import * as React from 'react'
import classNames from 'classnames'
import { Mutation, Query } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'src/utils/common'
import {
  Avatar,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  DialogContent,
  Dialog,
  DialogTitle,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import history from '../../../history'
import MobileChannelList from './MobileChannelList'
import { User, ReduxState } from 'src/store/reducers'
import { GetTube, getTubeData } from 'src/gql/queries/getTube'
import { changeLang } from '../../../translation/store/actions'
import * as authActions from '../../../auth/store/actions/user.actions'
import { changeSearchVideo, toggleSidebar } from '../../../store/actions'
import UnsupportedDialog from 'src/components/reusable/UnsupportedDialog'
import { listChannelsQuery, ListChannels } from '../../../gql/listChannels'
import { getTranslation, Translate, getLanguages } from '../../../translation'
import { changeTooltipSettings } from '../../../auth/store/actions/user.actions'
import { userCommandMutation, userCommandVariables } from '../../../gql/userCommand'
import SearchMenu from 'src/components/reusable/SearchMenu'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '@media(min-width: 1025px)': {
      display: 'none !important',
    },
  },
  seperator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
  logo: {
    paddingTop: 5,
    maxHeight: 55,
    marginLeft: -5,
  },
  hamburgerWrapper: {
    paddingLeft: 20,
    paddingRight: 5,
    '@media(max-width: 766px)': {
      marginRight: -20,
    },
  },
  hamburger: {
    cursor: 'pointer',
  },
  search: {
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    margin: 10,
    padding: 0,
  },
  chatIcon: {
    padding: 0,
    minWidth: 30,
    marginLeft: -5,
    marginRight: -10,
  },
  searchPopover: {
    width: '100vw',
  },
  profilePictureDefault: {
    width: 40,
    height: 40,
    margin: '20px auto',
    borderRadius: '50%',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    backgroundSize: '100%',
    textAlign: 'center' as 'center',
    '& img': {
      objectPosition: 'top',
    },
  },
})

interface MenuItem {
  to: string
}

type Props = {
  channelId: string
  sidebarStatus: boolean
  language: string
  user: User
  classes?: any
  routes: {
    path: string
    component: React.Component
  }[]
  translate: Translate
  logout: () => void
  toggleSidebar: (active: boolean) => void
  changeLang: (lang: string) => void
  savePreferredLanguage: (lang: string) => void
  changeSearchVideo: (search: string) => void
  changeTooltipSettings: (data: boolean) => void
  getTubeQuery: GetTube
  tooltipDisabled: boolean
}

type State = {
  userMenu: any
  langMenu: any
  searchPopover: any
  unSupportedDialog: boolean
  helpDialog: boolean
  langDialog: boolean
  search: string
}
// @ts-ignore
@withStyles(styles, { withTheme: true })
class MainToolbar extends React.PureComponent<Props, State> {
  inFeatureVideoChecked = false

  state = {
    userMenu: null,
    langMenu: null,
    searchPopover: null,
    unSupportedDialog: false,
    helpDialog: false,
    langDialog: false,
    search: '',
  }

  private userMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ userMenu: event.currentTarget })
  }

  private userMenuClose = () => {
    this.setState({ userMenu: null })
  }

  private onUnsupportedDialogClose = () => this.setState({ unSupportedDialog: false })

  private handleOpenSearchPopover = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ searchPopover: event.currentTarget })
  }

  private searchPopoverClose = () => {
    this.setState({ searchPopover: null })
  }

  private onOpenLangDialog = () => {
    this.setState({ langDialog: true })
    this.userMenuClose()
  }

  private onCloseLangDialog = () => {
    this.setState({ langDialog: false })
  }

  private onLangClick = (lang: string) => () => {
    this.props.changeLang(lang)
    this.props.savePreferredLanguage(lang)
    this.onCloseLangDialog()
  }

  public render() {
    const { classes, user, logout, translate, getTubeQuery, language } = this.props
    const { userMenu, searchPopover, unSupportedDialog, langDialog } = this.state
    const logo =
      getTubeQuery.loading || getTubeQuery.error
        ? ''
        : getTubeQuery.tube.logo
        ? getTubeQuery.tube.logo
        : '/assets/images/logo.png'

    return (
      <div className={classNames(classes.root, 'flex flex-row')}>
        <UnsupportedDialog
          open={unSupportedDialog}
          url={history.location.pathname}
          onClose={this.onUnsupportedDialogClose}
        />

        <div className="flex flex-1">
          <Query {...listChannelsQuery('readChannel')}>
            {({ loading, error, data }: ListChannels) =>
              // @ts-ignore
              !error && !!data ? <MobileChannelList channels={data.channels} loading={loading} /> : null
            }
          </Query>
        </div>

        <Link id="home-link" to="/home">
          {Boolean(logo) && <img className={classes.logo} src={logo} alt="logo" />}
        </Link>

        <div className="flex items-center pr-16">
          <IconButton id="search-btn" className="w-40 h-64" onClick={this.handleOpenSearchPopover}>
            <Icon style={{ fontSize: 30 }}>search</Icon>
          </IconButton>
          <div id="user-menu" className="cursor-pointer ml-8" onClick={this.userMenuClick}>
            {user.profile.profilePic ? (
              <Avatar className={classes.profilePictureDefault} alt="user photo" src={user.profile.profilePic} />
            ) : user.data.displayName ? (
              <Avatar className="">{user.data.displayName[0]}</Avatar>
            ) : user.email ? (
              <Avatar className="">{user.email[0]}</Avatar>
            ) : null}
          </div>

          <Popover
            open={Boolean(searchPopover)}
            anchorEl={searchPopover}
            onClose={this.searchPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              paper: classes.searchPopover,
            }}
          >
            <SearchMenu isInMainToolbar={true} />
          </Popover>

          <Popover
            open={Boolean(userMenu)}
            anchorEl={userMenu}
            onClose={this.userMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              paper: 'py-8',
            }}
          >
            <MenuItem id="language-select" onClick={this.onOpenLangDialog}>
              <ListItemIcon>
                <Icon>language</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={translate(language)} />
            </MenuItem>
            <MenuItem
              id="logout-link"
              onClick={() => {
                logout()
                this.userMenuClose()
              }}
            >
              <ListItemIcon>
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={translate('logout')} />
            </MenuItem>
          </Popover>

          <Dialog onClose={this.onCloseLangDialog} open={langDialog}>
            <DialogTitle>{translate('select-language')}</DialogTitle>
            <DialogContent>
              {getLanguages().map(lang => (
                <MenuItem id={`lang-${lang}`} key={lang} onClick={this.onLangClick(lang)}>
                  <ListItemText className="pl-0" primary={translate(lang)} />
                </MenuItem>
              ))}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logoutUser,
      changeSearchVideo,
      changeLang,
      toggleSidebar,
      changeTooltipSettings,
    },
    dispatch
  )
}

function mapStateToProps({ auth, translation, channel, sidebar }: ReduxState) {
  return {
    user: auth.user,
    translate: getTranslation(translation.lang),
    language: translation.lang,
    channelId: channel.currentChannel,
    sidebarStatus: sidebar.status,
    tooltipDisabled: auth.user.settings.tooltipDisabled,
  }
}

export default props => {
  const Comp = compose(getTubeData(), connect(mapStateToProps, mapDispatchToProps))(MainToolbar)

  return (
    <Mutation {...userCommandMutation()}>
      {userCommandReq => {
        return (
          <Comp
            {...props}
            savePreferredLanguage={(language: string) =>
              userCommandReq(
                userCommandVariables({
                  type: 'updatePreferredLanguage',
                  payload: { language },
                })
              )
            }
          />
        )
      }}
    </Mutation>
  )
}
