import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'
import { Item as TrackItem } from '../../../gql/getHelpVideos'
import { Icon } from '@material-ui/core'
import classNames from 'classnames'
import { getTranslation, Translate } from '../../../translation'
import { ReduxState, User } from '../../../store/reducers'
import { VideosWatched } from 'src/gql/getUserAnalytics'

const styles = theme => ({
  root: {
    width: '100%',
  },
  rootTrack: {
    background: '#fff',
    margin: '8px 0',
    // padding: '5px 10px 5px 15px',
    border: '1px solid #f3f3f3',
    fontSize: '1.3rem',
    overflow: 'hidden',
  },
  rowWrap: {
    position: 'relative',
  },
  verticalLine: {
    position: 'absolute',
    width: 1,
    background: '#b5b5b5',
    zIndex: 1,
    top: 20,
    bottom: 0,
  },
  horizontalLine: {
    position: 'absolute',
    right: 0,
    top: '50%',
    height: 1,
    background: '#b5b5b5',
  },
  circle: {
    border: '1px solid #b5b5b5',
    margin: '0 auto',
    fontSize: 10,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleIcon: {
    background: '#ccc',
  },
  icon: {
    fontSize: 12,
    color: 'white',
    fontWeight: 'bold',
  },
  itemText: {
    padding: '12px 20px 12px 5px',
    '& a': {
      color: 'black!important',
    },
  },
  itemTextSelected: {
    '& a': {
      color: 'white!important',
    },
  },
  bottomLineEraser: {
    position: 'absolute',
    top: '50%',
    bottom: 0,
    width: 1,
    background: '#fff',
    marginTop: 1,
    zIndex: 1,
  },
  topLineEraser: {
    position: 'absolute',
    bottom: '50%',
    top: 0,
    width: 1,
    background: '#fff',
    marginBottom: 1,
    zIndex: 1,
  },
  lineEraserSelected: {
    background: 'none',
  },
})

const widthFactor = 26
const rowHeight = 40
const circleSize = 28
const leftMargin = 20

type Props = {
  videoId: string
  humanId?: string
  channelId: string
  tracks: TrackItem[]
  user?: User
  readVideo?: boolean
  tubeColor?: string
  classes?: any
  featuredVideoId: string
  translate: Translate
  videosWatched: VideosWatched
  onClose: () => void
}

// @ts-ignore
@withStyles(styles, { withTheme: true })
class HelpTrackList extends React.PureComponent<Props, { collapsed: string[] }> {
  state = {
    collapsed: this.props.tracks.map(track => track.id),
  }

  private isCollapsed = (id: string) => this.state.collapsed.indexOf(id) !== -1

  private handleTrackToggle = itemId => e => {
    if (this.isCollapsed(itemId)) {
      this.setState({
        collapsed: this.state.collapsed.filter(id => id !== itemId),
      })
    } else {
      this.setState({
        collapsed: [...this.state.collapsed, itemId],
      })
    }

    e.stopPropagation()
  }

  private getFilteredItems = (items: TrackItem[]): TrackItem[] =>
    items.filter(item => item.type === 'video' || Boolean(this.getFilteredItems(item.items).length))

  private renderItems = (items: TrackItem[]) => {
    const { classes, tubeColor, videosWatched } = this.props
    const filteredItems = this.getFilteredItems(items)

    let videoIndex = 0
    return filteredItems.map((item, index) => {
      const isRoot = item.depth === 0
      const isLast = index === filteredItems.length - 1
      const isFirst = index === 0 || isRoot
      const isSelected = item.id === this.props.featuredVideoId
      const hasNoChildren = item.type === 'video' || item.items.length === 0
      const lastItemsChildren =
        item.type !== 'video' &&
        !hasNoChildren &&
        item.items[item.items.length - 1].items &&
        this.isCollapsed(item.items[item.items.length - 1].id)
          ? item.items[item.items.length - 1].items.length
          : 0

      if (item.type === 'video') {
        videoIndex += 1
      }

      return (
        <div
          key={`${item.id}-${index}`}
          className={classNames(isRoot && classes.rootTrack)}
          style={
            isSelected
              ? {
                  backgroundColor: tubeColor,
                  userSelect: 'none',
                }
              : { userSelect: 'none' }
          }
        >
          <div
            id={`help-track-${(item.name || item.id || '').replace(/\s{1,}/g, '-')}-${index}-item`}
            onClick={this.handleTrackToggle(item.id)}
            style={{
              position: 'relative',
              cursor: 'pointer',
              display: this.isCollapsed(item.parent) || item.depth === 0 ? 'block' : 'none',
            }}
          >
            <div
              className={classes.verticalLine}
              style={{
                display: hasNoChildren || !this.isCollapsed(item.id) ? 'none' : 'block',
                left: item.depth * widthFactor + leftMargin,
                height: `calc(100% - ${rowHeight * (1 + lastItemsChildren)}px)`,
              }}
            />
            <table
              cellSpacing={0}
              cellPadding={0}
              style={{
                height: rowHeight,
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <div
                      style={{
                        position: 'relative',
                        height: '100%',
                        width: item.depth * widthFactor + leftMargin,
                      }}
                    >
                      <div
                        className={classes.horizontalLine}
                        style={{
                          width: item.depth ? widthFactor : 0,
                        }}
                      />
                      <div
                        className={classNames(classes.topLineEraser, isSelected && classes.lineEraserSelected)}
                        style={{
                          display: isFirst ? 'block' : 'none',
                          right: -1,
                        }}
                      />
                      <div
                        className={classNames(classes.bottomLineEraser, isSelected && classes.lineEraserSelected)}
                        style={{
                          display: isLast && !isSelected ? 'block' : 'none',
                          right: widthFactor - 1,
                        }}
                      />
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <div
                      style={{
                        width: widthFactor,
                        marginLeft: -widthFactor / 2,
                        marginRight: 5,
                        position: 'relative',
                        zIndex: 1,
                      }}
                    >
                      <div
                        className={classNames(classes.circle, item.type === 'track' && classes.circleIcon)}
                        style={{
                          background: item.type === 'track' ? '#ccc' : videosWatched[item.id] ? tubeColor : 'white',
                          color: videosWatched[item.id] ? 'white' : 'black',
                          width: circleSize,
                          height: circleSize,
                          borderRadius: circleSize,
                          lineHeight: circleSize + 'px',
                        }}
                      >
                        {item.type === 'track' ? (
                          <Icon className={classes.icon}>{this.isCollapsed(item.id) ? 'remove' : 'add'}</Icon>
                        ) : (
                          videoIndex
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={classNames(classes.itemText, isSelected && classes.itemTextSelected)}>
                      {item.type === 'video' ? (
                        <a
                          id={`video-${(item.name || item.id || '').replace(/\s{1,}/g, '-')}-link`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: tubeColor }}
                          href={`/video/${item.humanId}`}
                          onClick={this.props.onClose}
                        >
                          {item.name}
                        </a>
                      ) : (
                        item.name
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {item.items && this.renderItems(item.items)}
          </div>
        </div>
      )
    })
  }

  public render() {
    const { classes, translate } = this.props

    return (
      <div className={classes.root}>
        {this.props.featuredVideoId && <h3>{translate('help-dialog-title')}</h3>}
        {this.renderItems(this.props.tracks)}
      </div>
    )
  }
}

export default connect((state: ReduxState, ownProps) => ({
  ...ownProps,
  user: state.auth.user,
  readVideo: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.readVideo),
  translate: getTranslation(state.translation.lang),
  lang: state.translation.lang,
  tubeColor: state.auth.tube.color,
}))(HelpTrackList)
