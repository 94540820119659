import * as React from 'react'
import axios from 'axios'
import 'slick-carousel/slick/slick.css'
import Select from 'react-select'
import classNames from 'classnames'
import { useMutation } from 'react-apollo'
import 'slick-carousel/slick/slick-theme.css'
import { withStyles } from '@material-ui/core/styles'
import MobileDetect from 'mobile-detect/mobile-detect'
import { Dialog, Icon, ListItemText, MenuItem } from '@material-ui/core'

import store from 'src/store'
import { mainApi } from 'src/config'
import { getInitials } from 'src/utils/common'
import { UserProfile } from 'src/interfaces/User'
import Spinner from 'src/components/reusable/Spinner'
import { countries } from 'src/constants/countries'
import { checkIfDevelopmentStage } from 'src/utils/common'
import styles from './UserInitialConfigurationDialog.style'
import { getTranslation, getLanguages } from 'src/translation'
import { setUserProfile, setUserConfigurationStatus } from 'src/auth/store/actions/user.actions'
import { getUserPermissions } from 'src/auth/cognito/organizationApi'
import { userCommandGQL, userCommandVariables } from '../../../gql/userCommand'
import { TRAININGTUBE_SUPPORTED_LANGUAGES } from 'src/utils/constants'

type Props = {
  open: boolean
  tubeColor: string
  orgId: string
  userLanguage: string
  userEmail: string
  profile?: UserProfile
  onClose: () => void
  changeLang: (lang: string) => void
  saveUserProfile: (data: any) => void
  organizationLanguages: string[]
  classes?: {
    skipBtn: string
    langItem: string
    inputRow: string
    clearImg: string
    langSelect: string
    inputField: string
    stepperText: string
    columnPreview: string
    nextButtons: string
    stepperIcons: string
    profilePicText: string
    userDetailTitle: string
    navigationWrapper: string
    stepperIconWrapper: string
    uploadPictureButton: string
    profilePictureDefault: string
  }
}

const FIRST_PAGE = 1
const SECOND_PAGE = 2
const THIRD_PAGE = 3
const S3_PREFIX = 'https://static-polly-audio.s3.eu-west-1.amazonaws.com/welcome-screenshots/'
const orgToNotShowTheDialog = ['orstedcom', 'semcomaritime']

const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '16px',
    height: '32px',
    border: 0,
    boxShadow: 'none',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '32px',
    padding: '0 6px',
  }),

  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '32px',
    marginTop: -2,
  }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
}

const UserInitialConfigurationDialog = (props: Props) => {
  const {
    open,
    orgId,
    classes,
    profile,
    tubeColor,
    userEmail,
    userLanguage,
    saveUserProfile,
    organizationLanguages,
  } = props

  const [isSaving, setSavingStatus] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(FIRST_PAGE)
  const [selectedLanguage, setSelectedLanguage] = React.useState(userLanguage || 'en-us')
  const [showAllLanguages, setShowAllLangsStatus] = React.useState(false)

  const [firstName, setFirstName] = React.useState(profile?.firstName)
  const [lastName, setLastName] = React.useState(profile?.lastName)
  const [profilePic, setProfilePicture] = React.useState(profile?.profilePic)
  const [selectedFile, setSelectedFile] = React.useState<any>(null)
  const [phoneNumber, setPhoneNumber] = React.useState(profile?.phoneNumber)
  const [country, setCountry] = React.useState(profile?.country)
  const [jobTitle, setJobTitle] = React.useState(profile?.jobTitle)

  const translate = getTranslation(selectedLanguage)

  const [updateUserStatus] = useMutation(
    userCommandGQL,
    userCommandVariables({
      type: 'completeUserConfigurationStatus',
      payload: { isConfigurationCompleted: true },
    })
  )

  const [updateUserPreferredLanguage] = useMutation(
    userCommandGQL,
    userCommandVariables({
      type: 'updatePreferredLanguage',
      payload: { language: selectedLanguage },
    })
  )

  const onSaveProfile = async (isUserDetailsPage: boolean) => {
    if (isUserDetailsPage) {
      // If nothing has changed do not do anything
      if (
        firstName === profile.firstName &&
        lastName === profile.lastName &&
        jobTitle === profile.jobTitle &&
        country === profile.country &&
        phoneNumber === profile.phoneNumber
      ) {
        return
      }

      await saveUserProfile({
        firstName,
        lastName,
        jobTitle,
        country,
        phoneNumber,
      })

      // Refetch users data
      const data = await getUserPermissions()

      // save to redux
      setUserProfile(data.userData)(store.dispatch)
      return
    }
    // If its not the details page, its the profile pic upload page
    else {
      const isDevStage = checkIfDevelopmentStage()

      const mainApiUrl = mainApi(isDevStage)

      let pathUrl

      if (selectedFile) {
        //  Get a secure URL to upload to s3
        const apiRes = await axios({
          method: 'post',
          url: `${mainApiUrl.command}/getGenericS3UploadSignedUrl`,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          data: {
            fileExt: selectedFile.name.split('.').pop(),
          },
        })
        pathUrl = apiRes.data

        // Upload to s3
        await axios({
          method: 'put',
          url: pathUrl.signedUrl,
          headers: {
            'content-type': selectedFile.type,
          },
          data: selectedFile,
        })

        await saveUserProfile({
          // If pic is removed, clear it
          ...(!profilePic && { profilePic: '' }),
          // If a new pic was uploaded. Save it
          ...(pathUrl && { profilePic: pathUrl.s3Path }),
        })

        // Refetch users data
        const data = await getUserPermissions()

        // save to redux
        setUserProfile(data.userData)(store.dispatch)
        return
      }
    }
  }

  const onClose = async () => {
    updateUserStatus()

    // save to redux
    setUserConfigurationStatus(true)(store.dispatch)

    props.onClose()
  }

  const md = new MobileDetect(window.navigator.userAgent)
  const isMobile = md.mobile()

  const languagesToShow = (showAllLanguages || !organizationLanguages.length
    ? getLanguages()
    : organizationLanguages
  ).filter(lang => lang !== userLanguage && TRAININGTUBE_SUPPORTED_LANGUAGES.includes(lang))

  const handleChange = selectedCountry => {
    setCountry(selectedCountry.value)
  }

  const renderLanguageSelectPage = () => {
    return (
      <div style={{ margin: '15px 25px 0px 25px' }}>
        <h1>{translate('select-your-languge')}</h1>
        <div className="fs--15 mt--5 mb--25">{translate('select-lang-reason')}</div>

        <div className="ds-flex">
          <div>
            <div className={classes.columnPreview}>{translate('language-select')}</div>
            <div style={{ maxHeight: 435, width: 265 }} className={classes.langSelect}>
              <MenuItem
                style={
                  selectedLanguage === userLanguage ? { background: tubeColor, color: 'white', fontWeight: 500 } : {}
                }
                id={`lang-${userLanguage}`}
                name={userLanguage}
                className={classes.langItem}
                key={userLanguage}
                onClick={() => setSelectedLanguage(userLanguage)}
              >
                <ListItemText disableTypography={true} className="pl-0" primary={translate(userLanguage)} />
                {selectedLanguage === userLanguage && (
                  <Icon style={{ color: 'white', marginLeft: 15 }}>check_circle_outline</Icon>
                )}
              </MenuItem>

              {languagesToShow
                .sort((a, b) => (translate(a) as string).localeCompare(translate(b) as string))
                .map(lang => (
                  <MenuItem
                    id={`lang-${lang}`}
                    name={lang}
                    style={selectedLanguage === lang ? { background: tubeColor, color: 'white', fontWeight: 500 } : {}}
                    className={classes.langItem}
                    key={lang}
                    onClick={() => setSelectedLanguage(lang)}
                  >
                    <ListItemText disableTypography={true} className="pl-0" primary={translate(lang)} />
                    {selectedLanguage === lang && (
                      <Icon style={{ color: 'white', marginLeft: 15 }}>check_circle_outline</Icon>
                    )}
                  </MenuItem>
                ))}

              {!!organizationLanguages.length && (
                <MenuItem className={classes.langItem} onClick={() => setShowAllLangsStatus(!showAllLanguages)}>
                  <div className="ds-flex">
                    <Icon style={{ color: tubeColor }}>{!showAllLanguages ? 'expand_more' : 'expand_less'}</Icon>
                    <ListItemText
                      disableTypography={true}
                      className="pl-0"
                      primary={translate(showAllLanguages ? 'see-less' : 'see-all')}
                    />
                  </div>
                </MenuItem>
              )}
            </div>
          </div>
          <div className="ml--auto">
            <div className={classes.columnPreview}>{translate('appplication-preview')}</div>
            <img
              alt="logo"
              style={{ width: 850, border: `1px solid #e5e1e1` }}
              src={`${S3_PREFIX}${selectedLanguage}.png`}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderUserDetailsSelectPage = () => {
    return (
      <div style={{ margin: '15px 25px 0px 25px' }}>
        <h2>{translate('select-your-details')}</h2>
        <div className="fs--15 mt--5 mb--20">{translate('select-details-reason')}</div>

        <div className={classes.inputRow}>
          <div>
            <div className={classes.userDetailTitle}>{translate('first-name')}</div>
            <input
              className={classes.inputField}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder={String(translate('first-name-placeholder'))}
            />
          </div>
          <div>
            <div className={classes.userDetailTitle}>{translate('last-name')}</div>
            <input
              className={classes.inputField}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder={String(translate('last-name-placeholder'))}
            />
          </div>
        </div>

        <div className={classes.inputRow}>
          <div>
            <div className={classes.userDetailTitle}>{translate('job-title')}</div>

            <input
              className={classes.inputField}
              value={jobTitle}
              onChange={e => setJobTitle(e.target.value)}
              placeholder={String(translate('job-title-placeholder'))}
            />
          </div>

          <div>
            <div className={classes.userDetailTitle}>{translate('company-phone')}</div>
            <input
              type="number"
              className={classes.inputField}
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              placeholder={String(translate('company-phone-placeholder'))}
            />
          </div>
        </div>

        <div className={classes.inputRow} style={{ marginLeft: -225 }}>
          <div>
            <div className={classes.userDetailTitle}>{translate('country')}</div>
            <Select
              value={
                country
                  ? { label: country, value: country }
                    ? { label: country, value: country }
                    : { label: 'None', value: 'None' }
                  : 'Select Your Country'
              }
              onChange={e => handleChange(e)}
              placeholder={String(translate('country-placeholder'))}
              className={classes.inputField}
              styles={reactSelectStyles}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              options={countries.map(country => {
                return { label: country, value: country }
              })}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderUserProfilePicturePage = () => {
    const userName = `${firstName || ''} ${lastName || ''}`

    return (
      <div style={{ margin: '15px 25px 0px 25px' }}>
        <h2>{translate('select-profile-picture')}</h2>
        <div className="fs--15 mt--5 mb--20">{translate('select-profile-picture-reason')}</div>

        {profilePic ? (
          <div className={classes.profilePictureDefault}>
            <img src={profilePic} className="w-100" alt="profilePicture" />

            <div
              className={classes.clearImg}
              onClick={() => {
                setProfilePicture('')
                setSelectedFile(null)
              }}
            >
              <Icon style={{ color: tubeColor }}>close</Icon>
              <span>{translate('remove')}</span>
            </div>
          </div>
        ) : (
          <div className={classes.profilePictureDefault}>
            <span className={classes.profilePicText}>
              {firstName || lastName ? getInitials(userName) : (userEmail || '').slice(0, 2).toUpperCase()}
            </span>
          </div>
        )}

        <div className={classes.uploadPictureButton}>
          <input
            accept="image/*"
            id="icon-button-file"
            onChange={e => {
              if (!e.target && !e.target.files) {
                return
              }
              const url = URL.createObjectURL(e.target.files[0])
              setProfilePicture(url)
              setSelectedFile(e.target.files[0])
            }}
            type="file"
            style={{ display: 'none' }}
          />
          <label htmlFor="icon-button-file">
            <div color="primary" className="cursor-pointer">
              {translate('upload-picture')}
            </div>
          </label>
        </div>
      </div>
    )
  }

  const renderBody = () => {
    switch (currentPage) {
      case FIRST_PAGE:
        return renderLanguageSelectPage()

      case SECOND_PAGE:
        return renderUserDetailsSelectPage()

      case THIRD_PAGE:
        return renderUserProfilePicturePage()

      default:
        return <div />
    }
  }

  return (
    <Dialog
      fullWidth={true}
      // maxWidth="md"
      disableBackdropClick={true}
      PaperProps={{
        style: {
          height: 700,
          maxWidth: 1200,
          // overflow: 'hidden',
        },
      }}
      open={open && !isMobile && !orgToNotShowTheDialog.includes(orgId)}
      onClose={() => onClose()}
    >
      {renderBody()}

      <div className={classNames('ds-flex navigation mt-auto', classes.navigationWrapper)}>
        <div className={classes.stepperIconWrapper}>
          <span
            className={classNames(classes.stepperIcons, {})}
            style={currentPage === FIRST_PAGE ? { background: tubeColor } : {}}
          >
            <Icon style={{ color: 'white', fontSize: 22 }}>language</Icon>
          </span>
          <span className={classes.stepperText} style={currentPage === FIRST_PAGE ? { color: tubeColor } : {}}>
            {translate('select-language')}
          </span>
        </div>

        <div className={classes.stepperIconWrapper}>
          <span className={classes.stepperIcons} style={currentPage === SECOND_PAGE ? { background: tubeColor } : {}}>
            <Icon style={{ color: 'white', fontSize: 22 }}>account_circle</Icon>
          </span>
          <span className={classes.stepperText} style={currentPage === SECOND_PAGE ? { color: tubeColor } : {}}>
            {translate('your-details')}
          </span>
        </div>

        <div className={classes.stepperIconWrapper}>
          <span className={classes.stepperIcons} style={currentPage === THIRD_PAGE ? { background: tubeColor } : {}}>
            <Icon style={{ color: 'white', fontSize: 22 }}>add_a_photo</Icon>
          </span>
          <span className={classes.stepperText} style={currentPage === THIRD_PAGE ? { color: tubeColor } : {}}>
            {translate('profile-picture')}
          </span>
        </div>
      </div>

      <div className="ds-flex  mr-5">
        <div>
          {currentPage !== FIRST_PAGE && (
            <div className={classes.skipBtn} onClick={() => setCurrentPage(currentPage - 1)}>
              {translate('previous')}
            </div>
          )}
        </div>

        <div className="ml--auto ds-flex">
          <div className={classNames('ml-auto', classes.skipBtn)} onClick={() => onClose()}>
            {isSaving ? (
              <span>
                <Spinner />
              </span>
            ) : (
              translate('skip')
            )}
          </div>

          {currentPage !== THIRD_PAGE && (
            <div
              className={classNames('ml-auto', classes.nextButtons)}
              onClick={async () => {
                if (currentPage === FIRST_PAGE && userLanguage !== selectedLanguage) {
                  setSavingStatus(true)

                  await updateUserPreferredLanguage()

                  await setSavingStatus(false)
                  await props.changeLang(selectedLanguage)
                }

                if (currentPage !== FIRST_PAGE) {
                  onSaveProfile(currentPage === SECOND_PAGE)
                }

                setCurrentPage(currentPage + 1)
              }}
            >
              {isSaving ? (
                <span>
                  <Spinner />
                </span>
              ) : (
                translate('next')
              )}
            </div>
          )}

          {currentPage === THIRD_PAGE && (
            <div
              className={classes.nextButtons}
              onClick={async () => {
                if (isSaving) {
                  return
                }

                setSavingStatus(true)
                await onSaveProfile(false)
                setSavingStatus(false)
                onClose()
              }}
            >
              {isSaving ? (
                <span>
                  <Spinner />
                </span>
              ) : (
                translate('done')
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default withStyles(styles, { withTheme: true })(UserInitialConfigurationDialog)
