import * as React from 'react'
import { VelocityComponent } from 'velocity-react'

type Props = {
  children: any
  animation?: string
  runOnMount?: boolean
  targetQuerySelector?: any
  interruptBehavior?: string
  visibility?: string
  duration?: number
  delay?: number
  easing?: number[]
  display?: any
}

const Animate = (props: Props) => {
  const children = React.cloneElement(props.children, {
    style: {
      ...props.children.style,
      visibility: 'hidden',
    },
  })
  return <VelocityComponent {...props} children={children} />
}

Animate.defaultProps = {
  animation: 'transition.fadeIn',
  runOnMount: true,
  targetQuerySelector: null,
  interruptBehavior: 'stop',
  visibility: 'visible',
  duration: 300,
  delay: 50,
  easing: [0.4, 0.0, 0.2, 1],
  display: null,
}

export default Animate
