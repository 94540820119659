import gql from 'graphql-tag'
import { DataValue, graphql } from 'react-apollo'

export type GetTube = DataValue<{
  tube: {
    id: string
    pathId: string
    thumbnailOverlay: string
    thumbnailLogo: string
    logo: string
    backgroundImage: string
    color: string
    name: string
  }
}>

export const getTubeGql = gql`
  query {
    tube @rest(type: "TubeDetails", path: "/tube", endpoint: "query") {
      id
      pathId
      thumbnailOverlay
      thumbnailLogo
      backgroundImage
      logo
      color
      name
    }
  }
`

export const getTubeData = () =>
  graphql(getTubeGql, {
    options: {},
    props: ({ data }) => ({
      getTubeQuery: data,
    }),
  })
