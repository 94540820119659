import { CHANGE_SIDEBAR_WIDTH, LayoutSidebarAction } from '../actions/layoutSidebar.actions'

const layoutSidebarReducer = function(
  state = {
    width: 240,
  },
  { type, payload }: LayoutSidebarAction
) {
  switch (type) {
    case CHANGE_SIDEBAR_WIDTH:
      return {
        ...state,
        width: state.width + payload.width > 500 ? 500 : state.width + payload.width,
      }

    default:
      return state
  }
}

export default layoutSidebarReducer
