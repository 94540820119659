export const CHANGE_CHANNEL = '[CHANNEL] CHANGE'
export const UPDATE_CHANNEL_LIST = 'UPDATE_CHANNEL_LIST'

export type ChannelAction = {
  type: string
  payload: {
    id?: string
    channelList?: string[]
  }
}

type Dispatch = (action: ChannelAction) => void

export const changeChannel = (id: string) => (dispatch: Dispatch) =>
  dispatch({
    type: CHANGE_CHANNEL,
    payload: {
      id,
    },
  })

export const updateChannelList = (channelList: string[]) => (dispatch: Dispatch) => {
  return dispatch({
    type: UPDATE_CHANNEL_LIST,
    payload: {
      channelList,
    },
  })
}
