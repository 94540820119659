import { combineReducers } from 'redux'
import user from './user.reducer'
import organization from './organization.reducer'
import tube from './tube.reducer'
import language from './language.reducer'
import login from './login.reducer'
import register from './register.reducer'

const authReducers = combineReducers({
  user,
  login,
  register,
  tube,
  language,
  organization,
})

export default authReducers
