const initialState = {
  isViewOnlyMode: false,
}

const uiReducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case 'SET_VIEW_ONLY_MODE':
      return {
        ...state,
        isViewOnlyMode: true,
      }

    default:
      return state
  }
}

export default uiReducer
