import gql from 'graphql-tag'
import { DataValue, graphql } from 'react-apollo'

export type TrackItem = {
  id: string
  name?: string
  abstract?: string
  description?: string
  pathId?: string
  tubeId?: string
  channelId?: string
  videosCount?: number
  parentTrackId?: string
  createdAt?: string
  orgId?: string
  owner?: string
  status?: 'published' | 'private' | 'archived'
}

export type ListTracks = DataValue<{
  tracks: TrackItem[]
}>

export const listTracksGql = (channelId?: string, fetchAdditionalInfo?: boolean) => gql`
    query listTracks($channelId: String, $fetchAdditionalInfo: String) {
      tracks(channelId: $channelId, fetchAdditionalInfo: $fetchAdditionalInfo)
        @rest(type: "[Track]", path: "/listtracks${Boolean(channelId) ? '?channelId=:channelId' : ''}${
  Boolean(fetchAdditionalInfo) ? `?fetchAdditionalInfo=${fetchAdditionalInfo}` : ''
}", endpoint: "query") {
        id
        name
        abstract
        description
        pathId
        tubeId
        channelId
        parentTrackId
        videosCount
        orgId
        createdAt
        owner
        status
      }
    }
  `

export const listTracksData = (channelId?: string, fetchAdditionalInfo?: boolean) =>
  graphql(listTracksGql(channelId, fetchAdditionalInfo), {
    options: {},
    props: ({ data }) => ({
      listTracksQuery: data,
    }),
  })
