export type sidebarAction = {
  type: string
  payload: {
    status: any
  }
}

type Dispatch = (action: sidebarAction) => void

export const toggleSidebar = state => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'TOGGLE_SIDEBAR',
      payload: {
        status: state,
      },
    })
  }
}

export const toggleSubscriptionsMenu = state => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'TOGGLE_SUBSCRIPTION_MENU',
      payload: {
        status: state,
      },
    })
  }
}

export const toggleTracksExpandMenu = state => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'TOGGLE_TRACKS_EXPAND_MENU',
      payload: {
        status: state,
      },
    })
  }
}

export const updateScrollPosition = state => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'UPDATE_LAST_SCROLL_POSITION',
      payload: {
        status: state,
      },
    })
  }
}

export const updateSideBarOpen = state => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'UPDATE_SIDEBAR_OPEN',
      payload: {
        status: state,
      },
    })
  }
}
