export const SET_HIDE_TOOLBAR_SEARCH = 'SET_HIDE_TOOLBAR_SEARCH'

interface SetHideToolbarSearchAction {
  type: typeof SET_HIDE_TOOLBAR_SEARCH
  payload: boolean
}

export type ToolbarActionTypes = SetHideToolbarSearchAction

export const setHideToolbarSearch = (value: boolean): ToolbarActionTypes => ({
  type: SET_HIDE_TOOLBAR_SEARCH,
  payload: value,
})
