import * as React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { Button, DialogActions } from '@material-ui/core'
import { connect } from 'react-redux'
import { ReduxState } from 'src/store/reducers'
import { getTranslation, Translate } from 'src/translation'

@connect((state: ReduxState, ownProps) => {
  return {
    ...ownProps,

    translate: getTranslation(state.translation.lang),
  }
})
export class SuccessDialog extends React.PureComponent {
  props: {
    open: boolean
    onClose: any
    tubeColor: string
    dialogContent: string
    header: string
    translate?: Translate
  }

  render() {
    const { dialogContent, tubeColor, header, onClose, translate, ...other } = this.props

    return (
      <Dialog maxWidth="md" aria-labelledby="confirm-dialog" {...other}>
        <DialogTitle id="confirm-dialog">
          <span style={{ color: tubeColor }}>{header}</span>
        </DialogTitle>

        <DialogContent>
          <p>{dialogContent}</p>
        </DialogContent>
        {onClose && (
          <DialogActions>
            <Button
              name="close"
              id="error-dialog-close-btn"
              onClick={e => {
                e.stopPropagation()
                onClose()
              }}
              color="primary"
            >
              {translate('close')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}
