export const SET_LANG = 'SET_LANG'

export function setLanguageData(lang, data) {
  return dispatch => {
    dispatch({
      type: SET_LANG,
      payload: {
        lang,
        data,
      },
    })
  }
}
