import * as Actions from '../actions/user.actions'
import * as TranslationActions from '../../../translation/store/actions'

const initialState = {
  role: 'guest',
  data: {
    displayName: '',
    photoURL: '',
    email: '',
  },
  profile: {},
  settings: {},
  externalOrgsUserHasAccessTo: [],
}

const user = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return {
        ...initialState,
        ...action.payload,
      }
    }

    case Actions.SET_PROFILE_DATA: {
      return {
        ...state,
        profile: action.payload,
      }
    }

    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState,
      }
    }

    case Actions.USER_LOGGED_OUT: {
      return initialState
    }

    case Actions.USER_CHANGE_TOOLTIP_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isFirstLogin: false,
          tooltipDisabled: action.payload.tooltipDisabled,
        },
      }
    }

    case TranslationActions.CHANGE_LANG: {
      return {
        ...state,
        settings: {
          ...state.settings,
          preferredLanguage: action.payload.lang,
        },
      }
    }

    case Actions.SET_USER_CONFIGURATION_DATA: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isConfigurationCompleted: true,
        },
      }
    }

    case Actions.UPDATE_USER_AUTO_PLAY_SETTING: {
      return {
        ...state,
        settings: {
          ...state.settings,
          autoPlay: action.payload.isAutoPlay,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default user
