import * as React from 'react'
import { connect } from 'react-redux'
import { ReduxState } from 'src/store/reducers'
import Maintenance from '../routes/maintenance'
import { mainApi } from '../../config'

type Props = {
  route: {
    path: string
  }
  orgId: string
  isNewHomePage: boolean
}

const isDevStage =
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf(':3000') !== -1 ||
  window.location.href.indexOf('tt-react-dev') !== -1 ||
  window.location.href.indexOf('tt-react-test') !== -1 ||
  window.location.href.indexOf('tt-react-qa') !== -1 ||
  window.location.href.indexOf('.cloudfront.net') !== -1 ||
  window.location.href.indexOf('.dev.trainingtube.com') !== -1

const { maintenance } = mainApi(isDevStage)

class Loader extends React.PureComponent<Props, any> {
  state = {
    compnent: null,
  }

  async componentDidMount() {
    let Component = null

    if (maintenance) {
      switch (this.props.route.path) {
        case '/login':
          Component = (await import('../routes/login/Login')).default
          break

        case '/auto-login':
          Component = (await import('../routes/auto-login/AutoLogin')).default
          break

        case '/login-lp':
          Component = (await import('../routes/login/LoginLP')).default
          break

        case '/loginembed':
          Component = (await import('../routes/login/LoginEmbed')).default
          break

        case '/register':
          Component = (await import('../routes/register/Register')).default
          break

        case '/reset-password':
          Component = (await import('../routes/reset-password/resetPassword')).default
          break

        case '/forgot-password':
          Component = (await import('../routes/forgot-password/forgotPassword')).default
          break

        default:
          Component = Maintenance
          break
      }
      this.setState({ compnent: Component })
      return
    }

    switch (this.props.route.path) {
      case '/login':
        Component = (await import('../routes/login/Login')).default
        break

      case '/auto-login':
        Component = (await import('../routes/auto-login/AutoLogin')).default
        break

      case '/login-lp':
        Component = (await import('../routes/login/LoginLP')).default
        break

      case '/loginembed':
        Component = (await import('../routes/login/LoginEmbed')).default
        break

      case '/register':
        Component = (await import('../routes/register/Register')).default
        break

      case '/reset-password':
        Component = (await import('../routes/reset-password/resetPassword')).default
        break

      case '/forgot-password':
        Component = (await import('../routes/forgot-password/forgotPassword')).default
        break

      case '/admin/branding':
        Component = (await import('../routes/admin/branding')).default
        break
      case '/admin/organization':
        Component = (await import('../routes/admin/organization')).default
        break

      case '/admin/security':
        Component = (await import('../routes/admin/security')).default
        break

      case '/admin/azure-ad':
        Component = (await import('../routes/admin/ssoConfig/AdminSsoConfig')).default
        break

      case '/admin/videos':
        Component = (await import('../routes/home')).default
        break
      case '/admin/channels':
        Component = (await import('../routes/admin/channels')).default
        break

      case '/subscriptions':
        Component = (await import('../routes/subscriptions')).default
        break

      case '/history':
        Component = (await import('../routes/history')).default
        break

      case '/tasks':
        Component = (await import('../routes/tasks')).default
        break

      case '/results':
        Component = (await import('../routes/searchResults')).default
        break

      case '/orgs/usage':
        Component = (await import('../routes/orgsUsageList')).default
        break
      case '/orgs/crm':
        Component = (await import('../routes/orgsUserCrm')).default
        break
      case '/orgs':
        Component = (await import('../routes/orgsList')).default
        break

      case '/admin/users/usage':
        Component = (await import('../routes/admin/usersUsage')).default
        break

      case '/admin/tracks':
      case '/admin/playlists':
        Component = (await import('../routes/admin/tracks')).default
        break

      case '/admin/tasks':
        Component = (await import('../routes/admin/tasks')).default
        break

      case '/admin/operationalTasks':
        Component = (await import('../routes/admin/operationalTasks')).default
        break

      case '/admin/users':
        Component = (await import('../routes/admin/users')).default
        break

      case '/admin/tags':
        Component = (await import('../routes/admin/tags')).default
        break

      case '/admin/reports/shared':
        Component = (await import('../routes/admin/sharedReports')).default
        break

      case '/admin/reports':
        Component = (await import('../routes/admin/reports')).default
        break

      case '/admin/information-classification':
        Component = (await import('../routes/admin/infClassification')).default
        break

      case this.props.isNewHomePage === true && '/home':
        Component = (await import('../routes/mandatory/HomePageContainer')).default
        break
      case this.props.isNewHomePage === false && '/home':
        Component = (await import('../routes/home')).default
        break
      case '/mandatory':
        Component = (await import('../routes/mandatory/MandatoryContainer')).default
        break
      // chat-system
      case '/support/chat':
        Component = (await import('../routes/chat')).default
        break

      case '/video/:humanId':
      case '/video/:humanId/:version/:lang':
        Component = (await import('../routes/video/home')).default
        break

      case '/produce-video/:taskId':
      case '/translate-video/:taskId':
        Component = (await import('../routes/video/home/ProduceVideo')).default
        break

      case '/channel/:channelId/home':
        Component = (await import('../routes/channel/home')).default
        break

      case '/channel/:channelId/videos':
        Component = (await import('../routes/channel/videos')).default
        break

      case '/channel/:channelId/tasks':
        Component = (await import('../routes/channel/tasks')).default
        break

      case '/channel/create':
        Component = (await import('../routes/channel/create/ChannelCreate')).default
        break

      case '/channel/edit/:channelId':
        Component = (await import('../routes/channel/edit/overview')).default
        break
      case '/channel/:channelId/event-log':
        Component = (await import('../routes/channel/edit/update-history')).default
        break

      case '/channel/access-control/:channelId':
        Component = (await import('../routes/channel/edit/access-control')).default
        break

      case '/channel/tags/:channelId':
        Component = (await import('../routes/channel/edit/tags')).default
        break

      case '/channel/recommendation/:channelId':
        Component = (await import('../routes/channel/edit/recommendation')).default
        break

      // case '/channel/users/:channelId':
      //   Component = (await import('../routes/channel/edit/users')).default
      //   break

      case '/channel/:channelId/users':
        Component = (await import('../routes/channel/users/ChannelUsers')).default
        break

      case '/channel/:channelId/reports/shared':
        Component = (await import('../routes/channel/sharedReports')).default
        break
      case '/channel/:channelId/reports':
        Component = (await import('../routes/channel/reports')).default
        break

      case '/channel/:channelId/tracks':
      case '/channel/:channelId/playlists':
        Component = (await import('../routes/channel/tracks')).default
        break
      case '/channel/:channelId/playlist/:trackId/share':
        Component = (await import('../routes/track/share')).default
        break
      case '/channel/:channelId/playlist/:trackId/create-video':
        Component = (await import('../routes/track/create-video')).default
        break

      case '/channel/:channelId/promotions':
        Component = (await import('../routes/channel/promotions')).default
        break
      case '/playlist/create/:trackId':
        Component = (await import('../routes/track/create')).default
        break
      case '/track/create':
      case '/playlist/create':
        Component = (await import('../routes/track/create')).default
        break

      case '/playlist/edit/:trackId/translations':
        Component = (await import('../routes/track/edit/translations')).default
        break

      case '/playlist/edit/:trackId/share':
        Component = (await import('../routes/track/edit/share')).default
        break
      case '/playlist/edit/:trackId':
      case '/track/edit/:trackId':
        Component = (await import('../routes/track/edit/overview')).default
        break

      case '/track/users/:trackId':
      case '/playlist/users/:trackId':
        Component = (await import('../routes/track/edit/users')).default
        break

      case '/create/video':
        Component = (await import('../routes/video/create/VideoCreate')).default
        break

      case '/edit/video/overview/:videoId/:version/:section':
      case '/edit/video/editor/:videoId/:version/:section/effects':
      case '/edit/video/editor/:videoId/:version/:section/manuscript':
      case '/edit/video/editor/:videoId/:version/:section/quick-guide':
      case '/edit/video/editor/:videoId/:version/:section/links':
      case '/edit/video/ask-for-review/:videoId/:version/:section':
      case '/edit/video/reports/:videoId/:version/:section':
      case '/edit/video/shared/reports/:videoId/:version/:section':
      case '/edit/video/tags/:videoId/:version/:section':
      case '/edit/video/embed-codes/:videoId/:version/:section':
      case '/edit/video/editor/:videoId/:version/:section/upload-files':
      case '/edit/video/editor/:videoId/:version/:section/voice-over':
      case '/edit/video/:videoId/:version/:section/event-log':
        Component = (await import('../routes/video-edit')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/title':
        Component = (await import('../routes/video-edit/translate/VideoTranslate')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/manuscript':
        Component = (await import('../routes/video-edit/translate/VideoManuscript')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/quick-guide':
        Component = (await import('../routes/video-edit/translate/VideoQuickGuide')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/links':
        Component = (await import('../routes/video-edit/translate/VideoLinks')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/caption':
        Component = (await import('../routes/video-edit/translate/VideoCaptions')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/voice-annotation':
        Component = (await import('../routes/video-edit/translate/VideoVoiceOver')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/effects':
        Component = (await import('../routes/video-edit/translate/VideoEditor')).default
        break

      case '/edit/video/translator/:videoId/:version/:section/upload-files':
        Component = (await import('../routes/video-edit/translate/VideoUploadFile')).default
        break

      case '/channel/:redirectId':
        Component = (await import('../routes/channel/redirect')).default
        break

      // case '/finance/video-rendering':
      //   Component = (await import('../routes/admin/video-rendering')).default
      //   break

      // case '/finance/anonymous-plays':
      //   Component = (await import('../routes/admin/anonymous-plays')).default
      //   break

      // case '/finance/internal-users':
      //   Component = (await import('../routes/admin/internal-users')).default
      //   break

      // case '/finance/external-users':
      //   Component = (await import('../routes/admin/external-users')).default
      //   break

      case '/finance/features-services':
        Component = (await import('../routes/admin/features-services')).default
        break

      case '/approved-task-ledger':
        Component = (await import('../routes/admin/approved-task-ledger')).default
        break

      case '/lp':
        Component = (await import('../routes/sso-login')).default
        break

      default:
        Component = Maintenance
        break
    }

    this.setState({ compnent: Component })
  }

  render() {
    const Component = this.state.compnent
    const isAutoLogin = this.props.route.path === '/auto-login'
    return Component ? (
      <Component {...this.props} />
    ) : isAutoLogin ? (
      <div className="w-100 h-100" style={{ background: '#3C4252' }} />
    ) : null
  }
}

const mapStateToProps = (state: ReduxState) => ({
  orgId: state.auth.organization.id,
  isNewHomePage:
    state.auth.organization.featureFlags.filter(e => e.featureId === 'new-home-page')[0]?.configuration === 'tube',
})

export default connect(mapStateToProps, null)(Loader)
