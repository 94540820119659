import * as React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import styles from './OrgNotFound.style'
import { ReduxState } from 'src/store/reducers'

type Props = {
  classes?: any
  tubeColor?: string
}

const OrgNotFound = ({ classes, tubeColor }: Props) => {
  return (
    <div className={classes.noResultContainer}>
      <svg width="300" height="250" viewBox="0 0 194 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M38.634 148.674L2.12568 29.1107L93.142 1.24912L129.65 120.813L38.634 148.674Z"
          fill="white"
          stroke={tubeColor}
          stroke-width="2"
        />
        <path
          d="M48.0815 1.24912L139.098 29.1107L102.59 148.674L11.5732 120.813L48.0815 1.24912Z"
          fill="white"
          stroke={tubeColor}
          stroke-width="2"
        />
        <path d="M24 9.58594H119.175V134.613H24V9.58594Z" fill="white" stroke={tubeColor} stroke-width="2" />
        <path
          d="M140.77 64.0488C143.849 81.5134 132.206 98.1623 114.766 101.237C97.3266 104.312 80.6909 92.65 77.6114 75.1854C74.5319 57.7208 86.1756 41.0719 103.615 37.9968C121.055 34.9217 137.69 46.5842 140.77 64.0488Z"
          fill="white"
          stroke={tubeColor}
          stroke-width="2"
        />
        <path
          d="M135.78 64.9302C138.37 79.6154 128.564 93.6191 113.879 96.2085C99.194 98.7979 85.1903 88.9924 82.6009 74.3072C80.0115 59.6221 89.817 45.6183 104.502 43.0289C119.187 40.4395 133.191 50.2451 135.78 64.9302Z"
          fill="white"
          stroke={tubeColor}
          stroke-width="2"
        />
        <path
          d="M88.1164 67.6612C88.1164 67.6612 88.8808 60.2789 92.6587 55.8545C96.4366 51.4301 103.907 49.1688 103.907 49.1688"
          stroke={tubeColor}
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M147.128 85.4933L185.605 108.612C188.325 110.247 189.205 113.781 187.567 116.507C185.929 119.234 182.395 120.116 179.675 118.481L141.198 95.3623L147.128 85.4933Z"
          fill="white"
          stroke={tubeColor}
          stroke-width="2"
        />
        <path d="M155.105 90.4118L149.256 100.146" stroke={tubeColor} stroke-width="2" stroke-linecap="square" />
        <path d="M181.186 107.944L176.657 115.482" stroke={tubeColor} stroke-width="2" stroke-linecap="square" />
        <path
          d="M138.621 83.5363L145.478 87.6566L142.332 92.8922L135.475 88.7719L138.621 83.5363Z"
          fill="white"
          stroke={tubeColor}
          stroke-width="2"
        />
      </svg>

      <div className={classes.noResultHeader} style={{ color: tubeColor }}>
        This organization is not available in TrainingTube
      </div>
      <div
        className={classes.noResultFooter}
        onClick={() => (window.location.href = 'https://www.trainingtube.com/login?redirectTo=/home')}
      >
        Click to find your organization
      </div>
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  tubeColor: state.auth.tube.color,
})

export default withStyles(styles)<Props>(connect(mapStateToProps, null)(OrgNotFound))
