import * as React from 'react'
import Downshift from 'downshift'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

function renderInput(inputProps) {
  const { InputProps, disabled, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
        },
        ...InputProps,
      }}
      disabled={disabled}
      {...other}
    />
  )
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1

  return (
    <MenuItem
      {...itemProps}
      id={`auto-complete-${selectedItem.split(' ').join('-')}-${highlightedIndex}-option`}
      key={suggestion.label}
      selected={isHighlighted}
      name={suggestion.label}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  )
}

function getSuggestions(inputValue, suggestions, showAll) {
  let count = 0

  return suggestions.filter(suggestion => {
    const keep =
      (!inputValue || suggestion.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) && (showAll || count < 5)

    if (keep) {
      count += 1
    }

    return keep
  })
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight: 350,
    overflow: 'scroll',
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
})

function IntegrationDownshift(props) {
  const { classes, input, placeholder, suggestions, disabled, showAll, ...other } = props

  return (
    <div className={classes.root}>
      <Downshift
        {...input}
        onInputValueChange={inputValue => {
          input.onChange(inputValue)
        }}
        selectedItem={input.value}
      >
        {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              disabled,
              InputProps: getInputProps({
                placeholder,
                id: 'integration-downshift-simple',
                error: other.error,
                ...input,
              }),
              error: other.error,
            })}
            {isOpen ? (
              <Paper className={classes.paper} square={true}>
                {getSuggestions(inputValue, suggestions, showAll).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion.label }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    </div>
  )
}

// @ts-ignore
export default withStyles(styles)(IntegrationDownshift)
