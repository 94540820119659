import axios from 'axios'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { withStyles } from '@material-ui/core/styles'
import { AppBar, Hidden, Icon, IconButton, Toolbar, Drawer, MuiThemeProvider } from '@material-ui/core'

import * as _ from 'lodash'
import { themes } from '../../theme'
import { mainApi } from 'src/config'
import classNames from 'classnames'
import Message from '../../components/Message'
import { User } from '../../../store/reducers'
import * as Actions from '../../../store/actions'
import Scrollbars from '../../components/Scrollbars'
import { checkIfDevelopmentStage } from 'src/utils/common'
import Sidebar from '../../../components/reusable/Sidebar'
import { isCurrentPageAdminPage, isCurrentPageNotificationPage } from 'src/utils/common'

const defaultProps = {}

const navbarWidth = 256

const styles = theme => ({
  root: {
    transition: 'padding-left 350ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&.boxed': {
      maxWidth: 1280,
      margin: '0 auto',
      boxShadow: theme.shadows[3],
    },
    '& table.simple tbody tr td': {
      borderColor: theme.palette.divider,
    },
    '& table.simple thead tr th': {
      borderColor: theme.palette.divider,
    },
    '& a:not([role=button])': {
      // color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& [class^="border-"]': {
      borderColor: theme.palette.divider,
    },
    '& [class*="border-"]': {
      borderColor: theme.palette.divider,
    },
    '&.scroll-body': {
      '& $wrapper': {
        height: 'auto',
        flex: '0 0 auto',
        overflow: 'auto',
      },
      '& $contentWrapper': {},
      '& $content': {},
    },
    '&.scroll-content': {
      '& $wrapper': {},
      '& $contentWrapper': {},
      '& $content': {},
    },
  },
  sidebarContent: {
    '@media(max-width: 1305px)': {
      paddingLeft: '245px !important',
    },
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 3,
    overflow: 'hidden',
    flex: '1 1 auto',
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    overflow: 'auto !important',
  },
  navbarWrapper: {
    boxShadow: theme.shadows[3],
    zIndex: 4,
  },
  navbarPaperWrapper: {},
  navbar: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    width: navbarWidth,
    minWidth: navbarWidth,
    height: '100%',
    zIndex: 4,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  navbarButton: {
    '&.right': {
      borderLeft: '1px solid ' + theme.palette.divider,
    },
    '&.left': {
      borderRight: '1px solid ' + theme.palette.divider,
    },
  },
  navbarLeft: {
    left: 0,
  },
  navbarRight: {
    right: 0,
  },
  navbarFolded: {
    position: 'absolute',
    width: 64,
    minWidth: 64,
    top: 0,
    bottom: 0,
  },
  navbarFoldedOpen: {
    width: navbarWidth,
    minWidth: navbarWidth,
  },
  navbarFoldedClose: {
    '& $navbarHeader': {
      padding: '0 8px 0 13px',
      '& .logo-text': {
        opacity: 0,
      },
      '& .react-badge': {
        opacity: 0,
      },
    },
    '& .list-item-text, & .arrow-icon': {
      opacity: 0,
    },
    '& .list-subheader .list-subheader-text': {
      opacity: 0,
    },
    '& .list-subheader:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      minWidth: 16,
      borderTop: '2px solid',
      opacity: 0.2,
    },
    '& .collapse-children': {
      display: 'none',
    },
  },
  navbarHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 1 auto',
    ...theme.mixins.toolbar,
    backgroundColor: 'rgba(255, 255, 255, .05)',
    boxShadow: theme.shadows[1],
  },
  navbarHeader: {
    display: 'flex',
    flex: '1 1 auto',
    padding: '0 8px 0 16px',
  },
  navbarContent: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  toolbarWrapper: {
    display: 'flex',
    position: 'fixed',
    zIndex: 1200,
    border: '1px solid #cdcdcd',
  },
  toolbar: {
    display: 'flex',
    flex: '1 0 auto',
  },
  footerWrapper: {
    position: 'relative',
    zIndex: 5,
  },
  footer: {
    display: 'flex',
    flex: '1 0 auto',
  },
})

type Settings = {
  customScrollbars: boolean
  theme: {
    [key: string]: string
  }
  layout: {
    style: string
    config: {
      footer: {
        display: boolean
        style: string
        position: 'left' | 'top' | 'right' | 'bottom' | 'above' | 'below'
      }
      mode: string
      navbar: {
        display: boolean
        folded: boolean
        position: 'left' | 'top' | 'right' | 'bottom'
      }
      scroll: string
      toolbar: {
        display: boolean
        style: string
        position: 'left' | 'top' | 'right' | 'bottom' | 'above' | 'below'
      }
    }
  }
}

type Props = {
  isDevStage: boolean
  tubeColor: string
  originalOrgId?: boolean
  location: { pathname: string; search: string }
  routes: {
    auth: string[]
    component: React.Component
    path: string
    settings: any
  }[]
  user: User
  defaultSettings: Settings
  settings: Settings
  setDefaultSettings: (settings: Settings | {}) => void
  setSettings: (settings: Settings) => void
  resetSettings: () => void
  classes?: any
  toolbar: React.Component
  footer: React.Component
  navbarHeader: React.Component
  navbarContent: React.Component
  navbar: {
    foldedOpen: boolean
    mobileOpen: boolean
  }
  navbarCloseMobile: () => void
  navbarOpenFolded: () => void
  navbarCloseFolded: () => void
}

class Layout1 extends React.PureComponent<Props, {}> {
  static defaultProps = defaultProps

  handleToggleFolded = () => {
    this.props.setDefaultSettings(
      _.set({}, 'layout.config.navbar.folded', !this.props.settings.layout.config.navbar.folded)
    )
  }

  render() {
    const {
      user,
      classes,
      toolbar,
      footer,
      navbarHeader,
      navbarContent,
      settings,
      navbar,
      originalOrgId,
      navbarCloseMobile,
      navbarOpenFolded,
      navbarCloseFolded,
    } = this.props
    const layoutConfig = settings.layout.config
    // pages
    const isHomePage = window.location.pathname === '/home'
    const isMandatoryPage = window.location.pathname === '/mandatory'
    const isSearchVideosPage = window.location.pathname === '/results'
    const isSubsriptionPage = window.location.pathname === '/subscriptions'
    const isHistoryPage = window.location.pathname === '/history'
    const isLibraryPage = window.location.pathname === '/library'
    const isFavoritePage = window.location.pathname === '/favorites'
    const isSharingPage = window.location.pathname === '/sharings'
    const isMyVideospage = window.location.pathname === '/my-videos'
    const isFinancePage = _.includes(window.location.pathname, '/finance')

    const isPersistent =
      isHomePage ||
      isSearchVideosPage ||
      isMandatoryPage ||
      isSubsriptionPage ||
      isHistoryPage ||
      isLibraryPage ||
      isFavoritePage ||
      isSharingPage ||
      isMyVideospage ||
      isFinancePage ||
      isCurrentPageAdminPage() ||
      isCurrentPageNotificationPage()
    const navbarHeaderTemplate = (
      <div className={classes.navbarHeaderWrapper}>
        <div className={classes.navbarHeader}>{navbarHeader}</div>
        <Hidden mdDown={true}>
          <IconButton id="menu-btn" onClick={this.handleToggleFolded}>
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>
        <Hidden lgUp={true}>
          <IconButton id="menu-btn-mobile" onClick={navbarCloseMobile}>
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>
      </div>
    )

    const navbarContentTemplate = <Scrollbars className={classes.navbarContent}>{navbarContent}</Scrollbars>

    const navBarTemplate = (
      <MuiThemeProvider theme={themes[settings.theme.navbar]}>
        <div id="fuse-navbar" className={classes.navbarWrapper}>
          <Hidden mdDown={true}>
            <div
              className={classNames(
                classes.navbar,
                classes['navbar' + _.upperFirst(layoutConfig.navbar.position)],
                layoutConfig.navbar.folded && classes.navbarFolded,
                layoutConfig.navbar.folded && navbar.foldedOpen && classes.navbarFoldedOpen,
                layoutConfig.navbar.folded && !navbar.foldedOpen && classes.navbarFoldedClose
              )}
              onMouseEnter={navbarOpenFolded}
              onMouseLeave={navbarCloseFolded}
              style={{ backgroundColor: themes[settings.theme.navbar].palette.background.default }}
            >
              {navbarHeaderTemplate}
              {navbarContentTemplate}
            </div>
          </Hidden>

          <Hidden lgUp={true}>
            <Drawer
              anchor={layoutConfig.navbar.position}
              variant="temporary"
              open={navbar.mobileOpen}
              classes={{
                paper: classes.navbar,
              }}
              onClose={navbarCloseMobile}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {navbarHeaderTemplate}
              {navbarContentTemplate}
            </Drawer>
          </Hidden>
        </div>
      </MuiThemeProvider>
    )

    const isDevStage = checkIfDevelopmentStage()

    const mainApiUrl = mainApi(isDevStage)

    const toolbarTemplate = (
      <MuiThemeProvider theme={themes[settings.theme.toolbar]}>
        <AppBar
          id="fuse-toolbar"
          style={{ boxShadow: 'none' }}
          className={classNames(classes.toolbarWrapper)}
          color="default"
        >
          <Toolbar className="p-0">
            <div className={classes.toolbar}>{toolbar}</div>
          </Toolbar>
          {originalOrgId && (
            <div
              onClick={async () => {
                await axios({
                  method: 'POST',
                  url: `${mainApiUrl.command}/updateUserOrgSwitch`,
                  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
                  data: {
                    orgToSwitchTo: '',
                  },
                })
                localStorage.removeItem('collaborationOrgId')

                window.location.href = '/home'
              }}
              style={{
                cursor: 'pointer',
                background: this.props.tubeColor,
                color: 'white',
                textAlign: 'center',
                padding: 5,
              }}
            >
              You are working in another workspace. Click to move to your own organization
            </div>
          )}
        </AppBar>
      </MuiThemeProvider>
    )

    const footerTemplate = (
      <MuiThemeProvider theme={themes[settings.theme.footer]}>
        <AppBar id="fuse-footer" className={classNames(classes.footerWrapper)} color="default">
          <Toolbar className="p-0">
            <div className={classNames(classes.footer)}>{footer}</div>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    )

    const isEmbedCodesPage =
      window.location.pathname.startsWith('/embed/') || window.location.pathname.startsWith('/sharing/')
    const hideSidebar = _.includes(
      ['/login', '/register', '/forgot-password', '/login-lp', '/reset-password', '/auto-login', '/lp'],
      window.location.pathname
    )

    switch (layoutConfig.scroll) {
      case 'body': {
        return (
          <div
            id="fuse-layout"
            className={classNames(classes.root, layoutConfig.mode, 'scroll-' + layoutConfig.scroll)}
          >
            {!isEmbedCodesPage && !hideSidebar && (
              <Sidebar isPersistent={isPersistent} user={user} currentPath={this.props.location.pathname} />
            )}

            <div
              style={{
                background: 'red',
                zIndex: 2,
              }}
            >
              {layoutConfig.toolbar.display &&
                layoutConfig.toolbar.style === 'fixed' &&
                layoutConfig.toolbar.position === 'above' &&
                toolbarTemplate}
              <Scrollbars>
                {layoutConfig.toolbar.display &&
                  layoutConfig.toolbar.style !== 'fixed' &&
                  layoutConfig.toolbar.position === 'above' &&
                  toolbarTemplate}

                <div className={classes.wrapper}>
                  {layoutConfig.navbar.display && layoutConfig.navbar.position === 'left' && navBarTemplate}

                  {layoutConfig.toolbar.display && layoutConfig.toolbar.position === 'below' && toolbarTemplate}

                  <div
                    className={classNames(
                      classes.contentWrapper,
                      layoutConfig.navbar.display &&
                        layoutConfig.navbar.folded &&
                        layoutConfig.navbar.position === 'left' &&
                        'md:ml-64',
                      layoutConfig.navbar.display &&
                        layoutConfig.navbar.folded &&
                        layoutConfig.navbar.position === 'right' &&
                        'md:mr-64'
                    )}
                    style={!isEmbedCodesPage && !hideSidebar ? { marginTop: 82 } : {}}
                  >
                    <div className={classes.content}>
                      <Message />
                      {renderRoutes(this.props.routes)}
                    </div>

                    {layoutConfig.footer.display && layoutConfig.footer.position === 'below' && footerTemplate}
                  </div>

                  {layoutConfig.navbar.display && layoutConfig.navbar.position === 'right' && navBarTemplate}
                </div>

                {layoutConfig.footer.display &&
                  layoutConfig.footer.style !== 'fixed' &&
                  layoutConfig.footer.position === 'above' &&
                  footerTemplate}
              </Scrollbars>
              {layoutConfig.footer.display &&
                layoutConfig.footer.style === 'fixed' &&
                layoutConfig.footer.position === 'above' &&
                footerTemplate}
            </div>
          </div>
        )
      }
      case 'content':
      default: {
        return (
          <div
            id="fuse-layout"
            className={classNames(classes.root, layoutConfig.mode, 'scroll-' + layoutConfig.scroll)}
          >
            {!isEmbedCodesPage && !hideSidebar && (
              <Sidebar isPersistent={isPersistent} user={user} currentPath={this.props.location.pathname} />
            )}

            {layoutConfig.toolbar.display && layoutConfig.toolbar.position === 'above' && toolbarTemplate}

            <div
              className={classes.wrapper}
              style={{
                zIndex: 2,
              }}
            >
              {layoutConfig.navbar.display && layoutConfig.navbar.position === 'left' && navBarTemplate}

              {layoutConfig.toolbar.display &&
                layoutConfig.toolbar.position === 'below' &&
                layoutConfig.toolbar.style === 'fixed' &&
                toolbarTemplate}

              <div
                className={classNames(
                  classes.contentWrapper,
                  layoutConfig.navbar.display &&
                    layoutConfig.navbar.folded &&
                    layoutConfig.navbar.position === 'left' &&
                    'md:ml-64',
                  layoutConfig.navbar.display &&
                    layoutConfig.navbar.folded &&
                    layoutConfig.navbar.position === 'right' &&
                    'md:mr-64'
                )}
                style={!isEmbedCodesPage && !hideSidebar ? { marginTop: 82 } : {}}
              >
                <Scrollbars className={classes.content}>
                  <Message />

                  {renderRoutes(this.props.routes)}

                  {layoutConfig.footer.display &&
                    layoutConfig.footer.position === 'below' &&
                    layoutConfig.footer.style !== 'fixed' &&
                    footerTemplate}
                </Scrollbars>
                {layoutConfig.footer.display &&
                  layoutConfig.footer.position === 'below' &&
                  layoutConfig.footer.style === 'fixed' &&
                  footerTemplate}
              </div>

              {layoutConfig.navbar.display && layoutConfig.navbar.position === 'right' && navBarTemplate}
            </div>

            {layoutConfig.footer.display && layoutConfig.footer.position === 'above' && footerTemplate}
          </div>
        )
      }
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSettings: Actions.setSettings,
      setDefaultSettings: Actions.setDefaultSettings,
      resetSettings: Actions.resetSettings,
      navbarOpenFolded: Actions.navbarOpenFolded,
      navbarCloseFolded: Actions.navbarCloseFolded,
      navbarCloseMobile: Actions.navbarCloseMobile,
    },
    dispatch
  )
}

function mapStateToProps({ auth, fuse, sidebar }) {
  return {
    user: auth.user,
    defaultSettings: fuse.settings.defaults,
    settings: fuse.settings.current,
    navbar: fuse.navbar,
    tubeColor: auth.tube.color,
    originalOrgId: auth.user.originalOrgId,
  }
}

// @ts-ignore
export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout1))
)
