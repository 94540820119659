import * as React from 'react'
import { Icon } from '@material-ui/core'

import { Translate } from 'src/translation'

type Props = {
  translate: Translate
}

export default function NoNotificationsCard(props: Props) {
  return (
    <div className="flex justify-center items-center">
      <div className="text-center">
        <div>
          <Icon style={{ color: '#CCCCCC', fontSize: '120px' }}>notifications</Icon>
          <div style={{ color: '#606060' }}>{props.translate('no-new-notifications')}</div>
        </div>
      </div>
    </div>
  )
}
