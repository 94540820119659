// toolbar.reducer.ts
import { Reducer } from 'redux'
import { ToolbarActionTypes, SET_HIDE_TOOLBAR_SEARCH } from '../actions/toolbar.actions'

interface ToolbarState {
  hidetoolbarsearch: boolean
}

const initialState: ToolbarState = {
  hidetoolbarsearch: false,
}

export const toolbarReducer: Reducer<ToolbarState, ToolbarActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SET_HIDE_TOOLBAR_SEARCH:
      return {
        ...state,
        hidetoolbarsearch: action.payload,
      }
    default:
      return state
  }
}
