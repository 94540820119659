import * as React from 'react'
import { checkIfDevelopmentStage } from 'src/utils/common'
import { mainApi } from 'src/config'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

import { addNotification } from '../../NotificationWrapper'
import Notification from 'src/components/reusable/PopupNotification'

import { getNotifications } from 'src/auth/cognito/organizationApi'
import { useContext } from 'react'
import { OpenAPIResponseContext } from 'src/openAiConnectionResponse'

type Props = {
  email: string
  orgId: string
}

const WebsocketWrapper = ({ email, orgId }: Props) => {
  const { response, setResponse } = useContext<any>(OpenAPIResponseContext)

  React.useEffect(() => {
    const socketClient = new W3CWebSocket(
      `${mainApi(checkIfDevelopmentStage()).webSocket}?userId=${email}&orgId=${orgId}`
    )

    socketClient.onmessage = async message => {
      const event = JSON.parse(message.data)

      console.log({ event })

      switch (event.type) {
        case 'REFETCH_NOTIFICATIONS':
          const newNotifications = await getNotifications()

          // TODO: Currently if the backend sends 10 messages at once, 10 functions are called at once. Need to implement a debounce mechanism here.
          for (let i = 0; i < newNotifications.length; i++) {
            addNotification({
              // @ts:ignore
              children: <Notification notification={newNotifications[i]} />,
              level: 'success',
              position: 'tr',
              autoDismiss: 10,
            })
          }

          break

        case 'OPEN_AI_OPTIMIZATION_COMPLETED':
          setResponse({
            ...response,
            [event.response.videoId]: {
              ...response[event.response.videoId],
              [event.response.type]: event.response,
            },
          })

          break

        default:
          break
      }
    }

    socketClient.onopen = function() {
      console.log('WebSocket Client Connected')

      function sendNumber() {
        if (socketClient.readyState === socketClient.OPEN) {
          //   This message is sent every 60 seconds just so that the connection is not closed.
          socketClient.send('1')
          setTimeout(sendNumber, 60000)
        }
      }

      sendNumber()
    }

    socketClient.onclose = function() {
      console.log('WebSocket Client Closed')
    }
  }, [])

  return <div />
}

export default WebsocketWrapper
