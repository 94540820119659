import gql from 'graphql-tag'
import { DataValue } from 'react-apollo'
import { VideoByHumanId } from './getVideo'

export type Item = {
  id?: string
  humanId?: string
  parent?: string
  name: string
  abstract: string
  description: string
  status: string
  tubeId: string
  channelId: string
  pathId: string
  owner: string
  orgId: string
  depth: number
  type: 'track' | 'video'
  items: Item[]
  videoId?: string
}

export type GetHelpVideos = DataValue<{
  helpVideos: {
    featuredVideo: VideoByHumanId
    videos: Item[]
  }
}>

export const getHelpVideosData = (page: string, channelId?: string, selectedLang?: string) => ({
  query: gql`
  query($page: String!, $lang: String!, $channelId: String!) {
    helpVideos(page: $page, lang: $lang, channelId: $channelId)
      @rest(type: "GetHelpVideos", path: "/getHelpVideos?page=:page&lang=:lang${
        channelId ? '&channelId=:channelId' : ''
      }", endpoint: "query") {
        featuredVideo
        videos
    }
  }
`,
  variables: channelId
    ? {
        page: encodeURIComponent(page),
        lang: selectedLang || localStorage.getItem('lang'),
      }
    : {
        page: encodeURIComponent(page),
        channelId,
        lang: selectedLang || localStorage.getItem('lang'),
      },
})
