export const TOGGLE_FILTER = '[FAVORITES VIDEOS] TOGGLE FILTER'

export type SearchVideoAction = {
  type: string
  payload: {
    key: string
    filter: string
  }
}

type Dispatch = (action: SearchVideoAction) => void

export const toggleFavoritesVideoFilter = (key: string, filter: string) => (dispatch: Dispatch) =>
  dispatch({
    type: TOGGLE_FILTER,
    payload: { key, filter },
  })
