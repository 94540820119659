export default () => ({
  container: {
    width: '100%',
    height: '100%',
    background: 'lightgrey',
    position: 'relative' as 'relative',
  },
  upperContainer: {
    width: '100%',
    height: '30%',
    background: '#9C3296',
  },
  infoContainer: {
    position: 'absolute' as 'absolute',
    top: '15%',
    height: '40%',
    left: '10%',
    right: '10%',
    background: 'white',
    boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
  },
  title: {
    fontSize: '3rem',
    margin: '40px 0px 30px 40px',
    color: '#616161',
    textAlign: 'center' as 'center',
  },
  subtitle: {
    fontSize: '1.8rem',
    margin: '40px 0px 15px 40px',
    color: '#9E9E9E',
    textAlign: 'center' as 'center',
  },
  text: {
    alignItems: 'center' as 'center',
    display: 'flex' as 'flex',
    fontSize: 14,
    margin: '0px 12px',
    color: '#808080',
  },
})
