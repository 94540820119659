export default (theme: any) => ({
  stepperIconWrapper: {
    alignItems: 'center',
    display: 'flex',
    background: 'white',
    zIndex: 1,
    flexDirection: 'column' as 'column',
  },
  stepperIcons: {
    color: 'white',
    background: '#9E9E9E',
    borderRadius: '50%',
    width: 35,
    height: 35,
    padding: '0px 10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  stepperText: {
    marginTop: 5,
    fontSize: 15,
    fontWeight: 500 as 500,
  },
  navigationWrapper: {
    margin: '22px 30px 0px 30px',
    color: '#9E9E9E',
    position: 'relative' as 'relative',

    justifyContent: 'space-between',
  },

  skipBtn: {
    fontSize: 16,
    color: theme.palette.primary.main,
    padding: 6,
    margin: '15px 10px',
    cursor: 'pointer',
  },
  nextButtons: {
    fontSize: 16,
    background: theme.palette.primary.main,
    color: 'white',
    padding: 6,
    margin: '15px 10px',
    width: 100,
    textAlign: 'center' as 'center',
    borderRadius: 40,
    cursor: 'pointer',
  },
  langItem: {
    borderBottom: '1px solid #e5e1e1',
  },
  langSelect: {
    overflowY: 'auto' as 'auto',
    position: 'absolute' as 'absolute',
    maxHeight: 450,
    border: '1px solid #e5e1e1',
    '&::-webkit-scrollbar': {
      width: 8,
      position: 'fixed' as 'fixed',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
  inputRow: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    columnGap: '5px',
    justifyContent: 'space-evenly',
    fontSize: 17,
    marginTop: 40,
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'auto',
    },
  },
  userDetailTitle: {
    color: '#78797b',
    textAlign: 'left' as 'left',
    fontSize: 17,
    marginTop: 15,
    marginLeft: 18,
  },
  inputField: {
    border: '1px solid #d8dbe0',
    color: '#323335',
    borderRadius: 40,
    padding: '7px 15px',
    display: 'block',
    width: 450,
    height: 45,
    background: 'white',
    '@media (max-width: 768px)': {
      width: 180,
    },
    '@media (max-width: 600px)': {
      width: 250,
    },
  },
  uploadPictureButton: {
    textAlign: 'center' as 'center',
    width: 130,
    padding: 8,
    fontSize: 14,
    color: theme.palette.primary.main,
    borderRadius: 40,
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    margin: '25px auto 0 auto',
    display: 'block',
  },

  profilePictureDefault: {
    width: 220,
    height: 220,
    margin: '60px auto',
    borderRadius: '50%',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    backgroundSize: '100%',
    textAlign: 'center' as 'center',
    '&:hover $clearImg': {
      display: 'flex',
    },
  },
  clearImg: {
    marginTop: -40,
    marginLeft: 66,
    position: 'absolute' as 'absolute',
    color: 'white',
    background: 'rgba(0,0,0,0.8)',
    // display:'flex',
    padding: '0px 4px',
    cursor: 'pointer',
    display: 'none',
  },
  profilePicText: {
    color: theme.palette.primary.main,
    fontSize: 48,
    left: '50%',
    top: '50%',
    display: 'block',
    transform: 'translate(0%,100%)',
  },
  columnPreview: {
    marginBottom: 5,
    fontSize: 16,
  },
})
