export const SET_TUBE_DATA = '[TUBE] SET DATA'
export const SET_BRANDING_COLOR = '[TUBE] SET COLOR'
export const UPDATE_TUBE = '[TUBE] UPDATE'

export function setTubeData(tube) {
  return dispatch => {
    dispatch({
      type: SET_TUBE_DATA,
      payload: tube,
    })
  }
}

export function setBrandingColor(color) {
  return dispatch => {
    dispatch({
      type: SET_BRANDING_COLOR,
      payload: color,
    })
  }
}

export function updateTube(tube) {
  return dispatch => {
    dispatch({
      type: UPDATE_TUBE,
      payload: tube,
    })
  }
}
