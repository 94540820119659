const activityReducer = function(state = {}, action) {
  switch (action.type) {
    case 'TRACK_EXPANDED': {
      return {
        ...state,
        [action.payload.channelId]: [...(state[action.payload.channelId] || []), action.payload.trackId],
      }
    }
    case 'TRACK_COLLAPSED': {
      return {
        ...state,
        [action.payload.channelId]: (state[action.payload.channelId] || []).filter(id => id !== action.payload.trackId),
      }
    }
    default: {
      return state
    }
  }
}

export default activityReducer
