import gql from 'graphql-tag'
import { QueryResult } from 'react-apollo'

export type TranscodingStatus = {
  jobId: string
  pipelineId: string
  videoId: string
  status: string
  version: number
  language: string
}

export type GetTranscodingStatus = QueryResult<{
  transcodingStatus: TranscodingStatus[]
}>

export const getTranscodingStatusQuery = (videoId: string) => ({
  query: gql`
    query($id: String!) {
      transcodingStatus(videoId: $videoId, lang: $lang)
        @rest(type: TranscodingStatus, path: "/transcodingStatus/:videoId", endpoint: query) {
        jobId
        pipelineId
        videoId
        status
        version
        language
      }
    }
  `,
  variables: { videoId },
  pollInterval: 7 * 1000,
})
