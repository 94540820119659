import gql from 'graphql-tag'
import { DataValue, graphql } from 'react-apollo'

export type Channel = {
  id: string
  parent: string
  name: string
  abstract: string
  description: string
  icon: string
  backgroundImage: string
  backgroundColor: string
  owners: string[]
  owner: string
  orgId: string
  createdAt: string
  sourceOrgId: string
  shared: boolean
  canUpdate: boolean
  sharedWith: { shareId: string; destOrgId: string }[]
  channelIcon?: string
  videosCount: number
  tracksCount: number
  pathId?: string
  isChannelExternalShareEnabled?: boolean
  canExternalTasksBeAssignedToChannel?: boolean
  externalShareAllowedDomains?: string[]
  featuredVideosForRecommendations?: string[]
  status: string
  featuredVideoRecommendationGenerationOn: string
}

export type ListChannels = DataValue<{
  channels: Channel[]
}>

export const listChannelsGql = gql`
  query listChannels($tubeId: String!, $action: String!) {
    channels(id: $tubeId, action: $action)
      @rest(type: "[ChannelList]", path: "/listchannels?tubeId=:id&action=:action", endpoint: "query") {
      parent
      id
      name
      abstract
      owner
      description
      icon
      orgId
      canUpdate
      videosCount
      tracksCount
      shared
      sharedWith
      sourceOrgId
      channelIcon
      backgroundImage
      backgroundColor
      isChannelExternalShareEnabled
      canExternalTasksBeAssignedToChannel
      externalShareAllowedDomains
      featuredVideosForRecommendations
      featuredVideoRecommendationGenerationOn
      pathId
      owners
      createdAt
      status
      isPersonalChannel
    }
  }
`

export const listChannelsData = (action: 'readChannel' | 'updateChannel') =>
  graphql(listChannelsGql, {
    options: {
      variables: {
        action,
        tubeId: localStorage.getItem('tubeId'),
      },
    },
    props: ({ data }) => ({
      listChannelsQuery: data,
    }),
  })
