import * as React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Grow from '@material-ui/core/Grow'
import Apps from '@material-ui/icons/Apps'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import grey from '@material-ui/core/colors/grey'
import { sortBy, includes, delay } from 'lodash'
import { Icon, Avatar } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { withStyles } from '@material-ui/core/styles'
import { Manager, Target, Popper } from 'react-popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { Channel } from 'src/gql/listChannels'
import { getInitials } from 'src/utils/common'
import { ReduxState } from 'src/store/reducers'
import RouterLink from '../../reusable/RouterLink'
import { getTranslation, Translate } from 'src/translation'

const menuItemHeight = 48
const menuItemCount = 12

const styles = theme => ({
  root: {
    display: 'flex',
    marginLeft: 10,
    marginTop: 5,
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  popperClose: {
    pointerEvents: 'none',
  },
  dropdown: {
    display: 'flex',
    backgroundColor: 'white',
    boxShadow: 'none',
    '-ms-overflow-style': 'none',
  },
  iconSmall: {
    fontSize: 45,
    paddingRight: 5,
    '@media(max-width: 1200px)': {
      fontSize: 40,
    },
  },
  channelsMenu: {
    marginLeft: -100,
    '& a + div': {
      opacity: 0,
      pointerEvents: 'none',
    },
    '& a:hover + div': {
      pointerEvents: 'unset',
      opacity: 1,
    },
    '& a:hover': {
      pointerEvents: 'unset',
      opacity: 1,
    },
    '& div:hover': {
      pointerEvents: 'unset',
      opacity: 1,
    },
    '@media(max-height: 672px)': {
      marginTop: 62,
    },
  },
  selectText: {
    '@media(max-width: 1200px)': {
      fontSize: 14,
    },
  },
  channelWrapper: {
    opacity: '1 !important',
    position: 'relative',
    backgroundColor: 'white',
  },
  nestedPopper: {
    transform: 'none !important',
    left: '100% !important',
  },
  menuWrapper: {
    border: '1px #e0e0e0 solid',
  },
  menu: {
    width: 343,
    height: menuItemCount * menuItemHeight,
    maxHeight: menuItemCount * menuItemHeight,
    'overflow-x': 'hidden',
    paddingTop: 0,
  },
  menuLink: {
    display: 'flex',
    width: '100% !important',
    color: 'rgb(3,3,3) !important',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  channelItem: {
    color: '#F2F2F2',
    paddingLeft: 10,
    paddingRight: 10,
  },
  scrollIcon: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  selectButton: {
    padding: '8px 12px',
    width: 187,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      width: 187,
    },
  },
  channelName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  channelImg: {
    width: 22,
    fontSize: 12,
    height: 23,
    maxWidth: '100%',
    marginRight: 12,
  },
  channelPreloader: {
    width: 22,
    height: 23,
    marginRight: 10,
    maxWidth: '100%',
    backgroundColor: '#cccccc',
  },
  sharedText: {
    position: 'absolute',
    top: 2,
    right: 35,
    background: theme.palette.primary.main,
    borderRadius: 10,
    textAlign: 'center',
    color: 'white',
    width: 50,
    fontWeight: 600,
    fontSize: 12,
  },
})

type Props = {
  classes?: any
  channels: Channel[]
  loading: boolean
  translate?: Translate
  tubeColor?: string
  sharedChannelsToOthers?: { channelId: string; destinationPathId: string }[]
  orgId?: string
}

type State = {
  open: boolean
  selectedChannels: string[]
  scrolledMenus: {
    [key: string]: number
  }
}

class MenuListComposition extends React.PureComponent<Props, State> {
  eventStack = []

  state = {
    open: false,
    selectedChannels: [],
    scrolledMenus: {},
  }

  private getChannels = (parentId: string) =>
    sortBy(
      (this.props.channels || []).filter(channel => channel.parent === parentId),
      'name'
    )

  private handleOpen = () => {
    this.setState({ open: true, selectedChannels: [] })
  }

  private handleClose = () => {
    this.setState({ open: false, selectedChannels: [] })
  }

  private setSelectedChannels = () => {
    if (this.eventStack.length !== 0) {
      const lastEvent = this.eventStack[this.eventStack.length - 1]

      this.setState({
        selectedChannels: [...this.state.selectedChannels.slice(0, lastEvent.depth), lastEvent.id],
      })
      this.eventStack = []
    }
  }

  private onSelectChannel = (channelId: string, depth: number) => () => {
    this.eventStack = [...this.eventStack, { id: channelId, depth }]

    delay(this.setSelectedChannels, 10)
  }

  private onScrollDown = (depth: number) => () => {
    const element = document.getElementById(`scroll-menu-${depth}`)
    const scrollTo = menuItemHeight + (this.state.scrolledMenus[`depth-${depth}`] || 0)
    if (scrollTo > element.scrollHeight - menuItemHeight * (menuItemCount - 1)) {
      return
    }

    element.scrollTop = scrollTo

    this.setState({
      scrolledMenus: {
        ...this.state.scrolledMenus,
        [`depth-${depth}`]: scrollTo,
      },
    })
  }

  private onScrollUp = (depth: number) => () => {
    const element = document.getElementById(`scroll-menu-${depth}`)
    const scrollTo = this.state.scrolledMenus[`depth-${depth}`] - menuItemHeight
    element.scrollTop = scrollTo

    this.setState({
      scrolledMenus: {
        ...this.state.scrolledMenus,
        [`depth-${depth}`]: scrollTo,
      },
    })
  }

  public render() {
    const { classes, loading, translate, tubeColor } = this.props
    const { open, selectedChannels, scrolledMenus } = this.state

    const getChannelName = channel => {
      const isExpandable =
        channel.shared || (this.props.sharedChannelsToOthers || []).some(item => item.channelId === channel.id)

      return (
        <>
          {/* Tooltips are heavy on re-render in material UI so dont add tooltips where it is not needed */}
          {/* Currently 31 text fit in the width specified without having to show ellipsis */}
          {(isExpandable ? (
            channel.name.length > 26
          ) : (
            channel.name.length > 31
          )) ? (
            <Tooltip title={channel.name}>
              <div
                style={
                  includes(this.state.selectedChannels, channel.id)
                    ? { color: 'white', ...(isExpandable ? { width: '65%' } : {}) }
                    : { ...(isExpandable ? { width: '65%' } : {}) }
                }
                className={classes.channelName}
              >
                {channel.name}
              </div>
            </Tooltip>
          ) : (
            <div
              style={
                includes(this.state.selectedChannels, channel.id)
                  ? { color: 'white', ...(isExpandable ? { width: '65%' } : {}) }
                  : { ...(isExpandable ? { width: '65%' } : {}) }
              }
              className={classes.channelName}
            >
              {channel.name}
            </div>
          )}
        </>
      )
    }

    const rootChannelsToShow = this.getChannels(localStorage.getItem('tubeId'))

    const channelWithNoParent = (this.props.channels || [])
      .filter(item => item.parent !== localStorage.getItem('tubeId'))
      .filter(item => {
        const hasParentInList = this.props.channels.find(channel => channel.id === item.parent)

        return !hasParentInList
      })

    const channelsToShow = [...rootChannelsToShow, ...channelWithNoParent]

    return (
      <div className={classes.root}>
        <Manager>
          <Target>
            <div>
              <Button
                id="select-channel-btn"
                className={classes.selectButton}
                aria-owns={open ? 'menu-list-grow' : null}
                aria-haspopup="true"
                onClick={this.handleOpen}
              >
                <span className={classes.selectText}> {translate('selectChannel')}</span>
                <Apps className={classNames(classes.leftIcon, classes.iconSmall)} />
              </Button>
            </div>
          </Target>
          {open && (
            <Popper
              placement="bottom-start"
              eventsEnabled={open}
              className={classNames({ [classes.popperClose]: !open }, classes.channelsMenu)}
            >
              <ClickAwayListener onClickAway={this.handleClose}>
                <Grow in={open} style={{ transformOrigin: '0 0 0' }}>
                  <Paper className={classNames(classes.dropdown)}>
                    {loading ? (
                      translate('loading')
                    ) : (
                      <React.Fragment>
                        <div className={classes.menuWrapper}>
                          {scrolledMenus['depth-0'] ? (
                            <div className={classes.scrollIcon} onClick={this.onScrollUp(0)}>
                              <Icon>expand_less</Icon>
                            </div>
                          ) : null}
                          <MenuList
                            id="scroll-menu-0"
                            role="menu"
                            className={classNames('channels-menu', classes.menu)}
                          >
                            {channelsToShow.map(channel => (
                              <MenuItem
                                key={channel.id}
                                className={classes.channelItem}
                                style={
                                  includes(this.state.selectedChannels, channel.id) ? { background: tubeColor } : {}
                                }
                                onMouseEnter={this.onSelectChannel(channel.id, 0)}
                              >
                                <RouterLink
                                  id={`channel-${(channel.name || '').replace(/\s{1,}/g, '-')}-link`}
                                  name={channel.name}
                                  to={`/channel/${channel.id}/home`}
                                  className={classes.menuLink}
                                  onClick={this.handleClose}
                                >
                                  {loading ? (
                                    <Avatar className={classes.channelPreloader} />
                                  ) : channel.channelIcon ? (
                                    <img alt="logo" className={classes.channelImg} src={channel.channelIcon} />
                                  ) : (
                                    <Avatar
                                      style={
                                        includes(this.state.selectedChannels, channel.id)
                                          ? {
                                              backgroundColor: 'white',
                                              color: tubeColor,
                                            }
                                          : {
                                              backgroundColor: tubeColor,
                                              color: 'white',
                                            }
                                      }
                                      className={classes.channelImg}
                                    >
                                      {getInitials(channel.name)}
                                    </Avatar>
                                  )}
                                  {getChannelName(channel)}
                                  {
                                    <div
                                      className={classes.sharedText}
                                      style={
                                        includes(this.state.selectedChannels, channel.id)
                                          ? {
                                              background: 'white',
                                              color: tubeColor,
                                            }
                                          : {}
                                      }
                                    >
                                      {translate(
                                        channel.shared
                                          ? 'external'
                                          : this.props.sharedChannelsToOthers.some(
                                              item => item.channelId === channel.id
                                            )
                                          ? 'shared'
                                          : ''
                                      )}
                                    </div>
                                  }
                                  {this.getChannels(channel.id).length ? (
                                    <Icon
                                      style={
                                        includes(this.state.selectedChannels, channel.id)
                                          ? { marginLeft: 'auto', color: 'white' }
                                          : { marginLeft: 'auto' }
                                      }
                                    >
                                      chevron_right
                                    </Icon>
                                  ) : null}
                                </RouterLink>
                              </MenuItem>
                            ))}
                          </MenuList>
                          {this.getChannels(localStorage.getItem('tubeId')).length > menuItemCount ? (
                            <div className={classes.scrollIcon} onClick={this.onScrollDown(0)}>
                              <Icon>expand_more</Icon>
                            </div>
                          ) : null}
                        </div>
                        {selectedChannels.length
                          ? selectedChannels.map((selectedChannel, index) =>
                              this.getChannels(selectedChannel).length ? (
                                <div
                                  key={selectedChannel}
                                  className={classes.menuWrapper}
                                  style={{
                                    backgroundColor: grey[(index + 1) * 50],
                                  }}
                                >
                                  {scrolledMenus[`depth-${index + 1}`] ? (
                                    <div className={classes.scrollIcon} onClick={this.onScrollUp(index + 1)}>
                                      <Icon>expand_less</Icon>
                                    </div>
                                  ) : null}
                                  <MenuList
                                    id={`scroll-menu-${index + 1}`}
                                    role="menu"
                                    className={classNames('channels-menu', classes.menu)}
                                  >
                                    {this.getChannels(selectedChannel).map(channel => (
                                      <MenuItem
                                        key={channel.id}
                                        className={classes.channelItem}
                                        onMouseEnter={this.onSelectChannel(channel.id, index + 1)}
                                        style={
                                          includes(this.state.selectedChannels, channel.id)
                                            ? { background: tubeColor }
                                            : {}
                                        }
                                      >
                                        <RouterLink
                                          id={`selected-channel-${(channel.name || '').replace(/\s{1,}/g, '-')}-link`}
                                          name={channel.name}
                                          to={`/channel/${channel.id}/home`}
                                          className={classes.menuLink}
                                          onClick={this.handleClose}
                                        >
                                          {loading ? (
                                            <Avatar className={classes.channelPreloader} />
                                          ) : channel.channelIcon ? (
                                            <img alt="logo" className={classes.channelImg} src={channel.channelIcon} />
                                          ) : (
                                            <Avatar
                                              style={
                                                includes(this.state.selectedChannels, channel.id)
                                                  ? {
                                                      backgroundColor: 'white',
                                                      color: tubeColor,
                                                    }
                                                  : {
                                                      backgroundColor: tubeColor,
                                                      color: 'white',
                                                    }
                                              }
                                              className={classes.channelImg}
                                            >
                                              {getInitials(channel.name)}
                                            </Avatar>
                                          )}
                                          {getChannelName(channel)}
                                          {
                                            <div
                                              className={classes.sharedText}
                                              style={
                                                includes(this.state.selectedChannels, channel.id)
                                                  ? {
                                                      background: 'white',
                                                      color: tubeColor,
                                                    }
                                                  : {}
                                              }
                                            >
                                              {translate(
                                                channel.shared
                                                  ? 'external'
                                                  : this.props.sharedChannelsToOthers.some(
                                                      item => item.channelId === channel.id
                                                    )
                                                  ? 'shared'
                                                  : ''
                                              )}
                                            </div>
                                          }
                                          {this.getChannels(channel.id).length ? (
                                            <Icon
                                              style={
                                                includes(this.state.selectedChannels, channel.id)
                                                  ? { marginLeft: 'auto', color: 'white' }
                                                  : { marginLeft: 'auto' }
                                              }
                                            >
                                              chevron_right
                                            </Icon>
                                          ) : null}
                                        </RouterLink>
                                      </MenuItem>
                                    ))}
                                  </MenuList>

                                  {this.getChannels(selectedChannel).length > menuItemCount ? (
                                    <div
                                      id="expand-more-icon"
                                      className={classes.scrollIcon}
                                      onClick={this.onScrollDown(index + 1)}
                                    >
                                      <Icon>expand_more</Icon>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null
                            )
                          : null}
                      </React.Fragment>
                    )}
                  </Paper>
                </Grow>
              </ClickAwayListener>
            </Popper>
          )}
        </Manager>
      </div>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  translate: getTranslation(state.translation.lang),
  tubeColor: state.auth.tube.color,
  orgId: state.auth.organization.id,
  sharedChannelsToOthers: state.auth.organization.sharedChannelsToOthers || [],
})

// @ts-ignore
export default withStyles(styles)(connect(mapStateToProps)(MenuListComposition))
