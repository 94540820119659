import * as React from 'react'
import { connect } from 'react-redux'
// import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
// import { Helmet } from 'react-helmet'
import { getTranslation } from 'src/translation'
// import { Icon } from '@material-ui/core'
import { SupportForm } from 'src/components/routes/channel/users/SupportForm'
import { Mutation } from 'react-apollo'
import { addCreateLeadMutation, addCreateLeadVariables } from 'src/gql/zendesk'
import { ErrorDialog } from 'src/components/reusable/ErrorDialog'

import { SuccessDialog } from 'src/components/reusable/SuccessDialog'

const styles = theme => ({
  content: {
    // width: 134,
    // '@media(max-width: 1024px)': {
    //   width: 60,
    //   borderRadius: `50%`,
    // },
    // height: 50,
    padding: 0,
    margin: '10px 20px',
    position: ' fixed',
    bottom: 0,
    overflow: 'visible',
    opacity: 1,
    border: 0,
    zIndex: 1300,
    letterSpacing: 0.6,
    transitionDuration: 250,
    transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    transitionProperty: 'opacity, top, bottom',
    right: 0,
  },
  buttonWrapper: {
    color: '#FFFFFF !important',
    fill: '#FFFFFF !important',
    display: 'flex',
    padding: '10px 14px',
    borderRadius: '999rem',
    bottom: 0,
    letterSpacing: 0.6,
    fontSize: '1.07143rem',
  },
  supportText: {
    margin: 'auto',
    fontSize: 17,
    marginRight: 2,
    fontWeight: 500,
    '@media(max-width: 1024px)': {
      display: 'none',
    },
  },
})

// @ts-ignore
@withStyles(styles)
@connect((state, ownProps) => {
  return {
    ...ownProps,
    translate: getTranslation(state.translation.lang),
    tubeColor: state.auth.tube.color,
    userEmail: state.auth.user.data.email || state.auth.user.email,
    orgId: state.auth.organization.id,
  }
}, null)
export default class SupportButton extends React.Component<
  any,
  { error: any; showSuccessForm: boolean; isSupportDialogOpen: boolean }
> {
  constructor(props) {
    super(props)

    this.state = {
      isSupportDialogOpen: false,
      showSuccessForm: false,
      error: null,
    }
  }

  public onSupportFormSubmit = async (values, createLeadReq) => {
    if (values) {
      const orgId = this.props.orgId

      try {
        const description = `
        ${values.description || 'N/A'}


        URL: ${window.location.href}
        `

        const data = {
          orgId,
          body: description,
          requesterName: `${values.firstName} ${values.lastName}`,
          requesterEmail: this.props.userEmail || 'N/A',
          phoneNumber: values.phoneNumber,
          subject: values.title,
        }

        localStorage.setItem('firstName', values.firstName)
        localStorage.setItem('lastName', values.lastName)
        if (!!values.phoneNumber) {
          localStorage.setItem('phoneNumber', values.phoneNumber)
        }

        await createLeadReq(
          addCreateLeadVariables({
            type: 'createTicket',
            payload: data,
          })
        )

        this.setState({
          showSuccessForm: true,
          isSupportDialogOpen: false,
        })
      } catch (e) {
        this.setState({
          error: e.networkError && e.networkError.result ? e.networkError.result.message : e.message,
          isSupportDialogOpen: false,
        })
      }
    } else {
      this.setState({
        isSupportDialogOpen: false,
      })
    }
  }

  render() {
    const { classes, translate, tubeColor, userEmail } = this.props

    return (
      <>
        {/* <Helmet>
          <script src="https://www.socialintents.com/api/socialintents.1.3.js#2c9faa357da23501017da3a25c230080" />
        </Helmet> */}
        <Mutation {...addCreateLeadMutation()}>
          {createLeadReq => (
            <div className={classes.content}>
              {/* <button
              onClick={() => this.setState({ isSupportDialogOpen: true })}
              className={classes.buttonWrapper}
              style={{ backgroundColor: tubeColor }}
            >
              <Icon style={{ fontSize: 26, marginRight: 4, marginTop: 1 }}>help_outline</Icon>
              <span className={classes.supportText}>{translate('support')}</span>
            </button> */}

              {this.state.isSupportDialogOpen && (
                <SupportForm
                  open={this.state.isSupportDialogOpen}
                  title={translate('support-form-title')}
                  confirmLabel={translate('submit')}
                  userEmail={userEmail}
                  onClose={(values: any) => this.onSupportFormSubmit(values, createLeadReq)}
                />
              )}

              <ErrorDialog
                open={!!this.state.error}
                message={this.state.error}
                onClose={() => this.setState({ error: null })}
              />

              <SuccessDialog
                open={this.state.showSuccessForm}
                tubeColor={tubeColor}
                onClose={() => {
                  this.setState({ showSuccessForm: false })
                }}
                dialogContent={translate('support-success')}
                header={translate('success')}
              />
            </div>
          )}
        </Mutation>
      </>
    )
  }
}
