import gql from 'graphql-tag'
import { getUserAnalytics } from './getUserAnalytics'

type AddUserAnalyticsParams = {
  type: string
  payload: {
    videoId?: string
    channelId?: string
    trackId?: string
    time?: number
    url?: string
    version?: number
    lang?: string
  }
}

export const addUserAnalyticsMutation = () => ({
  mutation: gql`
    mutation addUserAnalytics($input: REST!) {
      addedUserAnalytics(input: $input)
        @rest(type: "UserAnalytics", path: "/user-analytics", method: "POST", endpoint: "command") {
        id
      }
    }
  `,
})

export const addUserAnalyticsVariables = (input: AddUserAnalyticsParams, skipRefetch?: boolean) => ({
  variables: {
    input,
  },
  refetchQueries: input.type === 'videoPlaybackEnded' && !skipRefetch ? [getUserAnalytics()] : [],
})

export const ADD_USER_ANALYTICS_MUTATION = gql`
  mutation addUserAnalytics($input: REST!) {
    addedUserAnalytics(input: $input)
      @rest(type: "UserAnalytics", path: "/user-analytics", method: "POST", endpoint: "command") {
      id
    }
  }
`
