import ReactDOM from 'react-dom'
import React from 'react'
import videojs from 'video.js'

import { Provider } from 'react-redux'
import store from 'src/store'
import VideoSwitch from './VideoSwitch'

var BaseComponent = videojs.getComponent('Component')

class VideoPlayerSwitchContainer extends BaseComponent {
  constructor(player, options) {
    super(player, options)
    this.mountReactComponent = this.mountReactComponent.bind(this)
    player.ready(() => this.mountReactComponent())
  }
  mountReactComponent() {
    ReactDOM.render(
      <Provider store={store}>
        <VideoSwitch />
      </Provider>,
      this.el()
    )
  }
}

export default VideoPlayerSwitchContainer
