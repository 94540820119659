import { keys, includes } from 'lodash'
import * as React from 'react'
import moment from 'moment'
import * as qs from 'query-string'
import { connect } from 'react-redux'
import { Query, Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button, Icon, IconButton } from '@material-ui/core'
import { VideoPlayerWithLoader, VideoPlayer } from 'src/components/reusable/VideoPlayer'

import styles from './VideoEmbedCodes.styles'
import { getTranslation } from 'src/translation'
import { getVideoDuration } from 'src/utils/cards'
import Animate from '../../../theme/components/Animate'
import { replaceTranslationSkipCharacters, replaceBreaks } from 'src/utils/common'
import { getVideoEmbed, GetVideoEmbed } from 'src/gql/getVideoEmbed'
import { addAnonymousAnalyticsMutation, addAnonymousAnalyticsVariables } from 'src/gql/addAnonymousAnalytics'
import classNames from 'classnames'

import '../../../styles/index.css'

interface State {
  isInfoExpanded: boolean
}

// @ts-ignore
@withStyles(styles)
@connect((state, ownProps) => {
  return {
    ...ownProps,
    tubeId: state.auth.tube.id,
    orgId: ownProps.orgId || state.auth.organization.id || state.auth.user.data.organization.id, // todo: fix state
    translate: getTranslation(state.translation.lang),
    tubeColor: state.auth.tube.color,
  }
})
export default class PublicEmbed extends React.Component<any, State> {
  constructor(props) {
    super(props)

    this.state = {
      isInfoExpanded: false,
    }
  }

  render() {
    const { classes, hasActiveSubscription, translate, tubeColor, logo } = this.props

    const renderItemWithLink = (component: any, linksToShow) => {
      return (
        <div className={classes.linkParentWrapper}>
          <div className={classes.linkInner}>
            {component}
            {this.state.isInfoExpanded ? (
              <div className={classNames(classes.expandedLink)}>
                <div className="ds-flex" style={{ fontSize: 24 }}>
                  <div className="ml--15">{translate('related-link')}</div>
                  <div className="ml-auto" style={{ cursor: 'pointer' }}>
                    <Icon onClick={() => this.setState({ isInfoExpanded: false })}>close</Icon>
                  </div>
                </div>

                {(linksToShow || [])
                  .filter(chapterLink => !!chapterLink.link && !!chapterLink.link.url && !!chapterLink.link.url.trim())
                  .map(chapterLink => (
                    <div
                      className={classNames('ds-flex flex-column align-center mt-25 w-75', classes.linkWrapper)}
                      onClick={() => window.open(chapterLink.link.url, '_blank')}
                    >
                      <div
                        title={chapterLink.link.title}
                        className={classes.linkTitleWrapper}
                        style={{ color: tubeColor }}
                      >
                        {chapterLink.link.title}
                      </div>

                      <div className={classNames(classes.link)}>{translate('open-link')}</div>
                    </div>
                  ))}
              </div>
            ) : (
              <>
                {!!(linksToShow || []).filter(
                  chapterLink => !!chapterLink.link && !!chapterLink.link.url && !!chapterLink.link.url
                ).length && (
                  <div
                    // style={{ top: showFavoritesIcon ? 90 : 50 }}
                    className={classNames('ds-flex', classes.expandView, classes.infoBtn, classes.expandViewHover)}
                    onClick={() => this.setState({ isInfoExpanded: true })}
                  >
                    <span className={classes.viewProgress}>{translate('related-link')}</span>

                    <IconButton className={classNames(classes.chartButton)}>
                      <Icon>info</Icon>
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )
    }

    return (
      <Query
        {...getVideoEmbed(
          window.location.href
            .split('?')[0]
            .split('/')
            .pop(),
          this.props.orgId
        )}
      >
        {({ loading, error, data }: GetVideoEmbed) => {
          if (loading) {
            return (
              <p style={{ background: 'white', width: '100%', height: '100%' }}>{this.props.translate('loading')}</p>
            )
          }
          if (error) {
            // @ts-ignore
            const message = error.networkError.result ? error.networkError.result.message : error.message

            return (
              <div className="flex flex-col flex-1 items-center justify-center p-16">
                <div className="max-w-512 text-center">
                  <Animate delay={500}>
                    <Typography variant="headline" color="textSecondary" className="mb-16 capitalize">
                      {message}
                    </Typography>
                  </Animate>

                  <Animate delay={600}>
                    <Typography variant="subheading" color="textSecondary" className="mb-48">
                      {this.props.translate('video-not-found')}
                    </Typography>
                  </Animate>
                </div>
              </div>
            )
          }

          const { abstract, createdAt, description, name, duration, type, renderingData } = data.getFromEmbedRes as any

          const subtitles = keys(renderingData || {}).reduce((r, lang) => {
            if (!renderingData[lang].subtitles) return r

            return [
              ...r,
              {
                language: lang,
                chapters: renderingData[lang].subtitles.map(data => ({
                  ...data,
                  manuscript: replaceBreaks(replaceTranslationSkipCharacters(data.manuscript)),
                })),
              },
            ]
          }, [])

          const videoDuration = duration ? getVideoDuration(duration / 1000) : null

          const createdAtDate = moment(new Date(createdAt)).format('MMMM Do, YYYY')

          const isIframe = includes(window.location.href, '/sharing/embed/')

          const parsedQuerySting = qs.parse(location.search)
          const subTitleLang = parsedQuerySting && parsedQuerySting.subTitleLang

          const masterLang = data.getFromEmbedRes.masterLanguage

          const linksToShow =
            renderingData &&
            renderingData[data.getFromEmbedRes.language] &&
            renderingData[data.getFromEmbedRes.language].videoLinks
              ? renderingData[data.getFromEmbedRes.language].videoLinks
              : renderingData && renderingData[masterLang] && renderingData[masterLang].videoLinks
              ? renderingData[masterLang].videoLinks
              : []

          return data.getFromEmbedRes.publicEmbed ? (
            <Mutation {...addAnonymousAnalyticsMutation(this.props.orgId)}>
              {addAnonymousAnalyticsReq => {
                if (loading) {
                  return (
                    <p style={{ background: 'white', width: '100%', height: '100%' }}>
                      {this.props.translate('loading')}
                    </p>
                  )
                }
                if (error) {
                  return <p>{error.message}</p>
                }

                return (
                  <div style={{ background: 'white', width: '100%', height: '100%', overflow: 'auto' }}>
                    {isIframe ? (
                      <div>
                        {renderItemWithLink(
                          <VideoPlayer
                            isEmbedVideo={true}
                            source={data.getFromEmbedRes.url}
                            videoDuration={videoDuration}
                            ribbonType={type}
                            tubeColor={'rgb(0,0,0,0.8)'}
                            showTTLogo={isIframe}
                            links={linksToShow}
                            isVideoLinksFeatureEnabled={true}
                            thumbnail={data.getFromEmbedRes.thumbnail}
                            subtitlesLang={subTitleLang}
                            mainLanguage={data.getFromEmbedRes.language}
                            onPlaybackEvent={(event, time) => {
                              addAnonymousAnalyticsReq(
                                addAnonymousAnalyticsVariables({
                                  type: event,
                                  payload:
                                    event === 'videoTimeWatched'
                                      ? {
                                          videoId: data.getFromEmbedRes.videoId,
                                          time,
                                          version: data.getFromEmbedRes.lastVersion,
                                          lang: data.getFromEmbedRes.language,
                                        }
                                      : {
                                          videoId: data.getFromEmbedRes.videoId,
                                          version: data.getFromEmbedRes.lastVersion,
                                          lang: data.getFromEmbedRes.language,
                                        },
                                })
                              )
                            }}
                            voiceOver={subtitles}
                          />,
                          linksToShow
                        )}
                      </div>
                    ) : (
                      <div className={classes.bodyWrapper}>
                        <div className={classes.headerWrapper}>
                          <img
                            onClick={() =>
                              !hasActiveSubscription && (window.location.href = 'https://www.launchpeople.com/')
                            }
                            className={classes.logoImg}
                            style={hasActiveSubscription ? { cursor: 'default' } : {}}
                            src={hasActiveSubscription ? logo : '/assets/images/logos/training-tube.png'}
                            alt="trainingtube"
                          />
                          {!hasActiveSubscription ? (
                            <Button
                              style={{ backgroundColor: '#A6419A' }}
                              onClick={() => {
                                window.location.href = 'https://www.trainingtube.com/booking-a-demo'
                              }}
                              className={classes.trainingTubeRedirect}
                            >
                              {this.props.translate('get-training-tube')}
                            </Button>
                          ) : (
                            <Button
                              style={{ backgroundColor: '#A6419A' }}
                              onClick={() => {
                                window.location.href = `https://www.trainingtube.com/login?redirectTo=/&commingFrom=${this.props.orgId}`
                              }}
                              className={classes.trainingTubeRedirect}
                            >
                              {this.props.translate('login')}
                            </Button>
                          )}
                        </div>

                        <div className={classes.cardWrapper}>
                          <div className={classes.innerCard}>
                            <div className={`embedded-player  ${classes.videoWrapper}`}>
                              {renderItemWithLink(
                                <div
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                  }}
                                >
                                  <VideoPlayer
                                    isEmbedVideo={true}
                                    subtitlesLang={subTitleLang}
                                    mainLanguage={data.getFromEmbedRes.language}
                                    source={data.getFromEmbedRes.url}
                                    videoDuration={videoDuration}
                                    ribbonType={type}
                                    links={linksToShow}
                                    isVideoLinksFeatureEnabled={true}
                                    tubeColor={'rgb(0,0,0,0.8)'}
                                    thumbnail={data.getFromEmbedRes.thumbnail}
                                    onPlaybackEvent={(event, time) => {
                                      addAnonymousAnalyticsReq(
                                        addAnonymousAnalyticsVariables({
                                          type: event,
                                          payload:
                                            event === 'videoTimeWatched'
                                              ? {
                                                  videoId: data.getFromEmbedRes.videoId,
                                                  time,
                                                  version: data.getFromEmbedRes.lastVersion,
                                                  lang: data.getFromEmbedRes.language,
                                                }
                                              : {
                                                  videoId: data.getFromEmbedRes.videoId,
                                                  version: data.getFromEmbedRes.lastVersion,
                                                  lang: data.getFromEmbedRes.language,
                                                },
                                        })
                                      )
                                    }}
                                    voiceOver={subtitles}
                                  />
                                </div>,
                                linksToShow
                              )}
                            </div>
                            <div className={classes.videoName}>{replaceTranslationSkipCharacters(name)}</div>
                            <div className={classes.videoAbstract}>{replaceTranslationSkipCharacters(abstract)}</div>

                            <div className={classes.imageContainer}>
                              <div className={classes.videoCreatedDate}>
                                <div>{createdAtDate}</div>
                              </div>
                            </div>

                            {description !== '<p><br></p>' && (
                              <>
                                <div className={classes.descriptionTitle}>{this.props.translate('description')}</div>
                                <div
                                  className={classes.descriptionBody}
                                  dangerouslySetInnerHTML={{
                                    __html: replaceTranslationSkipCharacters(description),
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              }}
            </Mutation>
          ) : (
            <VideoPlayerWithLoader
              isEmbedVideo={true}
              lang={data.getFromEmbedRes.language}
              subtitlesLang={subTitleLang}
              mainLanguage={data.getFromEmbedRes.language}
              masterLanguage={data.getFromEmbedRes.masterLanguage}
              videoId={data.getFromEmbedRes.videoId}
              lastVersion={data.getFromEmbedRes.lastVersion}
              source={data.getFromEmbedRes.url}
              thumbnail={data.getFromEmbedRes.thumbnail}
              subtitles={subtitles}
            />
          )
        }}
      </Query>
    )
  }
}
