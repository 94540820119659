import * as React from 'react'
import copy from 'copy-to-clipboard'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Icon,
  Snackbar,
} from '@material-ui/core'
import { Translate, getTranslation } from 'src/translation'
import { connect } from 'react-redux'
import { ReduxState } from 'src/store/reducers'

type Props = {
  open: boolean
  url: string
  translate?: Translate
  onClose: () => void
}

type State = {
  openSnackbar: boolean
}

class UnsupportedDialog extends React.PureComponent<Props, State> {
  state = {
    openSnackbar: false,
  }
  private copyStringToClipboard = () => {
    copy(`${window.location.origin}${this.props.url}`)

    this.setState({ openSnackbar: true })
    setTimeout(() => this.setState({ openSnackbar: false }), 2 * 1000)
  }

  public render() {
    const { open, translate, url, onClose } = this.props
    const { openSnackbar } = this.state

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{translate('browser-not-supported')}</DialogTitle>
        <DialogContent>
          <div>{translate('browser-not-supported-description-01')}</div>
          <br />
          <div>{translate('browser-not-supported-description-02')}</div>
          <br />
          <div>{translate('browser-not-supported-description-03')}</div>
          <br />
          <div>
            <div>{translate('video-link')}</div>
            <div className="flex items-center">
              <span style={{ width: 'calc(100% - 48px)' }}>
                {window.location.origin}
                {url}
              </span>
              <IconButton id="file-copy-btn" onClick={this.copyStringToClipboard}>
                <Icon>file_copy</Icon>
              </IconButton>
            </div>
          </div>
          <br />
          <div>{translate('browser-not-supported-description-04')}</div>
          <br />
          <div>{translate('browser-not-supported-description-05')}</div>
          <Snackbar open={openSnackbar} message={<span>{translate('copied-to-clipboard')}</span>} />
        </DialogContent>
        <DialogActions>
          <Button name="Cancel" id={`cancel-btn`} onClick={onClose}>
            {translate('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  translate: getTranslation(state.translation.lang),
})

export default connect(mapStateToProps, null)(UnsupportedDialog)
