import * as React from 'react'

interface MessageProps {
  html: string
  inline?: boolean
  className?: string
}

class Message extends React.Component<MessageProps, {}> {
  element: HTMLSpanElement | null

  render() {
    const { html, className, inline } = this.props
    if (inline) {
      return (
        <span
          className={className}
          ref={element => (this.element = element)}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )
    }

    return (
      <p className={className} ref={element => (this.element = element)} dangerouslySetInnerHTML={{ __html: html }} />
    )
  }
}

export default Message
