import axios from 'axios'
import * as React from 'react'
import { includes } from 'lodash'
import { Mutation } from 'react-apollo'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import {
  Icon,
  Button,
  Dialog,
  Popover,
  MenuItem,
  TextField,
  IconButton,
  DialogTitle,
  ListItemText,
  ListItemIcon,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Avatar } from '@material-ui/core'

import store from 'src/store'
import Helmet from './Helmet'
import history from 'src/history'
import { mainApi } from 'src/config'
import HelpDialog from './HelpDialog'
import ChannelList from './ChannelList'
import styles from './FullToolbar.style'
import NotificationsBar from './Notifications'
import { getInitials } from 'src/utils/common'
import { Channel } from 'src/gql/listChannels'
import { toggleSidebar } from 'src/store/actions'
import CreateLinkDialog from './CreateLinkDialog'
import { UserProfile } from 'src/interfaces/User'
import Animate from 'src/theme/components/Animate'
import UserProfileDialog from './UserProfileDialog'
import { compose, isLPUser } from 'src/utils/common'
import { User, ReduxState } from 'src/store/reducers'
import { addNotification } from '../../../NotificationWrapper'
import { changeLang } from 'src/translation/store/actions'
import { GetTube, getTubeData } from 'src/gql/queries/getTube'
import { Notification } from 'src/gql/queries/getNotifications'
import { ErrorDialog } from 'src/components/reusable/ErrorDialog'
import * as authActions from 'src/auth/store/actions/user.actions'
import { getLanguageData, getNotifications, getUserPermissions } from 'src/auth/cognito/organizationApi'
import { setUserProfile } from 'src/auth/store/actions/user.actions'

import { SuccessDialog } from 'src/components/reusable/SuccessDialog'
import { updateChannelList } from 'src/store/actions/channel.actions'
import { setLanguageData } from 'src/auth/store/actions/lang.actions'
import UnsupportedDialog from 'src/components/reusable/UnsupportedDialog'
import { getTranslation, getLanguages, Translate } from 'src/translation'
import { changeTooltipSettings } from 'src/auth/store/actions/user.actions'
import { listChannelsData, ListChannels } from 'src/gql/queries/listChannels'
import { SupportForm } from 'src/components/routes/channel/users/SupportForm'
import UserInitialConfigurationDialog from './UserInitialConfigurationDialog'
import { userCommandMutation, userCommandVariables } from 'src/gql/userCommand'
import { addCreateLeadMutation, addCreateLeadVariables } from 'src/gql/zendesk'
import { getUserAnalyticsData, GetUserAnalytics } from 'src/gql/queries/getUserAnalytics'
import {
  isEnableRequestDemoFeatureEnabled,
  isNewChannelLayoutFeatureEnabled,
  isNotificationsFeatureEnabled,
  isUserProfileFeatureEnabled,
} from 'src/utils/featureFlagChecks'
import BookDemoDialog from 'src/components/reusable/BookDemoDialog'
import { countries } from 'src/constants/countries'
import GetSupportDialog from 'src/components/reusable/GetSupportDialog'
import SearchMenu from 'src/components/reusable/SearchMenu'

interface MenuItem {
  to: string
}

type Props = {
  user: User
  email: string
  channelId: string
  language: string
  location: string
  tubeColor: string
  isLPUser: boolean
  sidebarStatus: boolean
  showUserEmail: boolean
  organizationLanguages: string[]
  profile?: UserProfile
  isViewOnlyMode: boolean
  listChannelsQuery: ListChannels
  isUserProfileFeatureEnabled: boolean
  isNewChannelLayoutEnabled: boolean
  isNotificationsFeatureEnabled: boolean
  getUserAnalyticsQuery: GetUserAnalytics

  channelList: Channel[]
  isChannelLoaded: boolean
  externalOrgsUserHasAccessTo: any
  originalOrgId?: string
  switchedOrgId?: string
  classes?: {
    root: string
    logo: string
    search: string
    listItem: string
    toolbarTextActive: string
    success: string
    userMenu: string
    chatIcon: string
    demoButton: string
    loginButton: string
    langSelect: string
    profilePictureDefault: string
    profilePicText: string
    langItem: string
    channelImg: string
    seperator: string
    hamburger: string
    centerContainer: string
    notificationIcon: string
    centerContainter: string
    hamburgerWrapper: string
    notificationCountBadge: string
    toolbarText: string
    toolbarLink: string
  }
  routes: {
    path: string
    component: React.Component
  }[]
  orgId: string
  userEmail: string
  translate: Translate
  logout: () => void
  searchValue: string
  isDevStage: boolean
  getTubeQuery: GetTube
  tooltipDisabled: boolean
  isConfigurationCompleted: boolean
  history: {
    push: (route: string) => void
  }
  notifications: Notification[]
  changeLang: (lang: string) => void
  toggleSidebar: (active: boolean) => void
  savePreferredLanguage: (lang: string) => void
  saveLocation: (location: string) => void
  changeTooltipSettings: (data: boolean) => void
  updateChannelList: (data: any) => void
  createAssistedSearchTask: (taskTitle: string) => Promise<{ data: { res: { taskId: string } } }>
  isEnableRequestDemoFeatureEnabled: boolean
  updateOrganization: boolean
}

type State = {
  error: any
  addMenu: any
  userMenu: any
  isLanguageMenuOpen: boolean
  isLocationMenuOpen: boolean
  isNetworkMenuOpen: boolean
  isOrgMenuOpen: boolean
  search: string
  helpDialog: boolean
  notificationsMenu: any
  openAddDialog: boolean
  isTubeDataLoaded: boolean
  createLinkOpen: boolean
  showAllLanguages: boolean
  showSuccessForm: boolean
  selectedAddAction: string
  unSupportedDialog: boolean
  isSupportDialogOpen: boolean
  isBookDemoDialogOpen: boolean
  isUserProfileDialogOpen: boolean
  isNewUserConfigurationDialogOpen: boolean

  isSearching: boolean
  errorMessage: string | null
}

const MAX_NOTIFICATIONS_TO_SHOW = 10

// @ts-ignore
@withStyles(styles, { withTheme: true })
class MainToolbar extends React.PureComponent<Props, State> {
  inFeatureVideoChecked = false

  state = {
    error: null,
    addMenu: null,
    userMenu: null,
    isLanguageMenuOpen: false,
    isLocationMenuOpen: false,
    isNetworkMenuOpen: false,
    isOrgMenuOpen: false,
    helpDialog: false,
    openAddDialog: false,
    selectedAddAction: '',
    showAllLanguages: false,
    createLinkOpen: false,
    showSuccessForm: false,
    notificationsMenu: null,
    unSupportedDialog: false,
    isSupportDialogOpen: false,
    isBookDemoDialogOpen: false,
    isUserProfileDialogOpen: false,
    isTubeDataLoaded: false,
    search: this.props.searchValue,
    isNewUserConfigurationDialogOpen: false,

    isSearching: false,
    errorMessage: null,
  }

  public onSupportFormSubmit = async (values, createLeadReq) => {
    if (values) {
      const orgId = this.props.orgId

      try {
        const description = `
        ${values.description || 'N/A'}


        URL: ${window.location.href}
        `
        const data = {
          orgId,
          body: description,
          requesterName: `${values.firstName} ${values.lastName}`,
          requesterEmail: this.props.userEmail || this.props.email || 'N/A',
          phoneNumber: values.phoneNumber,
          subject: values.title,
        }

        localStorage.setItem('firstName', values.firstName)
        localStorage.setItem('lastName', values.lastName)
        if (!!values.phoneNumber) {
          localStorage.setItem('phoneNumber', values.phoneNumber)
        }
        await createLeadReq(
          addCreateLeadVariables({
            type: 'createTicket',
            payload: data,
          })
        )

        this.setState({
          showSuccessForm: true,
          isSupportDialogOpen: false,
        })
      } catch (e) {
        this.setState({
          error: e.networkError && e.networkError.result ? e.networkError.result.message : e.message,

          isSupportDialogOpen: false,
        })
      }
    } else {
      this.setState({
        isSupportDialogOpen: false,
      })
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.searchValue !== nextProps.searchValue) {
      this.setState({ search: nextProps.searchValue })
    }
  }

  private userMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ userMenu: event.currentTarget })
  }

  private userMenuClose = () => {
    this.setState({ userMenu: null })
    setTimeout(() => {
      this.setState({
        isOrgMenuOpen: false,
        isLanguageMenuOpen: false,
        isLocationMenuOpen: false,
      })
    }, 500)
  }

  private langMenuClick = () => {
    this.setState({ isLanguageMenuOpen: true })
  }

  private langMenuClose = () => {
    this.setState({ isLanguageMenuOpen: false })
  }

  private orgMenuClick = () => {
    this.setState({ isOrgMenuOpen: true })
  }
  private orgMenuClose = () => {
    this.setState({ isOrgMenuOpen: false })
  }

  private locationMenuClick = () => {
    this.setState({ isLocationMenuOpen: true })
  }

  private locationMenuClose = () => {
    this.setState({ isLocationMenuOpen: false })
  }

  private networkMenuClick = () => {
    this.setState({ isNetworkMenuOpen: true })
  }

  private networkMenuClose = () => {
    this.setState({ isNetworkMenuOpen: false })
  }

  private notificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ notificationsMenu: event.currentTarget })
  }

  private addMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1

    if (isIE) {
      this.setState({ unSupportedDialog: true })
    } else {
      this.setState({ addMenu: event.currentTarget })
    }
  }

  private onUnsupportedDialogClose = () => this.setState({ unSupportedDialog: false })

  private addMenuClose = () => {
    this.setState({ addMenu: null })
  }

  private handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false })
  }

  // private handleOpenHelpDialog = () => {
  //   this.setState({ helpDialog: true })
  // }

  private handleCloseHelpDialog = (tooltip: boolean) => {
    this.setState({ helpDialog: false })
    this.props.changeTooltipSettings(tooltip)
  }

  private onLangClick = (lang: string, skipNotification?: boolean) => () => {
    this.props.changeLang(lang)
    this.props.savePreferredLanguage(lang)
    getLanguageData(lang).then(res => {
      // en-us is used from the local
      if (res.data.lang !== 'en-us') {
        setLanguageData(res.data.lang, res.data.data)(store.dispatch)
      }

      // Except english and danish all other languages are auto translated
      const isAutoTranslatedLang = !includes(['en-us', 'da'], lang)

      if (!skipNotification) {
        addNotification({
          children: (
            <div className="custom-notification">
              <div className={classNames(this.props.classes.success, 'custom-notification__icon')}>
                <Icon>check</Icon>
              </div>
              <div className="custom-notification__text">
                <p className="word-break">{`${this.props.translate('lang-changed')} ${this.props.translate(lang)}. ${
                  isAutoTranslatedLang ? this.props.translate('lang-changed-auto') : ''
                }`}</p>
              </div>
            </div>
          ),
          position: 'tr',
          autoDismiss: 5,
          level: 'success',
        })
      }
      // fetch new notifications languages
      getNotifications()
    })

    this.setState({
      addMenu: false,
      isLanguageMenuOpen: false,
      userMenu: null,
      isLocationMenuOpen: false,
    })
  }

  private onCountryClick = (location: string) => async () => {
    try {
      await this.props.saveLocation(location)
    } finally {
      const data = await getUserPermissions()
      setUserProfile(data.userData)(store.dispatch)
      addNotification({
        children: (
          <div className="custom-notification">
            <div className={classNames(this.props.classes.success, 'custom-notification__icon')}>
              <Icon>check</Icon>
            </div>
            <div className="custom-notification__text">
              <p className="word-break">{`${this.props.translate('country-changed-to')} ${location} `}</p>
            </div>
          </div>
        ),
        position: 'tr',
        autoDismiss: 5,
        level: 'success',
      })
      // fetch new notifications languages
      getNotifications()
      this.setState({
        addMenu: null,
        isLanguageMenuOpen: false,
        userMenu: null,
        isLocationMenuOpen: false,
      })
    }
  }

  // @ts-ignore
  private onRecord = () => (window.location = 'tt-desktop://record')

  // @ts-ignore
  private onRecordVideo = () => (window.location = 'trainingtube-recorder://')

  private toggleSidebar = event => {
    this.props.toggleSidebar(!this.props.sidebarStatus)
  }

  private onSelectAddAction = (type: string) => () => this.setState({ selectedAddAction: type })

  private onUnselectAddAction = () => this.setState({ selectedAddAction: '' })

  public render() {
    const {
      classes,
      user,
      email,
      orgId,
      logout,
      routes,
      profile,
      isLPUser,
      language,
      translate,
      tubeColor,
      isDevStage,
      getTubeQuery,
      showUserEmail,
      notifications,
      originalOrgId,
      switchedOrgId,
      isViewOnlyMode,
      isChannelLoaded,
      listChannelsQuery,
      organizationLanguages,
      getUserAnalyticsQuery,
      isUserProfileFeatureEnabled,
      isNewChannelLayoutEnabled,
      externalOrgsUserHasAccessTo,
      isNotificationsFeatureEnabled,
    } = this.props

    const {
      userMenu,
      addMenu,
      isLanguageMenuOpen,
      isLocationMenuOpen,
      isNetworkMenuOpen,
      isOrgMenuOpen,
      helpDialog,
      selectedAddAction,
      notificationsMenu,
      unSupportedDialog,
      createLinkOpen,
      showAllLanguages,
      isNewUserConfigurationDialogOpen,
      isUserProfileDialogOpen,
    } = this.state

    const mainApiUrl = mainApi(isDevStage)

    const languagesToShow = (showAllLanguages || !organizationLanguages.length
      ? getLanguages()
      : organizationLanguages
    ).filter(lang => lang !== language)

    const logo =
      getTubeQuery.loading || getTubeQuery.error
        ? ''
        : getTubeQuery.tube.logo
        ? getTubeQuery.tube.logo
        : '/assets/images/logo.png'

    const unseenNotifications = notifications.filter(notification => !notification.isSeen)

    if (!isChannelLoaded && !listChannelsQuery.loading) {
      this.props.updateChannelList(listChannelsQuery.channels)
    }

    const userName = `${profile.firstName || ''} ${profile.lastName || ''}`

    // Only show the dialog for new users when the tube data is fetched
    // This is done to stop this dialoag from being shown on initial login
    if (
      !!getTubeQuery.tube &&
      !getTubeQuery.loading &&
      !this.props.isConfigurationCompleted &&
      // This flag is just used to make sure this flag is only loaded once
      !this.state.isTubeDataLoaded &&
      !this.state.isNewUserConfigurationDialogOpen
    ) {
      this.setState({ isNewUserConfigurationDialogOpen: true, isTubeDataLoaded: true })
    }

    return (
      <div className={classNames(classes.root, 'flex justify-between flex-row')}>
        <ErrorDialog
          open={Boolean(this.state.errorMessage)}
          message={this.state.errorMessage}
          onClose={() => this.setState({ errorMessage: null })}
        />

        <UnsupportedDialog
          open={unSupportedDialog}
          url={history.location.pathname}
          onClose={this.onUnsupportedDialogClose}
        />
        <Helmet />
        <div className="flex items-center">
          {!isViewOnlyMode && (
            <div onClick={this.toggleSidebar} className={classes.hamburgerWrapper}>
              <Icon className={classes.hamburger}>menu</Icon>
            </div>
          )}
          {!isViewOnlyMode ? (
            <Link id="home-link" to="/home">
              {Boolean(logo) && <img alt="logo" className={classes.logo} src={logo} />}
            </Link>
          ) : (
            <span> {Boolean(logo) && <img alt="logo" className={classes.logo} src={logo} />}</span>
          )}

          {this.props.isEnableRequestDemoFeatureEnabled && (
            <Button
              id="request-demo-button"
              className={`ml-4 ${classes.demoButton}`}
              onClick={() => {
                this.setState({
                  isBookDemoDialogOpen: true,
                })
              }}
            >
              {translate('request-demo')}
            </Button>
          )}
        </div>
        {!isViewOnlyMode && (
          <div className={classNames('flex items-center w-1/3', classes.centerContainer)}>
            {!isNewChannelLayoutEnabled && listChannelsQuery.channels && (
              // @ts-ignore
              <ChannelList channels={listChannelsQuery.channels} loading={listChannelsQuery.loading} />
            )}

            <SearchMenu isInMainToolbar={true} />
          </div>
        )}
        <div className="flex items-center">
          {!isViewOnlyMode && (
            <>
              {includes(navigator.userAgent, 'tt-desktop') && (
                <IconButton id="video-call-btn" className="w-64 h-64" onClick={this.onRecord}>
                  <Icon>video_call</Icon>
                </IconButton>
              )}
              <Dialog
                open={this.state.openAddDialog}
                onClose={this.handleCloseAddDialog}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">{translate('subscribe')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{translate('subscribeDescription')}</DialogContentText>
                  <TextField
                    autoFocus={true}
                    margin="dense"
                    id="name"
                    label={translate('email')}
                    type="email"
                    fullWidth={true}
                  />
                </DialogContent>
                <DialogActions>
                  <Button id="cancel-btn" name={'Cancel'} onClick={this.handleCloseAddDialog} color="primary">
                    {translate('cancel')}
                  </Button>
                  <Button id="subscribe-btn" name={'Subscribe'} onClick={this.handleCloseAddDialog} color="primary">
                    {translate('subscribe')}
                  </Button>
                </DialogActions>
              </Dialog>

              <Popover
                open={Boolean(addMenu)}
                anchorEl={addMenu}
                onClose={this.addMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{
                  paper: 'py-0',
                }}
              >
                {user.role && (
                  <React.Fragment>
                    {!originalOrgId && (
                      <MenuItem
                        id="add-channel-link"
                        name="Add channel"
                        component={Link}
                        {...{ to: '/channel/create' }}
                        onClick={this.addMenuClose}
                        style={selectedAddAction === 'channel' ? { backgroundColor: tubeColor } : {}}
                        onMouseEnter={this.onSelectAddAction('channel')}
                        onMouseLeave={this.onUnselectAddAction}
                        className={classes.listItem}
                      >
                        <div className="pl-0" style={{ color: selectedAddAction === 'channel' ? 'white' : tubeColor }}>
                          {translate('add-channel')}
                        </div>
                      </MenuItem>
                    )}
                  </React.Fragment>
                )}
              </Popover>
              <GetSupportDialog />

              {!isNewChannelLayoutEnabled && (
                <Button className={`w-44 h-64 ${classes.notificationIcon}`} id="add-button" onClick={this.addMenuClick}>
                  <div className="md:flex justify-center items-center">
                    <Icon className="material-icons-outlined" style={{ fontSize: 33 }}>
                      video_call
                    </Icon>
                  </div>
                </Button>
              )}

              {helpDialog && (
                <HelpDialog
                  open={helpDialog}
                  isLoadingComplete={!listChannelsQuery.loading && !getTubeQuery.loading}
                  routes={routes}
                  translate={translate}
                  onLangClick={lang => {
                    this.props.changeLang(lang)
                    this.props.savePreferredLanguage(lang)
                  }}
                  onClose={this.handleCloseHelpDialog}
                  videosWatched={
                    getUserAnalyticsQuery.userAnalytics && getUserAnalyticsQuery.userAnalytics.videosWatched
                      ? getUserAnalyticsQuery.userAnalytics.videosWatched
                      : {}
                  }
                />
              )}

              {this.state.isBookDemoDialogOpen && (
                <BookDemoDialog
                  open={this.state.isBookDemoDialogOpen}
                  onClose={() => {
                    this.setState({
                      isBookDemoDialogOpen: false,
                    })
                  }}
                />
              )}

              {isNotificationsFeatureEnabled && (
                <Button
                  className={`w-44 h-64 ${classes.notificationIcon}`}
                  id="notification-select"
                  onClick={this.notificationClick}
                >
                  <div className="md:flex justify-center items-center">
                    <Icon style={{ fontSize: 33 }} className="material-icons-outlined">
                      notifications
                    </Icon>
                    {!!unseenNotifications.length && (
                      <div className={classes.notificationCountBadge} style={{ color: tubeColor }}>
                        {unseenNotifications.length}
                      </div>
                    )}
                  </div>
                </Button>
              )}
            </>
          )}

          {isNotificationsFeatureEnabled && !!notificationsMenu && (
            <Popover
              open={!!notificationsMenu}
              anchorEl={notificationsMenu}
              onClose={() => {
                this.setState({ notificationsMenu: null })
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  backgroundColor: 'rgba(255, 255, 255, 0.98)',
                },
              }}
            >
              <Mutation {...userCommandMutation()}>
                {userCommandReq => {
                  const notificationsToShow = notifications.slice(0, MAX_NOTIFICATIONS_TO_SHOW)

                  return (
                    <NotificationsBar
                      tubeColor={tubeColor}
                      translate={translate}
                      notifications={notificationsToShow}
                      updateSeenStatus={() =>
                        userCommandReq(
                          userCommandVariables({
                            type: 'updateNotificationIsSeenStatus',
                            payload: {
                              isSeen: true,
                              notificationIds: notificationsToShow
                                .filter(notification => !notification.isSeen)
                                .map(notification => notification.id),
                            },
                          })
                        )
                      }
                      history={this.props.history}
                      updateIsOpenedStatus={(notificationId: string) =>
                        userCommandReq(
                          userCommandVariables({
                            type: 'updateNotificationIsOpenedStatus',
                            payload: {
                              notificationId,
                              isOpened: true,
                            },
                          })
                        )
                      }
                      channels={listChannelsQuery.channels}
                    />
                  )
                }}
              </Mutation>
            </Popover>
          )}

          {!isViewOnlyMode && (
            <Animate delay={300}>
              <Button
                className={classNames('h-64', classes.userMenu)}
                id="user-menu-btn"
                name="user-menu"
                onClick={this.userMenuClick}
              >
                {profile.profilePic ? (
                  <div className={classes.profilePictureDefault} style={{ margin: 0 }}>
                    <img src={profile.profilePic} alt="pic" className="w-100" />
                  </div>
                ) : (
                  <div className={classes.profilePictureDefault}>
                    <span className={classes.profilePicText}>
                      {profile.firstName || profile.lastName
                        ? getInitials(userName)
                        : (profile.email || '').slice(0, 2)}
                    </span>
                  </div>
                )}

                <Icon className="text-16 ml-12 hidden sm:flex">keyboard_arrow_down</Icon>
              </Button>
            </Animate>
          )}

          <Popover
            open={Boolean(userMenu)}
            anchorEl={userMenu}
            onClose={this.userMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              paper: 'py-0',
            }}
          >
            {!isLanguageMenuOpen && !isOrgMenuOpen && !isLocationMenuOpen && !isNetworkMenuOpen && (
              <div style={{ minWidth: '305px' }}>
                {showUserEmail && (
                  <>
                    <MenuItem
                      id="create-link"
                      name="create-link"
                      className={classes.listItem}
                      onClick={() => {
                        this.setState({ createLinkOpen: true })
                        this.userMenuClose()
                      }}
                    >
                      <ListItemText className="pl-0" primary={`${translate('loggedInAs')} ${email}`} />
                    </MenuItem>
                  </>
                )}

                <MenuItem
                  id="switch-language"
                  name="switch-language"
                  className={classes.listItem}
                  onClick={this.langMenuClick}
                >
                  <ListItemIcon>
                    <Icon>translate</Icon>
                  </ListItemIcon>

                  <ListItemText className={classNames('pl-0', classes.toolbarText)} children={translate(language)} />
                  <ListItemIcon>
                    <Icon>chevron_right</Icon>
                  </ListItemIcon>
                </MenuItem>
                {((externalOrgsUserHasAccessTo && !!externalOrgsUserHasAccessTo.length) || originalOrgId) && (
                  <MenuItem
                    id="switch-organization"
                    name="switch-organization"
                    className={classes.listItem}
                    onClick={this.orgMenuClick}
                  >
                    <ListItemIcon>
                      <Icon>store</Icon>
                    </ListItemIcon>

                    <ListItemText
                      className={classNames('pl-0', classes.toolbarText)}
                      children={translate('switch-ogranization')}
                    />
                    <ListItemIcon>
                      <Icon>chevron_right</Icon>
                    </ListItemIcon>
                  </MenuItem>
                )}
                <MenuItem
                  id="switch-location"
                  name="switch-location"
                  className={classes.listItem}
                  onClick={this.locationMenuClick}
                >
                  <ListItemIcon>
                    <Icon>language</Icon>
                  </ListItemIcon>

                  <ListItemText
                    className={classNames('pl-0', classes.toolbarText)}
                    children={`${translate('location')} : ${profile.country ? profile.country : 'not-set'}`}
                  />
                  <ListItemIcon>
                    <Icon>chevron_right</Icon>
                  </ListItemIcon>
                </MenuItem>
                {isLPUser && (
                  <MenuItem id="network" name="network" className={classes.listItem} onClick={this.networkMenuClick}>
                    <ListItemIcon>
                      <Icon>sensors</Icon>
                    </ListItemIcon>

                    <ListItemText
                      className={classNames('pl-0', classes.toolbarText)}
                      children={translate('trainingtube-network')}
                    />
                    <ListItemIcon>
                      <Icon>chevron_right</Icon>
                    </ListItemIcon>
                  </MenuItem>
                )}

                {isUserProfileFeatureEnabled && !originalOrgId && (
                  <MenuItem
                    id="my-profile"
                    name="my-profile"
                    className={classes.listItem}
                    onClick={() => {
                      this.setState({ isUserProfileDialogOpen: true })
                      this.userMenuClose()
                    }}
                  >
                    <ListItemIcon>
                      <Icon>person_outline</Icon>
                    </ListItemIcon>
                    <ListItemText
                      className={classNames('pl-0', classes.toolbarText)}
                      primary={translate('my-profile')}
                    />
                  </MenuItem>
                )}

                <MenuItem
                  id="logout-link"
                  name="Logout"
                  className={classes.listItem}
                  onClick={() => {
                    logout()
                    this.userMenuClose()
                  }}
                >
                  <ListItemIcon>
                    <Icon>exit_to_app</Icon>
                  </ListItemIcon>
                  <ListItemText className={classNames('pl-0', classes.toolbarText)} primary={translate('logout')} />
                </MenuItem>
              </div>
            )}

            {isLocationMenuOpen && (
              <div style={{ minWidth: '305px' }}>
                <MenuItem
                  id="hide-location"
                  name="hide-location"
                  className={classes.listItem}
                  onClick={this.locationMenuClose}
                >
                  <ListItemIcon>
                    <Icon>chevron_left</Icon>
                  </ListItemIcon>

                  <ListItemText
                    className={classNames('pl-0', classes.toolbarText)}
                    children={translate('choose-your-location')}
                  />
                </MenuItem>
                {countries.map(country => (
                  <MenuItem
                    id={`country-${country}`}
                    name={country}
                    className={classes.listItem}
                    key={country}
                    onClick={this.onCountryClick(country)}
                  >
                    <ListItemText className={classNames('pl-0', classes.toolbarText)} children={country} />
                  </MenuItem>
                ))}
              </div>
            )}

            {isNetworkMenuOpen && (
              <div style={{ minWidth: '305px' }}>
                <MenuItem
                  id="hide-network"
                  name="hide-network"
                  className={classes.listItem}
                  onClick={this.networkMenuClose}
                >
                  <ListItemIcon>
                    <Icon>chevron_left</Icon>
                  </ListItemIcon>

                  <ListItemText
                    className={classNames('pl-0', classes.toolbarText)}
                    children={translate('trainingtube-network')}
                  />
                </MenuItem>
                {isLPUser && (
                  <>
                    <MenuItem
                      id="create-link"
                      name="create-link"
                      className={classes.listItem}
                      onClick={() => {
                        this.setState({ createLinkOpen: true })
                        this.userMenuClose()
                      }}
                    >
                      <ListItemText
                        className={classNames('pl-0', classes.toolbarText)}
                        primary={translate('create-link')}
                      />
                    </MenuItem>
                    <MenuItem
                      id="operationalTasks-link"
                      name="operationalTasks-link"
                      className={classes.listItem}
                      onClick={() => {
                        this.userMenuClose()
                      }}
                    >
                      <Link
                        id="operationalTasks"
                        name="operationalTasks"
                        to="/admin/operationalTasks"
                        className={classNames('pl-0', classes.toolbarLink)}
                      >
                        {translate('operational-tasks')}
                      </Link>
                    </MenuItem>
                  </>
                )}
                {this.props.updateOrganization &&
                  isLPUser &&
                  [
                    {
                      id: 'features-services-link',
                      name: translate('features-services'),
                      linkTo: '/finance/features-services',
                      visible: true,
                      active: !originalOrgId,
                    },
                    {
                      id: 'orgs',
                      name: 'Organizations',
                      linkTo: '/orgs',
                      visible: true,
                      active: orgId === 'launchpeople',
                    },
                    {
                      id: 'orgs',
                      name: 'Activity',
                      linkTo: '/orgs/usage',
                      visible: true,
                      active: orgId === 'launchpeople',
                    },
                    {
                      id: 'usercrm',
                      name: 'User CRM',
                      linkTo: '/orgs/crm',
                      visible: true,
                      active: orgId === 'launchpeople',
                    },
                    {
                      id: 'approved-task-ledger-link',
                      name: translate('task-ledger'),
                      linkTo: '/approved-task-ledger',
                      visible: true,
                      active: ['launchpeople', 'trainingtube'].includes(orgId),
                    },
                  ]
                    .filter(item => item.active)
                    .map(item => (
                      <MenuItem
                        id="create-link"
                        name="create-link"
                        key={item.id}
                        className={classes.listItem}
                        onClick={() => {
                          this.setState({ createLinkOpen: true })
                          this.userMenuClose()
                        }}
                      >
                        <Link
                          id={item.id}
                          name={item.name}
                          to={item.linkTo}
                          className={classNames('pl-0', classes.toolbarLink)}
                        >
                          {item.name}
                        </Link>
                      </MenuItem>
                    ))}
              </div>
            )}

            {isLanguageMenuOpen && (
              <div style={{ minWidth: '305px' }}>
                <MenuItem
                  id="hide-language"
                  name="hide-language"
                  className={classes.listItem}
                  onClick={this.langMenuClose}
                >
                  <ListItemIcon>
                    <Icon>chevron_left</Icon>
                  </ListItemIcon>

                  <ListItemText
                    className={classNames('pl-0', classes.toolbarText)}
                    children={translate('choose-your-language')}
                  />
                </MenuItem>
                {/* Selected language is always shown at the top */}
                <MenuItem
                  style={{ background: tubeColor, color: 'white', fontWeight: 500 }}
                  id={`lang-${language}`}
                  name={language}
                  className={classes.listItem}
                  key={language}
                  onClick={this.onLangClick(language)}
                >
                  {/* <ListItemText disableTypography={true} className="pl-0" primary={translate(language)} /> */}
                  <ListItemText
                    className={classNames('pl-0', classes.toolbarTextActive)}
                    children={translate(language)}
                  />

                  {<Icon style={{ color: 'white', marginLeft: 15 }}>check_circle_outline</Icon>}
                </MenuItem>

                {languagesToShow
                  .sort((a, b) => (translate(a) as string).localeCompare(translate(b) as string))
                  .map(lang => (
                    <MenuItem
                      id={`lang-${lang}`}
                      name={lang}
                      className={classes.listItem}
                      key={lang}
                      onClick={this.onLangClick(lang)}
                    >
                      <ListItemText className={classNames('pl-0', classes.toolbarText)} children={translate(lang)} />
                    </MenuItem>
                  ))}

                {!!organizationLanguages.length && (
                  <MenuItem
                    className={classes.listItem}
                    style={{ borderTop: '1px solid #eee' }}
                    onClick={() => this.setState({ showAllLanguages: !this.state.showAllLanguages })}
                  >
                    <Icon style={{ color: tubeColor }}>{!showAllLanguages ? 'expand_more' : 'expand_less'}</Icon>
                    <ListItemText
                      // disableTypography={true}
                      className={classNames('pl-0', classes.toolbarText)}
                      primary={translate(showAllLanguages ? 'see-less' : 'see-all')}
                    />
                  </MenuItem>
                )}
              </div>
            )}

            {isOrgMenuOpen && (
              <>
                <div style={{ minWidth: '305px' }}>
                  <MenuItem
                    id="hide-organization"
                    name="hide-organization"
                    className={classes.listItem}
                    onClick={this.orgMenuClose}
                  >
                    <ListItemIcon>
                      <Icon>chevron_left</Icon>
                    </ListItemIcon>

                    <ListItemText
                      className={classNames('pl-0', classes.toolbarText)}
                      children={translate('organizations')}
                    />
                  </MenuItem>
                  {((externalOrgsUserHasAccessTo && !!externalOrgsUserHasAccessTo.length) || originalOrgId) && (
                    <div>
                      {originalOrgId ? (
                        <MenuItem
                          onClick={async () => {
                            await axios({
                              method: 'POST',
                              url: `${mainApiUrl.command}/updateUserOrgSwitch`,
                              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
                              data: {
                                orgToSwitchTo: '',
                              },
                            })

                            this.userMenuClose()

                            localStorage.removeItem('collaborationOrgId')

                            window.location.href = '/home'
                          }}
                        >
                          <ListItemText className={classNames('pl-0', classes.toolbarText)}>
                            Back to original org
                          </ListItemText>
                        </MenuItem>
                      ) : (
                        <div />
                      )}

                      {externalOrgsUserHasAccessTo
                        .filter(item => item.id !== switchedOrgId)
                        .map(item => {
                          return (
                            <MenuItem
                              key={item.id}
                              onClick={async () => {
                                await await axios({
                                  method: 'POST',
                                  url: `${mainApiUrl.command}/updateUserOrgSwitch`,
                                  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
                                  data: {
                                    orgToSwitchTo: item.id,
                                  },
                                })

                                this.userMenuClose()

                                localStorage.setItem('collaborationOrgId', item.id)

                                window.location.href = '/home'
                              }}
                            >
                              <ListItemIcon>
                                {item.logo ? (
                                  <img width={30} src={item.logo} alt="logo" />
                                ) : (
                                  <Avatar
                                    style={{
                                      backgroundColor: tubeColor as any,
                                      color: 'white',
                                    }}
                                    className={classes.channelImg}
                                  >
                                    {getInitials(item.name)}
                                  </Avatar>
                                )}
                              </ListItemIcon>
                              <ListItemText className={classNames('pl-0', classes.toolbarText)}>
                                {item.name}
                              </ListItemText>
                            </MenuItem>
                          )
                        })}
                    </div>
                  )}
                </div>
              </>
            )}
          </Popover>

          {createLinkOpen && (
            <CreateLinkDialog
              open={createLinkOpen}
              translate={translate}
              tubeColor={tubeColor}
              orgId={orgId}
              onClose={() => this.setState({ createLinkOpen: false })}
            />
          )}

          {isUserProfileDialogOpen && !originalOrgId && (
            <Mutation {...userCommandMutation()}>
              {userCommandReq => (
                <UserProfileDialog
                  translate={translate}
                  tubeColor={tubeColor}
                  saveUserProfile={(data: any) =>
                    userCommandReq(
                      userCommandVariables({
                        type: 'updateUserProfile',
                        payload: data,
                      })
                    )
                  }
                  open={isUserProfileDialogOpen}
                  onClose={() => this.setState({ isUserProfileDialogOpen: false })}
                />
              )}
            </Mutation>
          )}

          {isNewUserConfigurationDialogOpen && !originalOrgId && (
            <Mutation {...userCommandMutation()}>
              {userCommandReq => (
                <UserInitialConfigurationDialog
                  open={isNewUserConfigurationDialogOpen}
                  tubeColor={tubeColor}
                  orgId={orgId}
                  profile={profile}
                  userLanguage={this.props.language}
                  changeLang={this.props.changeLang}
                  userEmail={this.props.email}
                  saveUserProfile={(data: any) =>
                    userCommandReq(
                      userCommandVariables({
                        type: 'updateUserProfile',
                        payload: data,
                      })
                    )
                  }
                  organizationLanguages={organizationLanguages}
                  onClose={() => {
                    this.setState({ isNewUserConfigurationDialogOpen: false })
                  }}
                />
              )}
            </Mutation>
          )}

          <Mutation {...addCreateLeadMutation()}>
            {createLeadReq => (
              <>
                {this.state.isSupportDialogOpen && (
                  <SupportForm
                    open={this.state.isSupportDialogOpen}
                    title={translate('support-form-title') as string}
                    confirmLabel={translate('submit') as string}
                    userEmail={this.props.email}
                    onClose={(values: any) => this.onSupportFormSubmit(values, createLeadReq)}
                  />
                )}
              </>
            )}
          </Mutation>
          <SuccessDialog
            open={this.state.showSuccessForm}
            tubeColor={tubeColor}
            onClose={() => {
              this.setState({ showSuccessForm: false })
            }}
            dialogContent={translate('support-success') as string}
            header={translate('success') as string}
          />
          <ErrorDialog
            open={!!this.state.error}
            message={this.state.error}
            onClose={() => this.setState({ error: null })}
          />
        </div>
        {isViewOnlyMode && (
          <div className="mr-16">
            <Button
              id="login-button"
              className={` ${classes.loginButton}`}
              onClick={() => {
                logout()
              }}
            >
              {translate('login')}
            </Button>
          </div>
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logoutUser,
      changeLang,
      toggleSidebar,
      changeTooltipSettings,
      updateChannelList,
    },
    dispatch
  )
}

function mapStateToProps(state: ReduxState) {
  const { auth, ui, translation, channel, sidebar, searchVideos, notifications } = state

  return {
    user: auth.user,
    email: auth.user.email,
    profile: state.auth.user.profile,
    language: translation.lang,
    tubeColor: auth.tube.color,
    orgId: auth.organization.id,
    sidebarStatus: sidebar.status,
    isViewOnlyMode: ui.isViewOnlyMode,
    originalOrgId: state.auth.user.originalOrgId,
    switchedOrgId: state.auth.user.orgId,
    externalOrgsUserHasAccessTo: auth.user.externalOrgsUserHasAccessTo,
    userEmail: auth.user.data.email,
    channelId: channel.currentChannel,
    isLPUser: isLPUser(auth.user.email),
    searchValue: searchVideos.searchValue,
    translate: getTranslation(translation.lang),
    notifications: notifications.data || [],
    showUserEmail: isLPUser(auth.user.data.email),
    tooltipDisabled: auth.user.settings.tooltipDisabled,
    isConfigurationCompleted: auth.user.settings.isConfigurationCompleted,
    isNotificationsFeatureEnabled: isNotificationsFeatureEnabled(state),
    channelList: channel.channelList,
    isChannelLoaded: channel.isChannelLoaded,
    organizationLanguages: auth.organization.organizationLanguages,
    isUserProfileFeatureEnabled: isUserProfileFeatureEnabled(state),
    isNewChannelLayoutEnabled: isNewChannelLayoutFeatureEnabled(state),
    isEnableRequestDemoFeatureEnabled: isEnableRequestDemoFeatureEnabled(state),
    updateOrganization: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.updateOrganization),
  }
}

export default props => {
  const Comp = compose(
    getTubeData(),
    listChannelsData('readChannel'),
    getUserAnalyticsData(),
    connect(mapStateToProps, mapDispatchToProps)
  )(MainToolbar)

  return (
    <Mutation {...userCommandMutation()}>
      {userCommandReq => {
        return (
          <Comp
            {...props}
            savePreferredLanguage={(language: string) =>
              userCommandReq(
                userCommandVariables({
                  type: 'updatePreferredLanguage',
                  payload: { language },
                })
              )
            }
            saveLocation={(location: string) =>
              userCommandReq(
                userCommandVariables({
                  type: 'updateUserProfile',
                  payload: { country: location },
                })
              )
            }
            createAssistedSearchTask={(taskTitle: string) =>
              userCommandReq(
                userCommandVariables({
                  type: 'createAssistedSearchTask',
                  payload: {
                    taskTitle,
                  },
                })
              )
            }
          />
        )
      }}
    </Mutation>
  )
}
