/**
 * Returns the video duration in display format such as 1K, 1M...
 *
 * @param {number} videoDuration
 * @returns {string|null}
 */
export const getVideoDurationInDisplayFormat = (videoDuration: number): string | null => {
  if (!videoDuration && videoDuration !== 0) return null

  if (videoDuration < 1000) {
    return videoDuration.toString()
  }

  if (videoDuration < 1000000) {
    return `${videoDuration / 1000}K`
  }

  return `${videoDuration / 1000000}M`
}

/**
 * Returns the video duration to show in videos
 *
 * @param {number} totalSeconds
 * @returns {string}
 */
export const getVideoDuration = (totalSeconds): string => {
  const hours = Math.floor(totalSeconds / 3600)
  const ss = totalSeconds % 3600
  const minutes = Math.floor(ss / 60)
  const seconds = `0${Math.floor(ss % 60)}`.substr(-2)

  if (!!hours) {
    return `${hours}:${minutes}:${seconds}`
  }

  if (!!minutes) {
    return `${minutes}:${seconds}`
  }

  return `0:${seconds}`
}
