import * as React from 'react'
import NotificationSystem from 'react-notification-system'

const _NotificationSystem = React.createRef<NotificationSystem.System>()

function NotificationWrapper() {
  return <NotificationSystem ref={_NotificationSystem} />
}

export default NotificationWrapper
export const addNotification = (notification: NotificationSystem.Notification) =>
  _NotificationSystem.current.addNotification(notification)
