import * as React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Mutation, Query } from 'react-apollo'
import { matchRoutes } from 'react-router-config'
import { withStyles } from '@material-ui/core/styles'
import { Icon, Dialog, IconButton, DialogTitle, DialogContent } from '@material-ui/core'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import MobileDetect from 'mobile-detect/mobile-detect'

import history from '../../../history'
import HelpTrackList from './HelpTrackList'
import { compose } from 'src/utils/common'
import { Translate } from 'src/translation'
import { Item } from 'src/gql/queries/getHelpVideos'
import { ReduxState } from 'src/store/reducers/index'
import { VideosWatched } from 'src/gql/getUserAnalytics'
import { getHelpVideosData } from 'src/gql/queries/getHelpVideo'
import { userCommandMutation, userCommandVariables } from '../../../gql/userCommand'

const styles = (theme: any) => ({
  closeButton: {
    position: 'absolute' as 'absolute',
    right: -12,
    top: -12,
    color: theme.palette.grey[500],
    '& > span > span': {
      fontSize: 40,
    },
    '@media(max-width: 1024px)': {
      right: -28,
      top: -28,
      '& > span > span': {
        fontSize: 30,
      },
    },
  },
  helpDialogContent: {
    '@media(max-width: 1024px)': {
      flexDirection: 'column' as 'column',
      '& > div': {
        width: '100%',
        marginBottom: 20,
      },
    },
  },
  helpDialogTitle: {
    '& > h2': {
      position: 'relative' as 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '@media(max-width: 1024px)': {
        flexDirection: 'column' as 'column',
        alignItems: 'start',
      },
    },
  },
})

type Props = {
  open: boolean
  lang?: string
  tubeColor?: string
  translate: Translate
  isLoadingComplete: boolean
  videosWatched: VideosWatched
  onClose: (tooltip: boolean) => void
  routes: {
    path: string
    component: React.Component
  }[]
  onLangClick: any
  pageTags?: {
    [page: string]: {
      tagGroup: string
      tagValue: string
    }[]
  }
  classes?: {
    helpDialogContent: string
    helpDialogTitle: string
    closeButton: string
  }
  saveTooltipPopupPreference?: (tooltip: boolean) => Promise<void>
}

class HelpDialog extends React.PureComponent<Props, { selectedLang: string }> {
  constructor(props) {
    super(props)
    this.state = {
      selectedLang: 'en-us',
    }
  }

  private getTag = () => {
    const { pageTags } = this.props
    const [branch] = matchRoutes(this.props.routes, history.location.pathname)

    return pageTags && pageTags[branch.match.path] && pageTags[branch.match.path].length
      ? pageTags[branch.match.path][0]
      : {
          tagGroup: '',
          tagValue: '',
        }
  }

  private closeMenu = () => {
    this.props.onClose(false)
  }

  public render() {
    const { open, classes, translate, videosWatched } = this.props

    const currentTag = this.getTag()

    if (!currentTag.tagGroup && !currentTag.tagValue) {
      return (
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          PaperProps={{
            style: {
              height: 540,
            },
          }}
          onClose={() => {
            this.closeMenu()
          }}
        >
          <DialogTitle className={classes.helpDialogTitle}>
            {translate('help-dialog-title')}
            <IconButton
              id="help-dialog-close-btn"
              className={classes.closeButton}
              onClick={() => {
                this.closeMenu()
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <p className="p-24">{translate('no-tag-defined')}</p>
          </DialogContent>
        </Dialog>
      )
    }

    const [branch] = matchRoutes(this.props.routes, history.location.pathname)

    const dialogData = (
      <Query {...getHelpVideosData(branch.match.path, null, undefined)}>
        {({ loading, error, data }: any) => {
          if (loading) {
            return <div className={classNames(classes.helpDialogContent, 'w-full flex')}>loading</div>
          }

          const helpVideos = (data && data.helpVideos) || {
            featuredVideo: null,
            videos: [] as Item[],
          }

          const featuredVideoData =
            data &&
            data.helpVideos &&
            data.helpVideos.videos &&
            data.helpVideos.videos[0] &&
            data.helpVideos.videos[0].items
              ? data.helpVideos.videos[0].items.find(item => item.id === 'dQlyH4Z2')
              : undefined

          return (
            <div>
              <DialogTitle className={classes.helpDialogTitle}>
                <span>{featuredVideoData ? featuredVideoData.name : translate('help-dialog-title')}</span>

                <IconButton
                  id="close-tooltip-btn"
                  className={classes.closeButton}
                  onClick={() => {
                    this.closeMenu()
                  }}
                >
                  <Icon>close</Icon>
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {helpVideos.videos && helpVideos.videos.length ? (
                  <div className={classNames(classes.helpDialogContent, 'w-full flex')}>
                    <div className={'w-full'}>
                      <HelpTrackList
                        tracks={helpVideos.videos}
                        videosWatched={videosWatched}
                        lang={this.state.selectedLang}
                        featuredVideoId={featuredVideoData ? featuredVideoData.humanId : ''}
                        onClose={() => {
                          this.closeMenu()
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="p-24">{translate('no-tag-defined')}</p>
                )}
              </DialogContent>
            </div>
          )
        }}
      </Query>
    )

    const md = new MobileDetect(window.navigator.userAgent)
    const isMobile = md.mobile()

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        PaperProps={{
          style: {
            height: 540,
          },
        }}
        open={open || (this.props.isLoadingComplete && !isMobile)}
        onClose={() => {
          this.closeMenu()
        }}
      >
        {dialogData}
      </Dialog>
    )
  }
}

function mapStateToProps({ auth, translation }: ReduxState) {
  return {
    pageTags: auth.organization.pageTags || {},
    lang: translation.lang,
    tubeColor: auth.tube.color,
  }
}

export default withStyles(styles)<Props>(
  connect(
    mapStateToProps,
    null
  )(props => {
    return (
      <Mutation {...userCommandMutation()}>
        {userCommandReq => {
          const Comp = compose(connect(mapStateToProps))(HelpDialog)

          return (
            <Comp
              {...props}
              saveTooltipPopupPreference={(tooltip: boolean) =>
                userCommandReq(
                  userCommandVariables({
                    type: 'updateTooltipPopupPreference',
                    payload: { disabled: tooltip },
                  })
                )
              }
            />
          )
        }}
      </Mutation>
    )
  })
)
