import { Dispatch } from '.'

export const expandTrack = channelId => trackId => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'TRACK_EXPANDED',
      payload: {
        trackId,
        channelId,
      },
    })
  }
}

export const collapseTrack = channelId => trackId => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'TRACK_COLLAPSED',
      payload: {
        trackId,
        channelId,
      },
    })
  }
}
