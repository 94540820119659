import { Dispatch } from 'redux'

export const SET_ORGANIZATION_DATA = '[ORGANZATION] SET DATA'
export const ADD_ORG_CLASSIFICATION = '[ORGANZATION] ADD CLASSIFICATION'
export const REMOVE_ORG_CLASSIFICATION = '[ORGANZATION] REMOVE CLASSIFICATION'
export const ADD_TAG_TO_PAGE = '[ORGANZATION] ADD TAG TO PAGE'
export const REMOVE_TAG_TO_PAGE = '[ORGANZATION] REMOVE TAG TO PAGE'
export const UPDATE_ORG_FEATURE = '[ORGANZATION] UPDATE FEATURE'
export const UPDATE_ORGANIZATION_LANGUAGES = '[ORGANZATION] UPDATE ORGANIZATION_LANGUAGES'
export const DEFINE_WHITELISTED_DOMAINS = '[ORGANZATION] DEFINE WHITELISTED DOMAINS'

export function setOrganizationData(organization) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_ORGANIZATION_DATA,
      payload: organization,
    })
  }
}

export function addOrgClassification(classification) {
  return dispatch => {
    dispatch({
      type: ADD_ORG_CLASSIFICATION,
      payload: classification,
    })
  }
}

export function updateOrgFeature(feature) {
  return dispatch => {
    dispatch({
      type: UPDATE_ORG_FEATURE,
      payload: feature,
    })
  }
}

export function updateOrgLanguages(organizationLanguages) {
  return dispatch => {
    dispatch({
      type: UPDATE_ORGANIZATION_LANGUAGES,
      payload: { organizationLanguages },
    })
  }
}

export function removeOrgClassification(classificationName) {
  return dispatch => {
    dispatch({
      type: REMOVE_ORG_CLASSIFICATION,
      payload: classificationName,
    })
  }
}

export function addPageTag(page, tag) {
  return dispatch => {
    dispatch({
      type: ADD_TAG_TO_PAGE,
      payload: { tag, page },
    })
  }
}

export function removePageTag(page, tag) {
  return dispatch => {
    dispatch({
      type: REMOVE_TAG_TO_PAGE,
      payload: { tag, page },
    })
  }
}

export function defineWhitelistedDomains(domains: string[]) {
  return dispatch => {
    dispatch({
      type: DEFINE_WHITELISTED_DOMAINS,
      payload: { domains },
    })
  }
}
