import { CHANGE_CHANNEL, ChannelAction, UPDATE_CHANNEL_LIST } from '../actions/channel.actions'

const initState = {
  currentChannel: '',
  channelList: [],
  isChannelLoaded: false,
}

export type ChannelState = typeof initState

export default (state: ChannelState = initState, { type, payload }: ChannelAction): ChannelState => {
  switch (type) {
    case CHANGE_CHANNEL:
      return {
        ...state,
        currentChannel: payload.id,
      }
    case UPDATE_CHANNEL_LIST:
      return {
        ...state,
        channelList: payload.channelList,
        isChannelLoaded: true,
      }
    default:
      return state
  }
}
