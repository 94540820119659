import * as React from 'react'
import { Snackbar, IconButton, withStyles, Icon } from '@material-ui/core'
import * as Actions from '../../store/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {
    position: 'absolute',
  },
  close: {},
})

type Props = {
  classes?: any
  hideMessage: () => any
  state: any
  options: {
    anchorOrigin: {
      vertical: number | 'center' | 'bottom' | 'top'
      horizontal: number | 'left' | 'right' | 'center'
    }
    autoHideDuration: number
    message: React.ReactElement<any>
  }
}

const Message = (props: Props) => (
  <Snackbar
    {...props.options}
    open={props.state}
    onClose={props.hideMessage}
    classes={{
      root: props.classes.root,
    }}
    action={[
      <IconButton
        id="close-message-btn"
        key="close"
        aria-label="Close"
        color="inherit"
        className={props.classes.close}
        onClick={props.hideMessage}
      >
        <Icon>close</Icon>
      </IconButton>,
    ]}
  />
)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideMessage: Actions.hideMessage,
    },
    dispatch
  )
}

function mapStateToProps({ fuse }) {
  return {
    state: fuse.message.state,
    options: fuse.message.options,
  }
}

// @ts-ignore
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Message))
