import * as React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import { getTranslation } from '../../translation'

// @ts-ignore
@connect((state, ownProps) => {
  return {
    ...ownProps,
    translate: getTranslation(state.translation.lang),
  }
}, null)
export class ErrorDialog extends React.PureComponent {
  props: {
    open: boolean
    onClose: any
    translate?: any
    message: string | React.Component
  }

  handleOk = () => {
    this.props.onClose()
  }

  render() {
    const { message, translate, ...other } = this.props

    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth="md"
        aria-labelledby="error-dialog"
        {...other}
      >
        <DialogTitle id="error-dialog">{translate('error')}</DialogTitle>
        <DialogContent>
          <p>{message}</p>
        </DialogContent>
        <DialogActions>
          <Button name="Ok" id="error-dialog-okay-btn" onClick={this.handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
