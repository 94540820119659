import * as Actions from '../actions'
import * as OrgActions from '../../../auth/store/actions/organization.actions'
import * as UserActions from '../../../auth/store/actions/user.actions'

const initialState = {
  lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-us',
}

type State = {
  lang: string
}

const translation = (state: State = initialState, action: Actions.TranslationAction) => {
  switch (action.type) {
    case Actions.CHANGE_LANG: {
      localStorage.setItem('lang', action.payload.lang)
      return {
        ...state,
        lang: action.payload.lang,
      }
    }

    case OrgActions.SET_ORGANIZATION_DATA: {
      if (!action.payload.masterLanguage) return state

      localStorage.setItem('lang', action.payload.masterLanguage)
      return {
        ...state,
        lang: action.payload.masterLanguage,
      }
    }

    case UserActions.SET_USER_DATA: {
      if (!action.payload.settings || !action.payload.settings.preferredLanguage) return state

      localStorage.setItem('lang', action.payload.settings.preferredLanguage)
      return {
        ...state,
        lang: action.payload.settings.preferredLanguage,
      }
    }

    default: {
      return state
    }
  }
}

export default translation
