import gql from 'graphql-tag'
import uid from 'uid'
import { QueryResult } from 'react-apollo'

export type VideosWatched = {
  [videoId: string]: {
    count: number
    lastTimeWatched: number
  }
}

export type UserAnalytics = {
  id: string
  videosWatched: VideosWatched
  pagesVisited: string[]
}

export type GetUserAnalytics = QueryResult<{
  userAnalytics: UserAnalytics
}>

//  without $id: String! apollo throws an error
export const getUserAnalytics = () => {
  const isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1

  return {
    query: gql`
      query($id: String!) {
        userAnalytics(id: $id) @rest(type: "UserAnalytics", path: "/user-analytics${
          isIE ? `?randID=${uid()}` : ''
        }", endpoint: "query") {
          id
          videosWatched
          pagesVisited
        }
      }
    `,
  }
}
