import * as Actions from '../actions/organization.actions'

const initialState = {
  id: '',
  name: '',
  masterLanguage: '',
  featureFlags: [],
  infClassifications: {},
  pageTags: {},

  organizationLanguages: [],
  whitelistedDomains: [],
}

const organization = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_ORGANIZATION_DATA: {
      return {
        ...initialState,
        ...action.payload,
      }
    }

    case Actions.UPDATE_ORG_FEATURE: {
      return {
        ...state,
        featureFlags: state.featureFlags.map(feature =>
          feature.featureId !== action.payload.featureId ? feature : action.payload
        ),
      }
    }

    case Actions.UPDATE_ORGANIZATION_LANGUAGES: {
      return {
        ...state,
        organizationLanguages: action.payload.organizationLanguages,
      }
    }

    case Actions.ADD_ORG_CLASSIFICATION: {
      return {
        ...state,
        infClassifications: {
          ...state.infClassifications,
          [action.payload.name]: action.payload.description,
        },
      }
    }

    case Actions.REMOVE_ORG_CLASSIFICATION: {
      const newState = { ...state }
      delete state.infClassifications[action.payload]
      return newState
    }

    case Actions.ADD_TAG_TO_PAGE: {
      return {
        ...state,
        pageTags: {
          ...state.pageTags,
          [action.payload.page]: state.pageTags[action.payload.page]
            ? [...state.pageTags[action.payload.page], action.payload.tag]
            : [action.payload.tag],
        },
      }
    }

    case Actions.REMOVE_TAG_TO_PAGE: {
      return {
        ...state,
        pageTags: {
          ...state.pageTags,
          [action.payload.page]: state.pageTags[action.payload.page].filter(
            t => t.tagGroup !== action.payload.tag.tagGroup && t.tagValue !== action.payload.tag.tagValue
          ),
        },
      }
    }

    case Actions.DEFINE_WHITELISTED_DOMAINS: {
      return {
        ...state,
        whitelistedDomains: action.payload.domains,
      }
    }

    default: {
      return state
    }
  }
}

export default organization
