export type UIActions = {
  type: string
  payload?: {
    data: any
  }
}

type Dispatch = (action: UIActions) => void

export const setIsViewOnlyMode = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'SET_VIEW_ONLY_MODE',
    })
  }
}
