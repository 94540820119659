import * as React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { ReduxState } from 'src/store/reducers'

type Props = {
  tubeName?: string
}

const CustomHelmet = ({ tubeName }: Props) => {
  // This is used to add the name into the manifest file while installing into android devices.
  const manifest = {
    short_name: `${tubeName} TrainingTube`,
    name: `${tubeName} TrainingTube`,
    icons: [
      {
        src: 'logo.png',
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/png',
      },
    ],
    start_url: './index.html',
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff',
  }

  const stringManifest = JSON.stringify(manifest)
  const blob = new Blob([stringManifest], { type: 'application/json' })
  const manifestURL = URL.createObjectURL(blob)
  document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL)

  return (
    <Helmet>
      <title>{tubeName} TrainingTube</title>
    </Helmet>
  )
}

const mapStateToProps = (state: ReduxState, ownProps) => ({
  tubeName: state.auth.organization.name,
})

export default connect(mapStateToProps)(CustomHelmet)
