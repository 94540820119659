import { keys } from 'lodash'
import * as React from 'react'
import moment from 'moment'
import * as qs from 'query-string'
import { connect } from 'react-redux'
import { Query, Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { VideoPlayerWithLoader, VideoPlayer } from 'src/components/reusable/VideoPlayer'

import styles from './VideoEmbedCodes.styles'
import { getTranslation } from 'src/translation'
import { getVideoDuration } from 'src/utils/cards'
import Animate from '../../../theme/components/Animate'
import { replaceTranslationSkipCharacters, replaceBreaks } from 'src/utils/common'
import { getVideoEmbed, GetVideoEmbed } from 'src/gql/getVideoEmbed'
import { addAnonymousAnalyticsMutation, addAnonymousAnalyticsVariables } from 'src/gql/addAnonymousAnalytics'

// @ts-ignore
@withStyles(styles)
@connect((state, ownProps) => {
  return {
    ...ownProps,
    orgId: state.auth.organization.id || state.auth.user.data.organization.id, // todo: fix state
    translate: getTranslation(state.translation.lang),
    hasActiveSubscription: state.auth.organization.hasActiveSubscription,
    logo: state.auth.organization.logo,
    tubeColor: state.auth.tube.color,
  }
})
export class Embed extends React.Component<any, any> {
  public componentDidMount() {
    // if (isDevStage) return
    // const scriptEl = document.createElement('script')
    // const noScriptEl = document.createElement('noscript')
    // const imgEl = document.createElement('img')
    // scriptEl.innerHTML = `
    //   !function(f,b,e,v,n,t,s)
    //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //   n.queue=[];t=b.createElement(e);t.async=!0;
    //   t.src=v;s=b.getElementsByTagName(e)[0];
    //   s.parentNode.insertBefore(t,s)}(window, document,'script',
    //   'https://connect.facebook.net/en_US/fbevents.js');
    //   fbq('init', '432949744083181');
    //   fbq('track', 'PageView');
    // `
    // imgEl.src = 'https://www.facebook.com/tr?id=432949744083181&ev=PageView&noscript=1'
    // imgEl.height = 1
    // imgEl.width = 1
    // imgEl.hidden = true
    // noScriptEl.appendChild(imgEl)
    // document.body.appendChild(scriptEl)
    // document.body.appendChild(noScriptEl)
  }

  render() {
    const { classes, hasActiveSubscription, logo } = this.props

    return (
      <Query {...getVideoEmbed(this.props.match.params.key, this.props.orgId)}>
        {({ loading, error, data }: GetVideoEmbed) => {
          if (loading) {
            return <p>{this.props.translate('loading')}</p>
          }
          if (error) {
            // @ts-ignore
            const message = error.networkError.result ? error.networkError.result.message : error.message

            return (
              <div className="flex flex-col flex-1 items-center justify-center p-16">
                <div className="max-w-512 text-center">
                  <Animate delay={500}>
                    <Typography variant="headline" color="textSecondary" className="mb-16 capitalize">
                      {message}
                    </Typography>
                  </Animate>

                  <Animate delay={600}>
                    <Typography variant="subheading" color="textSecondary" className="mb-48">
                      {this.props.translate('video-not-found')}
                    </Typography>
                  </Animate>
                </div>
              </div>
            )
          }

          const { abstract, createdAt, description, name, duration, type, renderingData } = data.getFromEmbedRes as any

          const subtitles = keys(renderingData || {}).reduce((r, lang) => {
            if (!renderingData[lang].subtitles) return r

            return [
              ...r,
              {
                language: lang,
                chapters: renderingData[lang].subtitles.map(data => ({
                  ...data,
                  manuscript: replaceBreaks(replaceTranslationSkipCharacters(data.manuscript)),
                })),
              },
            ]
          }, [])

          const videoDuration = duration ? getVideoDuration(duration / 1000) : null

          const createdAtDate = moment(new Date(createdAt)).format('MMMM Do, YYYY')

          const isIframe = this.props.match.path === '/sharing/embed/:key'

          const parsedQuerySting = qs.parse(location.search)
          const subTitleLang = parsedQuerySting && parsedQuerySting.subTitleLang

          return data.getFromEmbedRes.publicEmbed ? (
            <Mutation {...addAnonymousAnalyticsMutation(this.props.orgId)}>
              {addAnonymousAnalyticsReq => {
                if (loading) {
                  return <p>{this.props.translate('loading')}</p>
                }
                if (error) {
                  return <p>{error.message}</p>
                }

                return (
                  <>
                    {isIframe ? (
                      <div>
                        <VideoPlayer
                          source={data.getFromEmbedRes.url}
                          videoDuration={videoDuration}
                          ribbonType={type}
                          tubeColor={'rgb(0,0,0,0.8)'}
                          showTTLogo={isIframe}
                          isEmbedVideo={true}
                          thumbnail={data.getFromEmbedRes.thumbnail}
                          subtitlesLang={subTitleLang || data.getFromEmbedRes.language}
                          onPlaybackEvent={(event, time) => {
                            addAnonymousAnalyticsReq(
                              addAnonymousAnalyticsVariables({
                                type: event,
                                payload:
                                  event === 'videoTimeWatched'
                                    ? {
                                        videoId: data.getFromEmbedRes.videoId,
                                        time,
                                        version: data.getFromEmbedRes.lastVersion,
                                        lang: data.getFromEmbedRes.language,
                                      }
                                    : {
                                        videoId: data.getFromEmbedRes.videoId,
                                        version: data.getFromEmbedRes.lastVersion,
                                        lang: data.getFromEmbedRes.language,
                                      },
                              })
                            )
                          }}
                          voiceOver={subtitles}
                        />
                      </div>
                    ) : (
                      <div className={classes.bodyWrapper}>
                        <div className={classes.headerWrapper}>
                          <img
                            onClick={() =>
                              !hasActiveSubscription && (window.location.href = 'https://www.launchpeople.com/')
                            }
                            className={classes.logoImg}
                            style={hasActiveSubscription ? { cursor: 'default' } : {}}
                            src={hasActiveSubscription ? logo : '/assets/images/logos/training-tube.png'}
                          />
                          {!hasActiveSubscription ? (
                            <Button
                              style={{ backgroundColor: '#A6419A' }}
                              onClick={() => {
                                window.location.href = 'https://www.trainingtube.com/booking-a-demo'
                              }}
                              className={classes.trainingTubeRedirect}
                            >
                              {this.props.translate('get-training-tube')}
                            </Button>
                          ) : (
                            <Button
                              style={{ backgroundColor: '#A6419A' }}
                              onClick={() => {
                                window.location.href = `https://www.trainingtube.com/login?redirectTo=/&commingFrom=${this.props.orgId}`
                              }}
                              className={classes.trainingTubeRedirect}
                            >
                              {this.props.translate('login')}
                            </Button>
                          )}
                        </div>

                        <div className={classes.cardWrapper}>
                          <div className={classes.innerCard}>
                            <div className={classes.videoWrapper}>
                              <VideoPlayer
                                isEmbedVideo={true}
                                subtitlesLang={subTitleLang || data.getFromEmbedRes.language}
                                source={data.getFromEmbedRes.url}
                                videoDuration={videoDuration}
                                ribbonType={type}
                                tubeColor={'rgb(0,0,0,0.8)'}
                                thumbnail={data.getFromEmbedRes.thumbnail}
                                onPlaybackEvent={(event, time) => {
                                  addAnonymousAnalyticsReq(
                                    addAnonymousAnalyticsVariables({
                                      type: event,
                                      payload:
                                        event === 'videoTimeWatched'
                                          ? {
                                              videoId: data.getFromEmbedRes.videoId,
                                              time,
                                              version: data.getFromEmbedRes.lastVersion,
                                              lang: data.getFromEmbedRes.language,
                                            }
                                          : {
                                              videoId: data.getFromEmbedRes.videoId,
                                              version: data.getFromEmbedRes.lastVersion,
                                              lang: data.getFromEmbedRes.language,
                                            },
                                    })
                                  )
                                }}
                                voiceOver={subtitles}
                              />
                            </div>
                            <div className={classes.videoName}>{replaceTranslationSkipCharacters(name)}</div>
                            <div className={classes.videoAbstract}>{replaceTranslationSkipCharacters(abstract)}</div>

                            <div className={classes.imageContainer}>
                              {/* TODO: Enable this when image is to be added here */}
                              {/* <Query {...getChannelQuery(channelId)}>
                                {({ loading, error, data }: GetChannel) => {
                                  return (
                                    <>
                                      {data && !!Object.keys(data).length ? (
                                        <Tooltip classes={{ tooltip: classes.tooltip }} title={channelName}>
                                          <div className={classes.channelImgLink}>
                                            {loading ? (
                                              <Avatar className={classes.channelPreloader} />
                                            ) : (
                                              <>
                                                {data.channel && data.channel.icon ? (
                                                  <img className={classes.channelImg} src={data.channel && data.channel.icon ? data.channel.icon : ''} />
                                                ) : (
                                                  <Avatar
                                                    style={{
                                                      backgroundColor: tubeColor as any,
                                                      color: 'white',
                                                    }}
                                                    className={classes.channelImg}
                                                    src={data.channel && data.channel.icon ? data.channel.icon : ''}
                                                  >
                                                    {initials}
                                                  </Avatar>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </Tooltip>
                                      ) : null}
                                    </>
                                  )
                                }}
                              </Query> */}
                              <div className={classes.videoCreatedDate}>
                                <div>{createdAtDate}</div>
                              </div>
                            </div>

                            {description !== '<p><br></p>' && (
                              <>
                                <div className={classes.descriptionTitle}>{this.props.translate('description')}</div>
                                <div
                                  className={classes.descriptionBody}
                                  dangerouslySetInnerHTML={{
                                    __html: replaceTranslationSkipCharacters(description),
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              }}
            </Mutation>
          ) : (
            <VideoPlayerWithLoader
              isEmbedVideo={true}
              subtitlesLang={subTitleLang || data.getFromEmbedRes.language}
              lang={data.getFromEmbedRes.language}
              masterLanguage={data.getFromEmbedRes.masterLanguage}
              videoId={data.getFromEmbedRes.videoId}
              lastVersion={data.getFromEmbedRes.lastVersion}
              source={data.getFromEmbedRes.url}
              thumbnail={data.getFromEmbedRes.thumbnail}
              subtitles={subtitles}
            />
          )

          // return data.getFromEmbedRes.publicEmbed ? (
          //   <VideoPlayer source={data.getFromEmbedRes.url} thumbnail={data.getFromEmbedRes.thumnail} onPlaybackEvent={(event, time) => {}} />
          // ) : (
          //   <VideoPlayerWithLoader
          //     lang={data.getFromEmbedRes.language}
          //     masterLanguage={data.getFromEmbedRes.masterLanguage}
          //     videoId={data.getFromEmbedRes.videoId}
          //     lastVersion={data.getFromEmbedRes.lastVersion}
          //     source={data.getFromEmbedRes.url}
          //     thumbnail={data.getFromEmbedRes.thumnail}
          //   />
          // )
        }}
      </Query>
    )
  }
}
