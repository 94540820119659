// Used to calculate the height of the cards based on their width to maintain 16*9
export const CARD_ASPECT_RATIO_MULTIPLIER = 9 / 16

export const SIDEBAR_WIDTH = 280

export const HOME_PAGE_PADDING = 15

export const CARD_SPACING = 8

export const CHANNEL_HOME_UNUSED_SPACE = 400

export const GOOGLE_CDN_URL = 'https://s2.googleusercontent.com/s2/favicons'

export const langToVideoLinkPauseSoundMap = {
  'en-us': 'English.mp3',
  da: 'Danish.mp3',
  sv: 'Swedish.mp3',
  ar: 'Arabic.mp3',
  zh: 'Chinese.mp3',
  nl: 'Dutch.mp3',
  cy: 'Welsh.mp3',
  is: 'Icelandic.mp3',
  'fr-fr': 'French.mp3',
  de: 'German.mp3',
  it: 'Italy.mp3',
  hi: 'Hindi.mp3',
  ja: 'Japanese.mp3',
  ko: 'Korean.mp3',
  no: 'Norwegian.mp3',
  pl: 'Polish.mp3',
  'pt-pt': 'Portuguese.mp3',
  ro: 'Romanian.mp3',
  ru: 'Russian.mp3',
  tr: 'Turkish.mp3',
  'en-gb': 'English-en-gb.mp3',
  'en-au': 'English-en-au.mp3',
  'en-in': 'English-en-in.mp3',
  'en-gb-wls': 'English-gb-wls.mp3',
  'fr-ca': 'French-ca.mp3',
  'es-es': 'Spanish-es-es.mp3',
  'es-mx': 'Spanish-es-mx.mp3',
}

export const s3URLPrefix = 'https://static-polly-audio.s3.eu-west-1.amazonaws.com/video-links-pause/'
