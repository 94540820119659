import * as React from 'react'
import classNames from 'classnames'

interface SpinnerProps {
  className?: string
  variant?: 'tube' | 'primary'
  tubeColor?: string
}

const Spinner = (props: SpinnerProps) => {
  const { variant, tubeColor } = props
  const spinnerClass = classNames(props.className || '', {
    'inline-spinner': true,
  })

  if (variant === 'tube') {
    return (
      <span className={spinnerClass}>
        <span
          className="bounce1"
          style={{
            backgroundColor: tubeColor || 'black',
          }}
        />
        <span
          className="bounce2"
          style={{
            backgroundColor: tubeColor || 'black',
          }}
        />
        <span
          className="bounce3"
          style={{
            backgroundColor: tubeColor || 'black',
          }}
        />
      </span>
    )
  }

  return (
    <span className={spinnerClass}>
      <span className="bounce1" />
      <span className="bounce2" />
      <span className="bounce3" />
    </span>
  )
}

export default Spinner
