const initialState = {
  status: false,
  expandSubscriptions: false,
  areTracksExpanded: false,
  scrollPosition: 0,
  isViewOnlyMode: false,
}

const sidebarReducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        status: payload.status,
      }

    case 'TOGGLE_SUBSCRIPTION_MENU':
      return {
        ...state,
        expandSubscriptions: payload.status,
      }

    case 'TOGGLE_TRACKS_EXPAND_MENU':
      return {
        ...state,
        areTracksExpanded: payload.status,
      }

    case 'UPDATE_LAST_SCROLL_POSITION':
      return {
        ...state,
        scrollPosition: payload.status,
      }

    case 'UPDATE_SIDEBAR_OPEN':
      return {
        ...state,
        isSideBarOpen: payload.status,
      }

    case '[USER] LOGGED OUT':
      return {
        ...state,
        status: false,
      }

    default:
      return state
  }
}

export default sidebarReducer
