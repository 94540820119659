// import 'babel-polyfill'
import 'velocity-animate'
import { create } from 'jss'
import * as React from 'react'
import { includes } from 'lodash'
import jssExtend from 'jss-extend'
import WebFont from 'webfontloader'
import 'velocity-animate/velocity.ui'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloLink } from 'apollo-link'
import * as Sentry from '@sentry/browser'
import { Router } from 'react-router-dom'
import { RestLink } from 'apollo-link-rest'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import JssProvider from 'react-jss/lib/JssProvider'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'

import store from './store'
import history from './history'
import Theme from './theme/theme'
import Layout from './theme/layout'
import client from './apolloConfig'
import { mainApi, sentryDsn } from './config'
import RootLogin from './components/routes/root-login'
import NotificationWrapper from './NotificationWrapper'
import { routes } from './fuse-configs/fuseRoutesConfig'
import { checkIfDevelopmentStage } from 'src/utils/common'
import { GlobalDialogs } from './components/GlobalDialogs'
import Authorization from './theme/components/Authorization'
import MainToolbar from './components/layouts/Main/MainToolbar'
import SupportButton from 'src/components/reusable/SupportButton'
import WebsocketWrapper from 'src/theme/components/WebSocketWrapper'
import { setUserData, setUserProfile } from './auth/store/actions/user.actions'
import { setOrganizationData } from './auth/store/actions/organization.actions'
import { setTubeData, setBrandingColor } from './auth/store/actions/tube.actions'
import { OpenAIConnectionResponseWrapper } from './openAiConnectionResponse'
import {
  getTube,
  getLanguageData,
  getEmbedData,
  setOrginalOrgId,
  getOrganization,
  getNotifications,
  initializeCognito,
  getUserPermissions,
  getOrganizationDomainFromOrgId,
} from './auth/cognito/organizationApi'
import { setLanguageData } from './auth/store/actions/lang.actions'
import { setIsViewOnlyMode } from 'src/store/actions/ui.actions'
import PublicEmbedPage from 'src/components/routes/embed/PublicEmbedPage'
import { InMemoryCache } from 'apollo-cache-inmemory'
import './setup'

import 'react-table/react-table.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './styles/index.css'
import RecorderDialog from './components/reusable/Recorder/RecorderDialog'
import UploadDialog from './components/reusable/Recorder/UploadDialog'

WebFont.load({
  google: {
    families: ['Roboto:400,400i,500&display=swap'],
  },
})

const isDevStage = checkIfDevelopmentStage()

const mainApiUrl = mainApi(isDevStage)
let userEmail

if (!isDevStage) {
  Sentry.init({ dsn: sentryDsn })
}

if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', 'en-us')
}
const data = localStorage.getItem('isViewOnlyMode')

if (!!data) {
  setIsViewOnlyMode()(store.dispatch)
}

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
})

// @ts-ignore
jss.options.insertionPoint = document.getElementById('jss-insertion-point')
const generateClassName = createGenerateClassName()

const renderPublicEmbedCodePage = (orgId: string, hasActiveSubscription: boolean, logo: string) => {
  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      new RestLink({
        endpoints: {
          query: mainApiUrl.query,
          command: mainApiUrl.command,
          users: mainApiUrl.user,
        },

        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ]),
    cache: new InMemoryCache(),
  })

  ReactDOM.render(
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <Router history={history}>
            <Theme>
              <PublicEmbedPage orgId={orgId} hasActiveSubscription={hasActiveSubscription} logo={logo} />
            </Theme>
          </Router>
        </ApolloProvider>
      </Provider>
    </JssProvider>,
    document.getElementById('root')
  )
}

let isOrgPresent = true
const start = async () => {
  const matchOrgFromUrl = window.location.href.match(/([a-zA-Z0-9-_]*)\.trainingtube\.com/)
  const matchOrgFromUrlDev = window.location.href.match(/([a-zA-Z0-9-_]*)\.dev\.trainingtube\.com/)

  const isLoginPage = includes(window.location.href, 'login-lp')
  const isEmbedCodePage = includes(window.location.href, '/sharing/')

  const lastOrgId = localStorage.getItem('lastOrgId')
  let orgId = null

  if (isDevStage) {
    if (matchOrgFromUrlDev) {
      orgId = matchOrgFromUrlDev[1]
    } else {
      // orgId = localStorage.getItem('forceOrgId') || 'uivvn-net-1'
      orgId = localStorage.getItem('forceOrgId') || 'trainingtube'
    }
  } else if (matchOrgFromUrl) {
    orgId = matchOrgFromUrl[1]

    if (
      lastOrgId &&
      orgId !== lastOrgId &&
      window.location.pathname !== '/login-lp' &&
      window.location.pathname !== '/lp'
    ) {
      return (window.location.href =
        'https://www.trainingtube.com/login?redirectTo=' + window.location.pathname + '&commingFrom=' + lastOrgId)
    }
  }

  // Skipping all the authentication part for embed codes page because embed code pages are public pages and dont need the authenitcation.

  if (isEmbedCodePage) {
    let isPrivateEmbedPage = false

    let org
    try {
      org = await getOrganization(
        orgId,
        mainApiUrl.query,
        mainApiUrl.command,
        mainApiUrl.user,
        !isLoginPage && !isEmbedCodePage
      )

      setOrginalOrgId(org.id)

      const embedData = await getEmbedData(
        orgId,

        window.location.href
          .split('?')[0]
          .split('/')
          .pop()
      )

      if (!embedData.publicEmbed) {
        isPrivateEmbedPage = true
      }
    } catch (e) {}

    if (!isPrivateEmbedPage) {
      renderPublicEmbedCodePage(org.id, org.hasActiveSubscription, org.logo)

      return
    }
  }

  // DO not show support icon for public sharing page
  // This is the zendesk icon. Removed for now
  /*   if (!history.location.pathname.startsWith('/sharing/embed')) {
    var s = document.createElement('script')
    // zendesk icon
    s.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=5ccfa56d-3a6d-45c7-8c54-10995a589149')
    s.setAttribute('id', 'ze-snippet')
    document.body.appendChild(s)
  } */

  const isEmbedPage =
    history.location.pathname.startsWith('/sharing/embed') ||
    history.location.pathname.startsWith('/sharing') ||
    history.location.pathname.startsWith('/embed')

  if (!orgId) {
    ReactDOM.render(
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <Router history={history}>
            <Theme>
              <RootLogin apiUrl={mainApiUrl.query} />
            </Theme>
          </Router>
        </Provider>
      </JssProvider>,
      document.getElementById('root')
    )

    return
  }
  let org
  let userOrg
  let computedOrgData

  try {
    userOrg = await getOrganization(orgId, mainApiUrl.query, mainApiUrl.command, mainApiUrl.user, !isLoginPage)

    const collabOrgId = localStorage.getItem('collaborationOrgId')
    let collaborationOrgDomain

    if (collabOrgId) {
      collaborationOrgDomain = await getOrganizationDomainFromOrgId(collabOrgId, mainApiUrl.query)
    }

    // TODO: Note this is not a orgId but a org domain, its just named a orgId, if you check the getOrganization function it expects a domain
    // Update this to orgDomain in a cleanup PR later on.
    orgId = collaborationOrgDomain || orgId

    org = await getOrganization(orgId, mainApiUrl.query, mainApiUrl.command, mainApiUrl.user, !isLoginPage)

    computedOrgData = {
      ...org,
      ssoUrl: userOrg.ssoUrl,
      userPoolClientId: userOrg.userPoolClientId,
      userPoolId: userOrg.userPoolId,
      cognitoIdentifier: userOrg.cognitoIdentifier,
      samlResponseUrl: userOrg.samlResponseUrl,
    }

    setOrginalOrgId(org.id)

    setBrandingColor(org.color)(store.dispatch)
    if (!collabOrgId) {
      localStorage.setItem('lastOrgId', orgId)
    }
    setOrganizationData({
      ...org,
      ssoUrl: userOrg.ssoUrl,
      userPoolClientId: userOrg.userPoolClientId,
      userPoolId: userOrg.userPoolId,
      cognitoIdentifier: userOrg.cognitoIdentifier,
      samlResponseUrl: userOrg.samlResponseUrl,
      domain: orgId,
      featureFlags: [],
    })(store.dispatch)

    const cognitoUser = await initializeCognito(userOrg)

    getLanguageData().then(res => {
      // en-us is used from the local
      if (res.data.lang !== 'en-us') {
        setLanguageData(res.data.lang, res.data.data)(store.dispatch)
      }
    })
    const [tube, userPermissions] = await Promise.all([getTube(), getUserPermissions()])
    setTubeData(tube)(store.dispatch)

    // If somehow the local storage is cleared for collaborgId. Add it and reload the page
    if (!!userPermissions.originalOrgId && userPermissions.orgId !== collabOrgId) {
      localStorage.setItem('collaborationOrgId', userPermissions.orgId)
      window.location.reload()
    }

    // Note: originalOrgId is returned from the API only when in colloboration mode. It means the source org of the user.
    // If the local storage still collabOrgId but the backend doesnot have it remove it from local storage and reload the page
    if (!userPermissions.originalOrgId && !!collabOrgId) {
      localStorage.removeItem('collaborationOrgId')
      window.location.reload()
    }

    setOrganizationData({
      ...computedOrgData,

      featureFlags: userPermissions.featureFlags,
    })(store.dispatch)

    setUserData({
      role: userPermissions.permissions.updateOrganization ? 'admin' : 'member',
      data: {
        organization: computedOrgData,
        displayName: cognitoUser.email,
        photoUrl: 'assets/images/avatars/profile.jpg',
        email: cognitoUser.email,
      },
      ...userPermissions,
      settings: {
        ...userPermissions.settings,
        // Uncomment this line to test for the initial user configuration setup dialog
        // isConfigurationCompleted: false,
      },
      externalOrgsUserHasAccessTo: userPermissions.externalOrgsUserHasAccessTo,
    })(store.dispatch)

    setUserProfile(userPermissions.userData)(store.dispatch)

    userEmail = cognitoUser.email || userPermissions.email
    const notificationsFeature = userPermissions.featureFlags.find(ff => ff.featureId === 'notifications')

    const isNotificationsFeatureEnabled = notificationsFeature.configuration === 'tube'

    if (isNotificationsFeatureEnabled) {
      //  We dont want to await this
      getNotifications({ isFirstFetch: true })
    }
  } catch (err) {
    if (err === 'no organization found' || err.message === 'Failed to fetch') {
      // window.location.href =
      // 'https://www.trainingtube.com/login?redirectTo='
      isOrgPresent = false
    }
    setUserData({
      role: 'guest',
      data: {
        organization: computedOrgData,
        displayName: '',
        photoUrl: '',
        email: '',
      },
    })(store.dispatch)
  } finally {
    ReactDOM.render(
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <ApolloProvider client={client(orgId, computedOrgData ? computedOrgData.ssoUrl : null)}>
            <NotificationWrapper />
            <Router history={history}>
              <OpenAIConnectionResponseWrapper>
                <Authorization isOrgPresent={isOrgPresent} routes={routes}>
                  <WebsocketWrapper email={userEmail} orgId={org.id} />
                  <Theme>
                    {!isEmbedPage && <SupportButton />}
                    <Layout
                      isDevStage={isDevStage}
                      routes={routes}
                      toolbar={
                        !mainApiUrl.maintenance && (
                          <MainToolbar routes={routes} history={history} isDevStage={isDevStage} />
                        )
                      }
                    />
                  </Theme>
                  <RecorderDialog />
                  <UploadDialog />
                  <GlobalDialogs />
                </Authorization>
              </OpenAIConnectionResponseWrapper>
            </Router>
          </ApolloProvider>
        </Provider>
      </JssProvider>,
      document.getElementById('root')
    )
  }
}

start().catch((err: Error) => console.error(err))
