import gql from 'graphql-tag'

type AddAnonymousAnalyticsParams = {
  type: string
  payload: {
    videoId?: string
    channelId?: string
    trackId?: string
    time?: number
    url?: string
    version?: number
    lang?: string
  }
}

export const addAnonymousAnalyticsMutation = (orgId: string) => ({
  mutation: gql`
    mutation addUserAnalytics($input: REST!) {
      addedUserAnalytics(input: $input) @rest(type: "AnonymousAnalytics", path: "/anonymous-analytics?orgId=${orgId}", method: "POST", endpoint: "command") {
        id
      }
    }
  `,
})

export const addAnonymousAnalyticsVariables = (input: AddAnonymousAnalyticsParams) => ({
  variables: {
    input,
  },
})
