import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseCircleFilled from '@material-ui/icons/Pause'

import './style.css'
import { ReduxState } from 'src/store/reducers'
import store from 'src/store'
import { updateUserAutoPlaySettingData } from 'src/auth/store/actions/user.actions'

import { mainApi } from 'src/config'
import { checkIfDevelopmentStage } from 'src/utils/common'
import { getTranslation, Translate } from 'src/translation'

interface VideoSwitchProp {
  autoPlay?: boolean
  translate?: Translate
}

const isDevStage = checkIfDevelopmentStage()
const mainApiUrl = mainApi(isDevStage)

const VideoSwitch = (props: VideoSwitchProp) => {
  const { autoPlay, translate } = props
  const handleAutoPlay = async () => {
    const res = await axios({
      method: 'POST',
      url: `${mainApiUrl.command}/userCommand`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      data: {
        type: 'updateAutoPlaySetting',
        payload: {
          autoPlay: !autoPlay,
        },
      },
    })
    if (res.status === 200) {
      await updateUserAutoPlaySettingData({
        isAutoPlay: !autoPlay,
      })(store.dispatch)
    }
  }

  return (
    <div className="container-switch">
      <div
        className="switch-container"
        onClick={() => handleAutoPlay()}
        title={String(translate(autoPlay ? 'autoplay-on' : 'autoplay-off'))}
      >
        <input type="checkbox" id="switch" checked={autoPlay} />
        <label htmlFor="switch">
          {autoPlay ? (
            <PlayArrowIcon
              style={{
                color: 'black',
                fontSize: '16px',
                position: 'relative',
              }}
            />
          ) : (
            <PauseCircleFilled
              style={{
                color: 'black',
                fontSize: '16px',
                position: 'relative',
              }}
            />
          )}
        </label>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => {
  return {
    autoPlay: !!state?.auth?.user?.settings?.autoPlay,
    translate: getTranslation(state?.translation?.lang),
  }
}

export default connect(mapStateToProps)(VideoSwitch)
