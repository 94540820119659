import * as React from 'react'
import { Icon } from '@material-ui/core'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Message from 'src/components/reusable/Message'
import { Notification as NotificationData } from 'src/gql/queries/getNotifications'

interface Props {
  // translate:Translate
  classes?: {
    success: string
  }
  notification: NotificationData
}
interface State {}

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
})

// @ts-ignore
@withStyles(styles, { withTheme: true })
export class PopupNotification extends React.Component<Props, State> {
  render() {
    return (
      <div className="custom-notification">
        <div className={classNames(this.props.classes.success, 'custom-notification__icon')}>
          <Icon>check</Icon>
        </div>
        <div className="custom-notification__text">
          <p className="word-break">
            <Message html={this.props.notification.title} />
          </p>
        </div>
      </div>
    )
  }
}

export default PopupNotification
