const initialState = {
  data: null,
}

const subscriptionReducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case 'ADD_DATA':
      return {
        ...state,
        data: payload.data,
      }

    default:
      return state
  }
}

export default subscriptionReducer
