import * as React from 'react'
import { useState, useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  ListSubheader,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core'
import { compose } from 'underscore'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import { useMutation } from 'react-apollo'

import { CHANNEL_COMMAND_MUTATION, ChannelCommandVariables } from 'src/gql/channelCommand'
import { Channel } from 'src/gql/listChannels'
import { ReduxState } from 'src/store/reducers'
import { sleep } from 'src/utils/sleepFunction'
import { getTranslation, Translate } from 'src/translation'
import { styles } from './styles'
import Item from './Item'
import Spinner from '../Spinner'

const DEFAULT_CHANNEL_COUNT_TO_SHOW = 5

type MappedProps = {
  classes?: any
  translate: Translate
  tubeColor: string
  channels: Channel[]
  userProfileImage: string
  tubeIcon: string
  userEmail: string
}

type Props = MappedProps

const PersonalChannelList = (props: Props) => {
  const { classes, translate, tubeColor, channels, userProfileImage, userEmail, tubeIcon } = props
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [showProgressMessage, setShowProgressMessage] = useState(false)
  const [showAllChannels, setShowAllChannels] = useState(false)

  const [selectedChannelsIds, setSelectedChannelsIds] = useState([])
  const [showAddPrivateChannelMenuIcon, setShowAddPrivateChannelMenuIcon] = useState(false)
  const [isAddPrivateChannelMenuOpen, setIsAddPrivateChannelMenuOpen] = useState(null)

  const getChannels = (parentId: string) =>
    sortBy(
      (channels || [])
        .filter(channel => channel.parent === parentId)
        .filter(item => item.isPersonalChannel && !item.shared),
      'name'
    )

  const channelsToShow = useMemo(() => {
    const rootChannelsToShow = getChannels(localStorage.getItem('tubeId'))
    const channelWithNoParent = (channels || [])
      .filter(item => item.parent !== localStorage.getItem('tubeId'))
      .filter(item => {
        const hasParentInList = channels.find(channel => channel.id === item.parent)
        return !hasParentInList
      })
      .filter(item => item.isPersonalChannel && !item.shared)

    const allChannels = [...rootChannelsToShow, ...channelWithNoParent]
    const channelsOwnerdByUser = (allChannels || []).filter(channel => channel.owner === userEmail)
    const channelsSharedWithUser = (allChannels || []).filter(channel => channel.owner !== userEmail)

    return [...channelsOwnerdByUser, ...channelsSharedWithUser]
  }, [channels])

  const [ChannelCommand] = useMutation(CHANNEL_COMMAND_MUTATION)

  const onAddPersonalChannel = async () => {
    setShowProgressMessage(true)
    const payload = {
      tubeId: localStorage.getItem('tubeId'),
      name: userEmail,
    }

    const result = await ChannelCommand(
      ChannelCommandVariables({
        type: 'create',
        payload,
      })
    )

    await sleep(3000)
    setShowProgressMessage(false)

    const channelId = result.data.res.id
    window.location.href = `/channel/${channelId}/home`
  }

  return (
    <>
      <div className={classNames(classes.mobileHidden, classes.groupSeparator)}>
        <ListSubheader
          disableSticky={true}
          className={classNames(classes.heading, classes.headingPadding)}
          component="div"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onMouseEnter={() => setShowAddPrivateChannelMenuIcon(true)}
          onMouseLeave={() => setShowAddPrivateChannelMenuIcon(false)}
        >
          <span>{translate('personal-channels')}</span>
          {channelsToShow.length > 0 && showAddPrivateChannelMenuIcon && (
            <Icon onClick={event => setIsAddPrivateChannelMenuOpen(event.target)}>more_vert</Icon>
          )}

          <Menu
            id="add-private-channel-menu"
            anchorEl={isAddPrivateChannelMenuOpen}
            open={isAddPrivateChannelMenuOpen}
            onClose={() => {
              setShowAddPrivateChannelMenuIcon(false)
              setIsAddPrivateChannelMenuOpen(null)
            }}
            PaperProps={{
              style: {
                maxHeight: 220,
                width: 200,
              },
            }}
          >
            <MenuItem
              onClick={() => {
                // setIsAddChannelDialogOpen(true)
                setShowAddPrivateChannelMenuIcon(false)
                setIsAddPrivateChannelMenuOpen(null)
                setOpenConfirmationDialog(true)
              }}
            >
              <p>{translate('add-personal-channel')}</p>
            </MenuItem>
          </Menu>
        </ListSubheader>
        {!channelsToShow?.some(e => e.owner === userEmail) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
            }}
          >
            <Button
              onClick={() => {
                setOpenConfirmationDialog(true)
              }}
              style={{ backgroundColor: tubeColor, height: 40 }}
              className={classNames(classes.addChannelButton)}
            >
              <p>{translate('add-personal-channel')}</p>
            </Button>
          </div>
        )}

        {channelsToShow
          .slice(0, showAllChannels ? channelsToShow.length : DEFAULT_CHANNEL_COUNT_TO_SHOW)
          .map(channel => (
            <Item
              tubeIcon={tubeIcon}
              key={channel.id}
              classes={classes}
              channel={channel}
              translate={translate}
              selectedChannelsIds={selectedChannelsIds}
              setSelectedChannelsIds={setSelectedChannelsIds}
              tubeColor={tubeColor}
              getChannels={getChannels}
              userProfileImage={userProfileImage}
            />
          ))}

        {channelsToShow.length > DEFAULT_CHANNEL_COUNT_TO_SHOW && (
          <div
            className={classes.showMoreButtonWrapper}
            onClick={() => {
              setShowAllChannels(!showAllChannels)
            }}
          >
            {showAllChannels ? (
              <>
                <Icon className={classes.menuClass}>keyboard_arrow_up</Icon>
                <span className={classes.menuText}>{translate('showLess')}</span>
              </>
            ) : (
              <>
                <Icon className={classes.menuClass}>keyboard_arrow_down</Icon>
                <span className={classes.menuText}>
                  {translate('show')} {channelsToShow.length - DEFAULT_CHANNEL_COUNT_TO_SHOW} {translate('more')}
                </span>
              </>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={openConfirmationDialog}
        onClick={e => e.stopPropagation()}
        onClose={e => e.stopPropagation()}
        PaperProps={{
          style: {
            marginTop: 60,
            minHeight: 200,
            maxWidth: 600,
            minWidth: 400,
          },
        }}
      >
        <DialogTitle>{translate('add-personal-channel')}</DialogTitle>
        <DialogContent>{translate('create-personal-channel-dailog')}</DialogContent>
        <DialogActions>
          <Button name="Cancel" id="cancel-add-personal-channel-btn" onClick={() => setOpenConfirmationDialog(false)}>
            {translate('cancel')}
          </Button>
          <Button
            name="Add Personal Channel"
            id="add-personal-channel-btn"
            type="button"
            variant="raised"
            color="primary"
            onClick={() => {
              setOpenConfirmationDialog(false)
              onAddPersonalChannel()
            }}
          >
            {translate('add-personal-channel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showProgressMessage}
        onClose={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        disableBackdropClick={true}
        PaperProps={{
          style: {
            marginTop: 60,
            minHeight: 200,
            maxWidth: 600,
            minWidth: 400,
          },
        }}
      >
        <DialogContent
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 16,
          }}
        >
          <p>{translate('adding-personal-channel')}</p>
          <Spinner variant="tube" tubeColor={tubeColor} />
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  translate: getTranslation(state.translation.lang),
  tubeColor: state.auth.tube.color,
  channels: state.channel.channelList,
  userEmail: state.auth.user.email,
  userProfileImage: state.auth.user.profile.profilePic,
  tubeIcon: state.auth.tube.icon,
})

// @ts-ignore
export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(PersonalChannelList)
