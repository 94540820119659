import * as React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Dialog } from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles'
import { getTranslation } from '../../../../translation'
import { renderTextField } from '../../../reusable/formElements'

const required = value => {
  return value ? undefined : 'Required'
}

const styles = theme => ({
  content: {
    width: 500,
    // minHeight: 300,
  },
})

const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  title: 'title',
  description: 'description',
}

// @ts-ignore
@withStyles(styles)
@connect((state, ownProps) => {
  return {
    ...ownProps,
    translate: getTranslation(state.translation.lang),
  }
}, null)
export class SupportForm extends React.Component {
  props: {
    open: boolean
    userEmail: string
    onClose: any
    hideTitle?: boolean
    title: string
    confirmLabel: string
    descriptionText?: string
    email?: string
    role?: string
    channelId?: string
    classes?: any
    translate?: any
  }

  onSubmit = async values => {
    this.props.onClose(values)
  }

  handleCancel = () => {
    this.props.onClose()
  }

  render() {
    const { classes, confirmLabel, descriptionText, title, hideTitle, translate, open } = this.props

    return (
      <Form
        onSubmit={this.onSubmit}
        destroyOnUnregister={true}
        initialValues={{
          firstName: localStorage.getItem('firstName') || '',
          lastName: localStorage.getItem('lastName') || '',
          phoneNumber: localStorage.getItem('phoneNumber') || '',
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={open}
            maxWidth="md"
            aria-labelledby="error-dialog"
          >
            <form onSubmit={handleSubmit}>
              <DialogTitle id="zendesk-form-title">{title}</DialogTitle>
              <DialogContent className={classes.content}>
                <div>
                  {!hideTitle && (
                    <div>
                      <Field
                        validate={required}
                        fullWidth={true}
                        id="title"
                        className="mb-16"
                        name={formFields.title}
                        label={`${translate('issue-title')}*`}
                        component={renderTextField}
                      />
                    </div>
                  )}
                  <Field
                    id="description"
                    className="mb-16"
                    multiline={true}
                    fullWidth={true}
                    rows={1}
                    rowsMax={5}
                    name={formFields.description}
                    label={translate(descriptionText || 'issue-description')}
                    component={renderTextField}
                  />
                  <span style={{ marginRight: 9 }}>
                    <Field
                      validate={required}
                      id="firstName"
                      className="mb-16 "
                      name={formFields.firstName}
                      label={`${translate('firstName')}*`}
                      component={renderTextField}
                    />
                  </span>
                  <Field
                    validate={required}
                    id="lastName"
                    className="mb-16"
                    name={formFields.lastName}
                    label={`${translate('lastName')}*`}
                    component={renderTextField}
                  />
                </div>

                <div>
                  <Field
                    id="phoneNumber"
                    fullWidth={true}
                    className="mb-16"
                    name={formFields.phoneNumber}
                    label={translate('phoneNumber')}
                    component={renderTextField}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button name="Cancel" id="cancel-btn" onClick={this.handleCancel} color="primary">
                  {translate('cancel')}
                </Button>
                <Button name="Submit" id="submit-form0btn" type="submit" color="primary">
                  {confirmLabel}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      />
    )
  }
}
