export const styles = theme => ({
  supportButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '1.4rem !important',
    textTransform: 'none' as 'none',
    padding: '5px 10px',
    minHeight: 'unset',
    marginLeft: '20px !important',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}`,
      color: `#fff !important`,
    },
  },

  modalBody: {
    padding: '16px 16px 0px 16px',
  },
  line: {
    color: 'rgb(107 114 128)',
    background: 'rgb(107 114 128)',
  },
})
