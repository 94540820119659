export const CHANGE_SIDEBAR_WIDTH = '[LAYOUT SIDEBAR] WIDTH CHANGE'

export type LayoutSidebarAction = {
  type: string
  payload: {
    width: number
  }
}

type Dispatch = (action: LayoutSidebarAction) => void

export const changeLayoutSidebarWidht = (width: number) => (dispatch: Dispatch) =>
  dispatch({
    type: CHANGE_SIDEBAR_WIDTH,
    payload: { width },
  })
