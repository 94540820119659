import gql from 'graphql-tag'
import { DataValue, graphql } from 'react-apollo'

export type GetUserFollowData = DataValue<{
  userFollowData: {
    tracks: string[]
    channels: string[]
    videos: string[]
    alertsEnabledChannels: string[]
  }
}>

export type UserFollowData = {
  tracks: string[]
  channels: string[]
  videos: string[]
  alertsEnabledChannels: string[]
}

export const getUserFollowDataGql = gql`
  query($id: String) {
    userFollowData(id: $id) @rest(type: "userFollowData", path: "/getUserFollowingData", endpoint: "query") {
      tracks
      channels
      videos
      alertsEnabledChannels
    }
  }
`

export const getUserFollowData = () =>
  graphql(getUserFollowDataGql, {
    options: {
      variables: {},
    },
    props: ({ data }) => ({
      getUserFollowDataQuery: data,
    }),
  })
