import * as React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import { Icon, IconButton } from '@material-ui/core'

interface BookDemoDialogProps {
  open: boolean
  onClose: () => void
  classes?: any
}

const BookDemoDialog = ({ open, onClose, classes }: BookDemoDialogProps) => (
  <Dialog open={open} onClose={onClose} maxWidth={false} aria-labelledby="confirm-dialog">
    <DialogContent className={classes.content}>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Icon>clear</Icon>
      </IconButton>
      <iframe
        title="booking"
        src="https://outlook.office365.com/owa/calendar/RequestDemo@launchpeople.com/bookings/"
        width="1050"
        height="700"
        scrolling="yes"
      />
    </DialogContent>
  </Dialog>
)

const styles = {
  content: {
    width: 1050,
    minHeight: 700,
    padding: 0,
    paddingTop: '5px !important',
  },
  closeButton: {
    float: 'right' as any,
    marginRight: 15,
  },
}

export default withStyles(styles)(BookDemoDialog)
