import * as Actions from '../actions/tube.actions'

const initialState = {
  id: '',
  pathId: '',
  thumbnailOverlay: '',
  thumbnailLogo: '',
  logo: '',
  backgroundImage: '',
  color: '#9C3094',
  name: '',
}

const tube = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_TUBE_DATA: {
      return {
        ...initialState,
        ...{ ...action.payload, color: action.payload.color || '#9C3094' },
      }
    }
    case Actions.UPDATE_TUBE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case Actions.SET_BRANDING_COLOR: {
      return {
        ...initialState,
        ...{ color: action.payload || '#9C3094' },
      }
    }

    default: {
      return state
    }
  }
}

export default tube
