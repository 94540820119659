import FuseSettingsConfig from './fuseSettingsConfig'
import * as _ from 'lodash'

const defaultSettings = {
  layout: {
    style: 'layout1',
    config: {
      scroll: 'content',
      navbar: {
        display: true,
        folded: false,
        position: 'left',
      },
      toolbar: {
        display: true,
        style: 'fixed',
        position: 'below',
      },
      footer: {
        display: true,
        style: 'fixed',
        position: 'below',
      },
      mode: 'fullwidth',
    },
  },
  customScrollbars: true,
  theme: {
    main: 'default',
    navbar: 'mainThemeDark',
    toolbar: 'mainThemeLight',
    footer: 'mainThemeDark',
  },
}

const FuseDefaultSettings = _.merge({}, defaultSettings, FuseSettingsConfig)

export default FuseDefaultSettings
