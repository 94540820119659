import gql from 'graphql-tag'
import uid from 'uid'
import { DataValue, graphql } from 'react-apollo'

export type VideosWatched = {
  [videoId: string]: {
    count: number
    lastTimeWatched: number
  }
}

export type UserAnalytics = {
  id: string
  videosWatched: VideosWatched
  pagesVisited: string[]
}

export type GetUserAnalytics = DataValue<{
  userAnalytics: UserAnalytics
}>

const isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1

export const getUserAnalyticsGql = gql`
      query {
        userAnalytics @rest(type: "UserAnalytics", path: "/user-analytics${
          isIE ? `?randID=${uid()}` : ''
        }", endpoint: "query") {
          id
          videosWatched
          pagesVisited
        }
      }
    `

export const getUserAnalyticsData = () =>
  graphql(getUserAnalyticsGql, {
    options: {},
    props: ({ data }) => ({
      getUserAnalyticsQuery: data,
    }),
  })
