import * as React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Icon } from '@material-ui/core'
import * as jwt from 'jsonwebtoken'
import { Translate } from 'src/translation'

type Props = {
  open: boolean
  translate: Translate
  onClose: any
  tubeColor: string
  orgId: string
}

export default class extends React.Component<Props, { email: string; password: string; url: string }> {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      url: '',
    }
  }

  private onClose = () => {
    this.setState({ email: '', password: '', url: '' })
    this.props.onClose()
  }

  private onCopy = () => {
    const copyText = document.querySelector('#copy-url') as HTMLInputElement
    copyText.select()
    document.execCommand('copy')
    copyText.blur()
    this.onClose()
  }

  private onCreate = () => {
    const { orgId } = this.props
    const token = jwt.sign(
      { email: this.state.email, password: this.state.password, redirect: window.location.pathname },
      orgId
    )

    this.setState({ url: `${window.location.origin}/auto-login?token=${token}` })
  }

  render() {
    const { open, translate } = this.props

    return (
      <Dialog open={open} onClose={this.onClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>{translate('create-link')}</DialogTitle>

        <DialogContent className="flex flex-col">
          <TextField
            id="create-link-email"
            label={translate('email')}
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
          />
          <TextField
            className="mt-16"
            id="create-link-password"
            label={translate('password')}
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}
          />

          {this.state.url && (
            <div className="flex mt-32 items-center">
              <input
                id="copy-url"
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                value={this.state.url}
                onChange={() => {}}
              />

              <Icon className="ml-16" style={{ fontSize: 30, cursor: 'pointer' }} onClick={this.onCopy}>
                file_copy
              </Icon>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button name="Cancel" id="create-link-close-btn" onClick={() => this.onClose()}>
            {translate('cancel')}
          </Button>
          <Button
            name="Create"
            color="primary"
            id="create-link-button"
            disabled={!this.state.email || !this.state.password}
            onClick={this.onCreate}
          >
            {translate('create')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
