import * as React from 'react'
import { Link } from 'react-router-dom'
import { ReduxState } from 'src/store/reducers'
import { connect } from 'react-redux'

type Props = {
  to: string
  tubeColor?: string
  className?: string
  onClick?: any
  style?: {
    [key: string]: any
  }
  children: any
  dispatch: any
}

const RouterLink = ({ children, style, tubeColor, dispatch, ...other }: Props) => (
  <Link {...other} style={{ color: tubeColor, ...style }}>
    {children}
  </Link>
)

const mapStateToProps = (state: ReduxState) => ({
  tubeColor: state.auth.tube.color,
})

export default connect(mapStateToProps, null)(RouterLink)
