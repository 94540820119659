import * as React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { Avatar, Icon } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import styles from './Notifications.style'
import { Translate } from 'src/translation'
import TaskTypes from 'src/enums/TaskTypes'
import { getInitials } from 'src/utils/common'
import Message from 'src/components/reusable/Message'
import { Channel } from 'src/gql/queries/listChannels'
import taskTypeToIconMap from 'src/maps/taskTypeToIconMap'
import taskTypeToIntMap from 'src/maps/taskTypeToIntlMap'
import { Notification } from 'src/gql/queries/getNotifications'
import { getNotifications } from 'src/auth/cognito/organizationApi'
import NoNotificationsCard from 'src/components/reusable/NoNotificationsCard'

type Props = {
  classes?: {
    channelImg: string
    settingsIcon: string
    notification: string
    bodyContainer: string
    videoThumbnail: string
    notOpenedAlert: string
    headerContainer: string
    notificationText: string
    notificationTimer: string
    unseenNotification: string
    notificationAbstract: string
    notificationBodyText: string
    notificationContainer: string
    nonotificationContainer: string
    notificationBadgeWrapper: string
  }
  channels: Channel[]
  tubeColor: string
  history: {
    push: (route: string) => void
  }
  translate: Translate
  updateSeenStatus: () => void
  notifications: Notification[]
  updateIsOpenedStatus: (notificationId: string) => void
}

//@ts-ignore
@withStyles(styles)
class Notifications extends React.Component<{} & RouteComponentProps<Props>, null> {
  componentDidMount() {
    const { notifications } = this.props

    const unseenNotificationsCount = notifications
      .filter(notification => !notification.isSeen)
      .map(notification => notification.id)

    if (!!unseenNotificationsCount) {
      //  Mark the observations as seen when the component unmounts
      this.props.updateSeenStatus()
    }
  }

  componentWillUnmount() {
    const { notifications } = this.props

    const unseenNotificationsCount = notifications
      .filter(notification => !notification.isSeen)
      .map(notification => notification.id)

    if (!!unseenNotificationsCount) {
      // Refetch notifications on unmount.
      getNotifications()
    }
  }

  render() {
    const { classes, history, channels, translate, tubeColor, notifications, updateIsOpenedStatus } = this.props

    return (
      <div className={classes.notificationContainer}>
        <div className={classes.headerContainer}>
          <h2 className={classes.notificationText}>{translate('notifications')}</h2>
        </div>

        {!notifications.length ? (
          <div className={classes.nonotificationContainer}>
            <NoNotificationsCard translate={translate} />
          </div>
        ) : (
          <>
            <div className={classes.bodyContainer}>
              {notifications.map(notification => {
                const notificationChannel = (channels || []).find(channel => channel.id === notification.channelId)
                const createdAtDate = moment(new Date(notification.createdAt)).fromNow()

                return (
                  <div
                    className={classNames(
                      classes.notification,
                      !notification.isSeen ? classes.unseenNotification : '',
                      'pl-10'
                    )}
                    key={notification.id}
                    onClick={async () => {
                      if (notification.type === TaskTypes.WATCH_VIDEO) {
                        history.push(`/video/${notification.videoId}`)
                      }
                      await updateIsOpenedStatus(notification.id)

                      getNotifications()
                    }}
                  >
                    <div className="flex w-full">
                      {/* <div className="flex w-6">
                        <div className="m-auto">
                          {!notification.isOpened ? (
                            <div className={classes.notOpenedAlert} style={{ background: tubeColor }} />
                          ) : (
                            <div className={classes.notOpenedAlert} style={{ background: 'none' }} />
                          )}
                        </div>
                      </div> */}
                      <div className="flex ml-10 pl-10">
                        <div className="m-auto">
                          {notificationChannel && notificationChannel.channelIcon ? (
                            <Avatar
                              className={classes.channelImg}
                              alt={notificationChannel.name}
                              src={notificationChannel.channelIcon}
                            />
                          ) : (
                            <Avatar className={classes.channelImg}>
                              {getInitials(notificationChannel ? notificationChannel.name : '')}
                            </Avatar>
                          )}
                          <div
                            className={classes.notificationBadgeWrapper}
                            title={translate(taskTypeToIntMap[notification.type])}
                          >
                            <Icon style={{ color: 'white', fontSize: 14, marginTop: 3, marginLeft: 3 }}>
                              {taskTypeToIconMap[notification.type]}
                            </Icon>
                          </div>
                        </div>
                      </div>

                      <div className="flex w-full">
                        <div className={classes.notificationBodyText}>
                          <div className={classes.notificationAbstract}>
                            <Message html={notification.title} />
                          </div>

                          {notification.isRecommendation && (
                            <div style={{ fontSize: 11, color: 'gray' }}>
                              <div title={notification.videoAbstract}>{notification.videoAbstract}</div>
                            </div>
                          )}

                          <div
                            className={classes.notificationTimer}
                            style={{
                              color: !notification.isOpened ? tubeColor : '',
                            }}
                          >
                            {createdAtDate}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Notifications
