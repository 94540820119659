import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Card, CardContent, Typography } from '@material-ui/core'
import classNames from 'classnames'
import Animate from '../../../theme/components/Animate'
import TextFieldFormsy from '../../../theme/components/TextFieldFormsy'
import Formsy from 'formsy-react'
import axios, { AxiosResponse } from 'axios'

const isDevStage =
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf(':3000') !== -1 ||
  window.location.href.indexOf('tt-react-dev') !== -1 ||
  window.location.href.indexOf('tt-react-test') !== -1 ||
  window.location.href.indexOf('tt-react-qa') !== -1 ||
  window.location.href.indexOf('.cloudfront.net') !== -1 ||
  window.location.href.indexOf('.dev.trainingtube.com') !== -1

const styles = () => ({
  root: {
    background: "url('/assets/images/backgrounds/background.png') no-repeat",
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    '@media(max-width: 767px)': {
      padding: '0 !important',
    },
  },
  intro: {
    color: '#ffffff',
    '@media(max-width: 767px)': {
      display: 'none !important',
    },
  },
  card: {
    width: '100%',
    maxWidth: 400,
    height: '100%',
    minHeight: 700,
    '@media(max-width: 767px)': {
      maxWidth: '100%',
      margin: '0 !important',
    },
  },
  logoImg: {
    position: 'absolute',
    bottom: 75,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      bottom: 40,
    },
    '& img': {
      width: 300,
    },
  },
})

type Props = {
  classes?: any
  apiUrl: string
}

// @ts-ignore
@withStyles(styles, { withTheme: true })
class RootLogin extends React.PureComponent<Props, { canSubmit: boolean }> {
  state = {
    canSubmit: false,
  }

  form = React.createRef()

  public componentDidMount() {
    const lastOrgId = localStorage.getItem('lastOrgId')
    if (lastOrgId) {
      window.location.href = `https://${lastOrgId}.trainingtube.com/login`
    }

    if (isDevStage) return

    const scriptEl = document.createElement('script')
    const noScriptEl = document.createElement('noscript')
    const imgEl = document.createElement('img')

    scriptEl.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '432949744083181');
      fbq('track', 'PageView');
    `
    imgEl.src = 'https://www.facebook.com/tr?id=432949744083181&ev=PageView&noscript=1'
    imgEl.height = 1
    imgEl.width = 1
    imgEl.hidden = true

    noScriptEl.appendChild(imgEl)
    document.body.appendChild(scriptEl)
    document.body.appendChild(noScriptEl)
  }

  disableButton = () => {
    this.setState({ canSubmit: false })
  }

  enableButton = () => {
    this.setState({ canSubmit: true })
  }

  onSubmit = async model => {
    try {
      const { data } = (await axios({
        method: 'GET',
        url: `${this.props.apiUrl}/getOrgId`,
        params: {
          email: model.username,
        },
      })) as AxiosResponse<{ orgId: string; domain: string }>

      localStorage.setItem('lastOrgId', data.orgId)
      window.location.href = `https://${data.orgId}.trainingtube.com/login?email=${model.username}`
    } catch (err) {
      // @ts-ignore
      this.form.updateInputsWithError({
        username: 'This email does not belong to any organization',
      })
    }
  }

  render() {
    const { classes } = this.props
    const { canSubmit } = this.state

    return (
      <div
        className={classNames(classes.root, 'flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0')}
        style={{
          background: `url('/assets/images/backgrounds/background.png') no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div
          className={classNames(
            classes.intro,
            'flex flex-col flex-no-grow items-center p-16 text-center md:p-128 md:items-start md:flex-no-shrink md:flex-1 md:text-left'
          )}
        />

        <Animate animation="transition.expandIn">
          <Card className={classNames(classes.card, 'mx-auto m-16 md:m-0')}>
            <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
              <Typography variant="title" className="md:w-full mb-32">
                Login to organization
              </Typography>
              <Formsy
                onValidSubmit={this.onSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                ref={form => {
                  this.form = form
                }}
                className="flex flex-col justify-center w-full"
              >
                <TextFieldFormsy
                  id="email"
                  className="mb-16"
                  type="email"
                  name="username"
                  label="Email"
                  required={true}
                />

                <Button
                  type="submit"
                  variant="raised"
                  color="primary"
                  className="w-full mx-auto mt-16 normal-case"
                  aria-label="LOG IN"
                  disabled={!canSubmit}
                  value="legacy"
                  name="Login"
                  id="login-button"
                >
                  Login
                </Button>
              </Formsy>
              <div className={classes.logoImg}>
                <Typography variant="subheading">Powered by</Typography>
                <img src="./assets/images/launchpeople-logo-venstre-lilla-screen_p.jpg" alt="logo" />
              </div>
            </CardContent>
          </Card>
        </Animate>
      </div>
    )
  }
}

export default RootLogin
