import { includes, pickBy, keys } from 'lodash'

import { TOGGLE_FILTER, CHANGE_SEARCH, RESET_SEARCH_FILTER, SearchVideoAction } from '../actions/searchVideos.actions'
import { FilterState, Filters } from '../types/filterVideos'

const initState: FilterState = {
  searchValue: '',
  isFiltered: false,
  filters: {
    authorEmail: [],
    status: [],
    type: [],
    channel: [],
    subChannel: [],
    track: [],
    subTrack: [],
    subSubTrack: [],
  },
}

const checkedIsFiltered = (filters: Filters) => {
  let flag = false
  Object.keys(filters).forEach(key => {
    if (filters[key].length) flag = true
  })
  return flag
}

export default (state: FilterState = initState, { type, payload }: SearchVideoAction): FilterState => {
  switch (type) {
    case CHANGE_SEARCH:
      return {
        ...state,
        searchValue: payload.key,
      }

    case TOGGLE_FILTER:
      const filters = pickBy(
        {
          ...state.filters,
          subChannel: payload.key === 'channel' ? [] : state.filters.subChannel,
          track: payload.key === 'channel' || payload.key === 'subChannel' ? [] : state.filters.track,
          subTrack:
            payload.key === 'channel' || payload.key === 'subChannel' || payload.key === 'track'
              ? []
              : state.filters.subTrack,
          subSubTrack:
            payload.key === 'channel' ||
            payload.key === 'subChannel' ||
            payload.key === 'track' ||
            payload.key === 'subTrack'
              ? []
              : state.filters.subSubTrack,
          [payload.key]: !state.filters[payload.key]
            ? [payload.filter]
            : !includes(state.filters[payload.key], payload.filter)
            ? [...state.filters[payload.key], payload.filter]
            : state.filters[payload.key].filter(f => f !== payload.filter),
        },
        (value, key) => includes(keys(initState.filters), key) || value.length
      )
      return {
        ...state,
        isFiltered: checkedIsFiltered(filters),
        filters,
      }

    case RESET_SEARCH_FILTER:
      return {
        ...state,
        filters: initState.filters,
      }

    default:
      return state
  }
}
