import { Button, DialogActions, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux'
import { ErrorDialog } from 'src/components/reusable/ErrorDialog'

import { renderTextField } from 'src/components/reusable/formElements'
import FormElementHelperTooltip from 'src/components/reusable/FormElementHelperTooltip'
import Spinner from 'src/components/reusable/Spinner'
import { CREATE_SUPPORT_TASK } from 'src/gql/addComment'
import { ReduxState } from 'src/store/reducers'
import { getTranslation, Translate } from 'src/translation'

const required = value => {
  if (!value || value.trim() === '') {
    return 'Required'
  }
  return undefined
}

interface CreateSupportTaskProps {
  classes?: any
  onclose?: () => void
  callback: (chatId?: string) => void
  translate: Translate
}

const CreateSupportTask = (props: CreateSupportTaskProps) => {
  const { translate, callback, classes, onclose } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState('')

  const [addSupportTicket] = useMutation(CREATE_SUPPORT_TASK, {
    onError: error => {
      setError(error.message)
      setIsLoading(false)
    },
    onCompleted: (data: any) => {
      callback(data?.res?.taskId || data?.taskId)
      setIsLoading(false)
    },
  })

  const onSubmitForm = async values => {
    setIsLoading(true)
    await addSupportTicket({
      variables: {
        input: {
          type: 'createSupportTask',
          payload: {
            taskTitle: values.taskTitle,
            comment: values.taskMessage,
            ticketReferenceUrl: window.location.href,
          },
        },
      },
    })
    setIsLoading(false)
  }
  const handleCancel = () => {
    onclose()
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      destroyOnUnregister={true}
      initialValues={{
        titleTask: '',
        taskMessage: '',
      }}
      render={({ handleSubmit }) => (
        <div className={classes.dialog}>
          <Field
            validate={required}
            fullWidth={true}
            id="title"
            className="mb-16"
            name="taskTitle"
            InputLabelProps={{
              classes: {
                root: classes.formLabel,
              },
            }}
            label={
              <div>
                {translate('subject')} *
                <FormElementHelperTooltip title="request-assitant-subject-instructions" />
              </div>
            }
            component={renderTextField}
          />
          <Field
            validate={required}
            fullWidth={true}
            id="message"
            className="mb-16"
            multiline={true}
            rows={3}
            rowsMax={10}
            name="taskMessage"
            InputLabelProps={{
              classes: {
                root: classes.formLabel,
              },
            }}
            label={
              <div>
                {translate('chat-message')} *
                <FormElementHelperTooltip title="request-assitant-message-instructions" />
              </div>
            }
            component={renderTextField}
          />
          <ErrorDialog open={Boolean(error)} message={error} onClose={() => setError('')} />
          <DialogActions>
            <Button name="Cancel" id="cancel-btn" onClick={handleCancel} color="primary">
              {translate('cancel')}
            </Button>
            <Button
              variant="contained"
              name="Request"
              id="request-form-btn"
              type="button"
              color="primary"
              onClick={() => handleSubmit()}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <span
                  style={{
                    color: 'white',
                  }}
                >
                  {translate('request')}
                </span>
              )}
            </Button>
          </DialogActions>
        </div>
      )}
    />
  )
}

const mapStateToProps = (state: ReduxState) => ({
  tubeColor: state.auth.tube.color,
  user: state.auth.user?.profile,
  translate: getTranslation(state.translation.lang),
})

const styles = () => ({
  dialog: {
    flexDirection: 'column' as 'column',
    height: '100%',
    display: 'flex',
    marginTop: 10,
  },
  tooltip: {
    backgroundColor: '#e5e5e5',
    color: '#000',
    fontSize: '12px',
  },
  formLabel: {
    zIndex: 1,
    position: 'relative' as 'relative',
  },
  infoIcon: {
    backgroundColor: '#e5e5e5',
    padding: '1px 6px',
    marginLeft: '5px',
    borderRadius: '100%',
    fontSize: '1.3rem',
    color: '#000',
    border: '1px solid #eeee',
  },
})

export default connect(mapStateToProps)(withStyles(styles)(CreateSupportTask))
