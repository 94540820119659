export const styles = theme => ({
  groupSeparator: {
    borderBottom: '1px solid #00000019',
    padding: '8px 0',
  },
  heading: {
    'text-transform': 'uppercase',
    color: '#606060',
    fontSize: 14,
    lineHeight: '48px',
    fontWeight: 500,
  },

  topNavText: {
    color: '#030303',
    marginTop: 3,
    width: '200px',
  },
  topNavItem: {
    width: '100%',
    display: 'flex',
    padding: '12px 24px',
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: '#F2F2F2',
      color: '#030303',
    },
  },
  showMoreButtonWrapper: {
    cursor: 'pointer',
    color: '#030303',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    marginTop: 5,
    marginBottom: 5,
  },
  channelImg: {
    marginRight: 10,
    width: 22,
    height: 23,
    borderRadius: '100%',
    border: '1px solid #eee',
    display: 'flex',
    position: 'relative',
    fontSize: '1rem',
    justifyContent: 'center',
  },
  collapseButton: {
    minHeight: 'unset',
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mobileHidden: {
    '@media(max-width: 1024px)': {
      display: 'none',
    },
  },

  addChannelButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    padding: '5px 10px',
    minHeight: 'unset',
    margin: '15px 10px 0px',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}`,
      color: `#fff !important`,
    },
  },
})
