import * as React from 'react'
import { withStyles } from '@material-ui/core'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 40,
    padding: 120,
    backgroundColor: '#f5f5f5',
    minHeight: 'calc(100vh - 64px)',
  },
  image: {
    paddingTop: 120,
    width: 350,
  },
}

const Maintenance = ({ classes }) => (
  <div className={classes.root}>
    <div>We're down for maintenance</div>
    <div>Be right back!</div>
    <img className={classes.image} alt="Maintenance" src="assets/images/logos/training-tube.png" />
  </div>
)

// @ts-ignore
export default withStyles(styles)(Maintenance)
