import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'

import styles from './BrowserNotSupported.style'

type Props = {
  classes?: any
}

const BrowserNotSupported = ({ classes }: Props) => {
  return (
    <div className={classes.container}>
      {/* <div className={classes.upperContainer}><img src={"https://org-launchpeople.s3.eu-west-1.amazonaws.com/uploads/VrdUnaN_V.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAULKVSZNI5JDHOCFD%2F20201220%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20201220T112545Z&X-Amz-Expires=86400&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEMz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMSJHMEUCIQDkYg%2BqBoFNs7ekMX1j95rtKtstxh0fggcDW3IAlvDrzwIgY%2FYofyDSWkQ3U6KBSbR9ZnfdCnrJGsIb8Kb3mpqYqwwq4gEIdBACGgwyOTkyMTY1ODc2MDEiDPzuKn1yuOwOkiXpkCq%2FAcCgpE7LfA3jFin84Kj%2BjqcOM2oRBt5QZ3lZXh4olQjbCZG8j1a4K3RXzv009kisDlB4JXPngoVPQgoDrU0%2BCkhjXBEiF3WdTgx%2F%2BCJoO2FgnHt8vJQkd81slp3Rs3K9BgOiePi6BgflNHua4nLEZErlzI670JogpcvAsC2CwcZZlvs7R6LozbxRk8G7uwcLNlY6g9hPVjmZw%2F06Yg7M%2Fnqqqo0wmTj9eQg76ggFCZZ%2BRR3U52L8SWRFhcs0bO6XMLHl%2FP4FOuAB6v6Fstpmb5ohgFs2YGzoDIgTQBDjMYo4bSWB5ez5U5kNR81QHlfmkQZ7PUrne%2Bw3%2FWYrVwfSPzpOsknaiZ4mrAUFi875pvC8OfN%2FQCFBLAMYDGQS946fEsnBnB5ZOoq8I2Xosgz2ybTIoo2gHkvUi%2BJlnbpbsQ5IsgyOkgYUT%2BB8DwJvJzwXK82nM6um5LAvMjqvI9F1p3bYQw5Ki5UKhbttvQ26WzhaBngmtCdU%2BFuC95PbXadtmjdkS19FcYQ2EO6gAzCCzBoyCy0sxAHN5gVZHz2pOj9Hvtus4j0Gmj0%3D&X-Amz-Signature=bd2f77e5125c32672b0b4190ef1f39192bf55d372aea017a2ed70c43d359a103&X-Amz-SignedHeaders=host&response-content-type=image%2Fpng"}/></div> */}
      <div className={classes.upperContainer} />
      <div className={classes.infoContainer}>
        <div className={classes.title}>Browser not supported</div>
        <div className={classes.subtitle}>We recommend using TrainingTube with one of the following browsers:</div>
        <div style={{ marginLeft: 40, display: 'flex' }}>
          <span
            style={{ margin: 'auto', display: 'flex', cursor: 'pointer' }}
            onClick={() =>
              (window.location.href =
                'https://www.google.com/chrome/?brand=BNSD&gclid=Cj0KCQiAifz-BRDjARIsAEElyGKwN5j6DOAsVlck-LyEDM3G6cG6Bl9gMWxoxmwUC0lKpeEFW9d14XwaAgB1EALw_wcB&gclsrc=aw.ds')
            }
          >
            <img alt="chrome" src="./assets/images/chrome.png" style={{ width: 55, height: 55 }} />
            <span className={classes.text}>Google Chrome</span>
          </span>
          <span
            style={{ margin: 'auto', display: 'flex', cursor: 'pointer' }}
            onClick={() => (window.location.href = 'https://www.microsoft.com/en-us/edge')}
          >
            <img alt="edge" src="./assets/images/edge.png" style={{ width: 55, height: 55 }} />
            <span className={classes.text}>Microsoft Edge</span>
          </span>
          <span
            style={{ margin: 'auto', display: 'flex', cursor: 'pointer' }}
            onClick={() => (window.location.href = 'https://www.mozilla.org/en-US/firefox/new/')}
          >
            <img alt="firefox" src="./assets/images/firefox.png" style={{ width: 55, height: 55 }} />
            <span className={classes.text}>Firefox</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)<Props>(BrowserNotSupported)
