const styles = theme => ({
  player: {},
  ttLogo: {
    height: '2.4em',
    lineHeight: '3em',
    fontSize: '1em',
    padding: 3,
    marginTop: 3,
  },
  subtitles: {
    zIndex: 9,
    position: 'relative',
    top: '4px',
    '& > span': {
      height: '3em',
      lineHeight: '3em',
    },
    '& span': {
      color: 'white',
      textTransform: 'uppercase',
      fontSize: '1em',
      padding: '10px',
    },
    '& > div': {
      opacity: 0,
      transition: 'opacity 0.25s ease-in-out',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      minWidth: 'calc(100% + 10px)',
      backgroundColor: 'rgba(43, 51, 63, 0.7)',
      display: 'flex',
      '& > div > span': {
        backgroundColor: 'rgba(43, 51, 63, 0.7)',
        display: 'block',
        minWidth: '60px',
        transition: 'background-color 0.25s ease-in-out',
        '&:hover': {
          backgroundColor: 'rgba(43, 51, 63, 1)',
        },
        '&.active': {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    '& > div:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
    '& > span:hover': {
      cursor: 'pointer',
      '& + div': {
        opacity: 1,
      },
    },
    // '& > span > input[type ="checkbox"]': {
    //   width: 0,
    //   height: 0,
    //   visibility: 'hidden',
    // },
    // '& > span > label': {
    //   backgroundColor: 'rgba(43, 51, 63, 0.7)',
    // },
  },
  bitrates: {
    zIndex: 9,
    position: 'relative',
    top: '4px',
    // '& > span + div > .active': {
    //   backgroundColor: 'rgba(0,0,0,0.1)'
    // },
    '& > span': {
      height: '3em',
      lineHeight: '3em',
    },
    '& span': {
      color: 'white',
      textTransform: 'uppercase',
      fontSize: '1em',
      padding: '10px',
    },
    '& > div': {
      opacity: 0,
      transition: 'opacity 0.25s ease-in-out',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      minWidth: 'calc(100% + 10px)',
      backgroundColor: 'rgba(43, 51, 63, 0.7)',
      display: 'flex',
      '& > div > span': {
        // backgroundColor: 'rgba(43, 51, 63, 0.7)',
        display: 'block',
        minWidth: '60px',
        transition: 'background-color 0.25s ease-in-out',
        '&:hover': {
          backgroundColor: 'rgba(43, 51, 63, 1)',
        },
        '&.active': {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    '& > div:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
    '& > span:hover': {
      cursor: 'pointer',
      '& + div': {
        opacity: 1,
      },
    },
  },
  transcript: {
    zIndex: 4,
    backgroundColor: 'rgba(43, 51, 63, 0.7)',
    color: 'white',
    position: 'absolute',
    pointerEvents: 'none',
    bottom: 40,
    padding: '3px 7px',
    left: '50%',
    maxWidth: 'calc(100%)',
    transform: 'translate3d(-50%, -20px, 0)',
    width: 'calc(100%)',
    display: 'table',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.4,
    '.vjs-has-started.vjs-user-inactive.vjs-playing &': {
      transition: 'transform 1s',
      transform: 'translate3d(-50%, 0px, 0)',
    },
  },
  hidden: {
    display: 'none',
  },
  videoDuration: {
    bottom: 5,
    left: 3,
    color: '#fff',
    margin: '4px',
    fontWeight: 500,
    padding: '3px 5px',
    zIndex: 1,
    position: 'absolute',
    fontSize: 14,
    letterSpacing: 0.5,
    textAlign: 'center',
  },
  overlay: {
    width: 120,
    height: 120,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  videoEndWrapper: {
    zIndex: 12,
    overflow: 'hidden',
    position: 'absolute',
    bottom: '50%',
    top: 0,
    height: '100%',
    width: '100%',
    right: 0,
    marginBottom: 48,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  linkWrapper: {
    zIndex: 12,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  titleText: {
    marginTop: '14%',
    fontSize: '140%',
    color: 'rgba(255,255,255,0.7)',
  },
  linkTitle: {
    width: '70%',
    fontSize: '140%',
  },
  nextVideoName: {
    marginTop: '2%',
    fontSize: '200%',
    padding: '0px 10px 2px',
    marginLeft: 32,
    marginRight: 32,
    fontWeight: 500,
  },
  nextChannelName: {
    fontSize: '130%',
    marginTop: '2%',
    lineHeight: '1.3rem',
    marginBottom: '3%',
    color: 'white',
  },
  nextVideoPlayBtn: {
    width: 54,
    height: 54,
    borderRadius: '50%',
    background: 'rgba(255,255,255,0.3) !important',
  },

  nextVideoLink: {
    textDecoration: 'none',
    color: 'white',
    position: 'relative',
  },
  nextPlayIcon: {
    fontSize: 40,
    color: 'white',
    position: 'absolute',
  },
  loadingIcon: {
    width: '74px !important',
    height: '74px !important',
    position: 'absolute',
    marginLeft: '-10px',
    marginTop: '-10px',
  },
  cancelBtn: {
    marginTop: 30,
  },
  cancelText: {
    fontSize: '105%',
    padding: '10px 20px',
    fontWeight: 500,
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
  ribbon: {
    fontSize: 14,
    lineHeight: '15px',
    color: '#fff',
    background: '#556677',
    textAlign: 'center',
    transform: 'rotate(45deg)',
    position: 'absolute',
    padding: '3px 0',
    right: -30,
    top: 35,
    width: 150,
    zIndex: 1,
    left: 'auto',
  },
  essential: {
    backgroundColor: '#881155',
  },
  introduction: {
    backgroundColor: '#115588',
  },
  explanation: {
    backgroundColor: '#556677',
  },
  'useful-tip': {
    backgroundColor: '#228811',
  },
  videoWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& .vjs-menu-button-popup .vjs-menu .vjs-menu-content': {
      zIndex: 99,
    },
    // border: '1px solid lightgray',
    '& .vjs-resolution-button-label': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      fontSize: '1.2em',
      lineHeight: 2.5,
      textAlign: 'center',
    },
    '& .vjs-progress-control': {
      bottom: '3em ',
      width: '100% !important',
      position: 'absolute !important',
    },
    '& .vjs-play-progress': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    '& .vjs-control-bar': {
      background: 'linear-gradient(0deg, #000, transparent)',
      backgroundColor: 'transparent !important',
      height: '4em !important',
      zIndex: 9,
    },
    '& .vjs-button > .vjs-icon-placeholder:before': {
      fontSize: '2.3em !important',
    },
    '& .vjs-remaining-time': {
      marginLeft: 'auto !important',
      opacity: 0,
    },
    '& .vjs-volume-bar': {
      margin: '1.65em 0.45em !important',
    },
    '& .vjs-time-control': {
      display: 'flex',
      columnGap: '5px',
      fontSize: '12px !important',
    },
    '& .vjs-current-time': {
      display: 'block !important',
    },
    '& .vjs-duration': {
      display: 'block !important',
    },
    '& .vjs-playback-rate-value': {
      lineHeight: '2.5 !important',
    },
    '& .container-switch': {
      marginTop: '12px !important',
    },
  },
  errorCard: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 20,
  },
  errorProgress: {
    marginBottom: 10,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    color: 'white',
  },
})

export default styles
