import axios from 'axios'
import * as React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Dialog, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import store from 'src/store'
import { mainApi } from 'src/config'
import { Translate } from 'src/translation/'
import styles from './UserProfileDialog.style'
import { getInitials } from 'src/utils/common'
import { ReduxState } from 'src/store/reducers'
import { UserProfile } from 'src/interfaces/User'
import { countries } from 'src/constants/countries'
import Spinner from 'src/components/reusable/Spinner'
import { addNotification } from '../../../NotificationWrapper'
import { checkIfDevelopmentStage } from 'src/utils/common'
import { setUserProfile } from 'src/auth/store/actions/user.actions'
import { getUserPermissions } from 'src/auth/cognito/organizationApi'

type Props = {
  classes?: {
    error: string
    wrapper: string
    saveBtn: string
    clearImg: string
    userName: string
    inputField: string
    crossButton: string
    innerWrapper: string
    profilePicText: string
    nonEditableText: string
    userDetailTitle: string
    userDetailsWrapper: string
    uploadPictureButton: string
    welcomeMessageTitle: string
    profilePictureDefault: string
    inputRow: string
    reactSelect: string
  }
  profile?: UserProfile
  open: boolean
  tubeColor: string
  onClose: () => void
  translate: Translate
  saveUserProfile: (data: any) => void
}

interface State {
  //  This is set as any because the state of a uploaded file is too much to create a type for.
  selectedFile: any
  isSaving: boolean
  lastName: string
  jobTitle: string
  firstName: string
  profilePic: string
  phoneNumber: string
  country: string
}

export const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '16px',
    height: '16px',
    border: 0,
    boxShadow: 'none',
    marginTop: 2,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '20px',
    padding: '0 6px',
  }),

  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '16px',
    marginTop: 2,
  }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
}

// @ts-ignore
@withStyles(styles, { withTheme: true })
class UserProfileDialog extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    const { lastName, firstName, jobTitle, profilePic, phoneNumber, country } = this.props.profile

    this.state = {
      firstName,
      lastName,
      jobTitle,
      profilePic,
      phoneNumber,
      selectedFile: null,
      isSaving: false,
      country,
    }
  }

  handleChange = selectedCountry => {
    this.setState({ country: selectedCountry.value })
  }

  handleSave = async () => {
    const { firstName, lastName, jobTitle, phoneNumber, country } = this.state
    const selectedCountryValue = country
    this.setState({ isSaving: true })

    const isDevStage = checkIfDevelopmentStage()

    const mainApiUrl = mainApi(isDevStage)
    try {
      let pathUrl

      if (this.state.selectedFile) {
        //  Get a secure URL to upload to s3
        const apiRes = await axios({
          method: 'post',
          url: `${mainApiUrl.command}/getGenericS3UploadSignedUrl`,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          data: {
            fileExt: this.state.selectedFile.name.split('.').pop(),
          },
        })
        pathUrl = apiRes.data

        // Upload to s3
        await axios({
          method: 'put',
          url: pathUrl.signedUrl,
          headers: {
            'content-type': this.state.selectedFile.type,
          },
          data: this.state.selectedFile,
        })
      }

      // Save the users data
      await this.props.saveUserProfile({
        firstName,

        lastName,
        jobTitle,
        country: selectedCountryValue,
        phoneNumber,
        // If pic is removed, clear it
        ...(!this.state.profilePic && { profilePic: '' }),
        // If a new pic was uploaded. Save it
        ...(pathUrl && { profilePic: pathUrl.s3Path }),
      })

      // Refetch users data
      const data = await getUserPermissions()

      // save to redux
      setUserProfile(data.userData)(store.dispatch)
    } catch (e) {
      // Error toast
      addNotification({
        children: (
          <div className="custom-notification">
            <div className={classNames(this.props.classes.error, 'custom-notification__icon')}>
              <Icon>warning</Icon>
            </div>
            <div className="custom-notification__text">
              <p className="word-break">{this.props.translate('error-saving')}</p>
            </div>
          </div>
        ),
        position: 'tr',
        autoDismiss: 15,
        level: 'error',
      })

      this.setState({ isSaving: false })

      return
    }

    this.setState({ isSaving: false })

    this.props.onClose()
  }

  render() {
    const { open, tubeColor, onClose, classes, profile, translate } = this.props

    const { firstName, lastName, jobTitle, phoneNumber, country } = this.state

    const userName = `${profile.firstName || ''} ${profile.lastName || ''}`

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={false}
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        <div className={classes.wrapper}>
          <div className={classes.innerWrapper}>
            <div className={classes.crossButton} onClick={() => onClose()}>
              <Icon>close</Icon>
            </div>

            <div className={classes.welcomeMessageTitle}>{translate('welcome-msg')}</div>
            <div className={classes.userName}>{profile.firstName || profile.lastName ? userName : profile.email}</div>
          </div>

          <div className={classes.userDetailsWrapper}>
            <div className={classes.userDetailTitle}>{translate('profile-picture')}</div>
            {this.state.profilePic ? (
              <div className={classes.profilePictureDefault}>
                <img src={this.state.profilePic} alt="pic" className="w-100" />
                <div
                  className={classes.clearImg}
                  onClick={() => {
                    this.setState({ profilePic: '', selectedFile: null })
                  }}
                >
                  <Icon style={{ color: tubeColor }}>close</Icon>
                  <span>{translate('remove')}</span>
                </div>
              </div>
            ) : (
              <div className={classes.profilePictureDefault}>
                <span className={classes.profilePicText}>
                  {profile.firstName || profile.lastName
                    ? getInitials(userName)
                    : (profile.email || '').slice(0, 2).toUpperCase()}
                </span>
              </div>
            )}

            <div className={classes.uploadPictureButton}>
              <input
                accept="image/*"
                id="icon-button-file"
                onChange={e => {
                  if (!e.target && !e.target.files) {
                    return
                  }
                  const url = URL.createObjectURL(e.target.files[0])
                  this.setState({ profilePic: url, selectedFile: e.target.files[0] })
                }}
                type="file"
                style={{ display: 'none' }}
              />
              <label htmlFor="icon-button-file">
                <div color="primary" className="cursor-pointer">
                  {translate('upload-picture')}
                </div>
              </label>
            </div>
            <div className={classes.userDetailTitle} style={{ textAlign: 'center' }}>
              {translate('your-email')}
            </div>
            <div className={classes.nonEditableText}>{profile.email}</div>

            <div className={classes.inputRow}>
              <div>
                <div className={classes.userDetailTitle}>{translate('first-name')}</div>
                <input
                  className={classes.inputField}
                  value={firstName}
                  onChange={e => this.setState({ firstName: e.target.value })}
                  placeholder={String(translate('first-name-placeholder'))}
                />
              </div>
              <div>
                <div className={classes.userDetailTitle}>{translate('last-name')}</div>
                <input
                  className={classes.inputField}
                  value={lastName}
                  onChange={e => this.setState({ lastName: e.target.value })}
                  placeholder={String(translate('last-name-placeholder'))}
                />
              </div>
            </div>

            <div className={classes.inputRow}>
              <div>
                <div className={classes.userDetailTitle}>{translate('job-title')}</div>

                <input
                  className={classes.inputField}
                  value={jobTitle}
                  onChange={e => this.setState({ jobTitle: e.target.value })}
                  placeholder={String(translate('job-title-placeholder'))}
                />
              </div>

              <div>
                <div className={classes.userDetailTitle}>{translate('company-phone')}</div>
                <input
                  type="number"
                  className={classes.inputField}
                  value={phoneNumber}
                  onChange={e => this.setState({ phoneNumber: e.target.value })}
                  placeholder={String(translate('company-phone-placeholder'))}
                />
              </div>
            </div>

            <div className={classes.inputRow}>
              <div>
                <div className={classes.userDetailTitle}>{translate('location')}</div>
                <Select
                  value={
                    country
                      ? { label: country, value: country }
                        ? { label: country, value: country }
                        : { label: 'None', value: 'None' }
                      : 'Select Your Country'
                  }
                  onChange={this.handleChange}
                  placeholder={String(translate('country-placeholder'))}
                  className={classes.inputField}
                  styles={reactSelectStyles}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  options={countries.map(country => {
                    return { label: country, value: country }
                  })}
                />
              </div>
            </div>

            <button className={classes.saveBtn} onClick={() => this.handleSave()}>
              {this.state.isSaving ? (
                <span>
                  <Spinner />
                </span>
              ) : (
                translate('save')
              )}
            </button>
          </div>
        </div>
      </Dialog>
    )
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    profile: state.auth.user.profile,
  }
}

export default connect(mapStateToProps)(UserProfileDialog)
