import * as React from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getFuseThemesConfig } from '../../fuse-configs/fuseThemesConfig'
import * as _ from 'lodash'
import dark from '../colors/dark'
import lightBlue from '@material-ui/core/colors/lightBlue'
import red from '@material-ui/core/colors/red'

const mustHaveOptions = {
  typography: {
    htmlFontSize: 10,
  },
}

export const defaults = {
  default: {
    palette: {
      type: 'light',
      primary: dark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700],
      },
      error: red,
    },
    status: {
      danger: 'orange',
    },
  },
  defaultDark: {
    palette: {
      type: 'dark',
      primary: dark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700],
      },
      error: red,
    },
    status: {
      danger: 'orange',
    },
  },
}

export let FuseSelectedTheme

const themesObj = (tubeColor?: string) => {
  const fuseThemesConfig = getFuseThemesConfig(tubeColor)
  return Object.keys(fuseThemesConfig).length !== 0 ? fuseThemesConfig : defaults
}

const getTheme = (tubeColor?: string) =>
  Object.assign(
    {},
    // @ts-ignore
    ...Object.entries(themesObj(tubeColor)).map(([key, value]) => {
      // @ts-ignore
      const muiTheme = createMuiTheme(_.merge({}, value, mustHaveOptions))

      return {
        [key]: createMuiTheme(_.merge({}, muiTheme, { mixins: customMixins(muiTheme) })),
      }
    })
  )

export let themes = getTheme()

function customMixins(theme) {
  return {
    border: (width = 1) => ({
      borderWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderLeft: (width = 1) => ({
      borderLeftWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderRight: (width = 1) => ({
      borderRightWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderTop: (width = 1) => ({
      borderTopWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderBottom: (width = 1) => ({
      borderBottomWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
  }
}

function updateLightDarkThemes(val, tubeColor) {
  const newThemes = themesObj(tubeColor)
  const theme = newThemes[val]
  themes = {
    ...getTheme(tubeColor),
    mainThemeDark: createMuiTheme(_.merge({}, theme, { palette: { type: 'dark' }, ...mustHaveOptions })),
    mainThemeLight: createMuiTheme(_.merge({}, theme, { palette: { type: 'light' }, ...mustHaveOptions })),
  }
}

class FuseTheme extends React.PureComponent {
  state = {
    theme: null,
    tubeColor: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    themes = getTheme(prevState.tubeColor)
    FuseSelectedTheme = themes[nextProps.selectedTheme]
    updateLightDarkThemes(nextProps.selectedTheme, nextProps.tubeColor)
    const selected = themes[nextProps.selectedTheme]

    return !_.isEqual(prevState.theme, selected)
      ? {
          theme: selected,
          tubeColor: nextProps.tubeColor,
        }
      : null
  }

  render() {
    const { children } = this.props
    const { theme } = this.state

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  }
}

function mapStateToProps({ fuse, auth }) {
  return {
    selectedTheme: fuse.settings.current.theme.main,
    // Added as a quick fix a very specific issue.
    tubeColor: auth.tube.color === '#' ? '#9C3296' : auth.tube.color,
  }
}

export default withRouter(connect(mapStateToProps, null)(FuseTheme))
