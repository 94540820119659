export const TOGGLE_FILTER = '[SEARCH VIDEOS] TOGGLE FILTER'
export const CHANGE_SEARCH = '[SEARCH VIDEOS] CHANGE SEARCH'
export const RESET_SEARCH_FILTER = '[SEARCH VIDEOS] RESET SEARCH FILTER'

export type SearchVideoAction = {
  type: string
  payload: {
    key: string
    filter: string
  }
}

type Dispatch = (action: SearchVideoAction) => void

export const toggleSearchVideoFilter = (key: string, filter: string) => (dispatch: Dispatch) =>
  dispatch({
    type: TOGGLE_FILTER,
    payload: { key, filter },
  })

export const resetSearchVideoFilter = (key: string, filter: string) => (dispatch: Dispatch) =>
  dispatch({
    type: RESET_SEARCH_FILTER,
    payload: {
      key: '',
      filter: '',
    },
  })

export const changeSearchVideo = (value: string) => (dispatch: Dispatch) =>
  dispatch({
    type: CHANGE_SEARCH,
    payload: {
      key: value,
      filter: '',
    },
  })
