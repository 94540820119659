import * as Actions from '../actions/register.actions'

const initialState = {
  success: false,
  confirm: false,
  error: {
    username: null,
    password: null,
    code: null,
  },
}

const register = function(state = initialState, action) {
  switch (action.type) {
    case Actions.REGISTER_SUCCESS:
      return {
        ...initialState,
        success: true,
        confirm: false,
      }
    case Actions.REGISTER_ERROR:
      return {
        success: false,
        error: action.payload,
      }
    case Actions.REGISTER_CONFIRM: {
      return {
        ...initialState,
        confirm: true,
      }
    }
    default:
      return state
  }
}

export default register
