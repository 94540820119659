import gql from 'graphql-tag'
import { QueryResult } from 'react-apollo'

export type GetVideoUrl = QueryResult<{
  getUrlRes: {
    signedUrl: string
  }
}>

export const getVideoUrlQuery = (key: string, download = 0, accessTaskId?: string) => {
  let path = `/getVideoUrl?key=:key&download=:download`

  if (accessTaskId) {
    path += `&accessTaskId=:accessTaskId`
  }

  return {
    query: gql`
      query($key: String!, $download: Int!, $accessTaskId: String) {
        getUrlRes(key: $key, download: $download, accessTaskId: $accessTaskId)
          @rest(type: "VideoUrl", path: "${path}", endpoint: "query") {
          signedUrl
        }
      }
    `,
    variables: { key, download, accessTaskId: accessTaskId || undefined },
  }
}
