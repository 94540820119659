import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { matchRoutes } from 'react-router-config'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'
import * as _ from 'lodash'
import FuseLayouts from './layouts'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,

    '& table.simple tbody tr td': {
      borderColor: theme.palette.divider,
    },
    '& table.simple thead tr th': {
      borderColor: theme.palette.divider,
    },
    '& a:not([role=button])': {
      // color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& [class^="border-"]': {
      borderColor: theme.palette.divider,
    },
    '& [class*="border-"]': {
      borderColor: theme.palette.divider,
    },
  },
})

type Settings = {
  customScrollbars: boolean
  theme: {
    [key: string]: string
  }
  layout: {
    style: string
    config: {
      footer: {
        display: boolean
        style: string
        position: string
      }
      mode: string
      navbar: {
        display: boolean
        folded: boolean
        position: string
      }
      scroll: string
      toolbar: {
        display: boolean
        style: string
        position: string
      }
    }
  }
}

type Props = {
  isDevStage: boolean
  location: { pathname: string; search: string }
  routes: {
    auth: string[]
    component: React.Component
    path: string
    settings: any
  }[]
  defaultSettings: Settings
  settings: Settings
  setSettings: (settings: Settings) => void
  resetSettings: () => void
  classes?: any
}

// @ts-ignore
@withStyles(styles, { withTheme: true })
class Layout extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props)
    this.routeSettingsCheck()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
      this.routeSettingsCheck()
    }
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.isDevStage) return

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  routeSettingsCheck = () => {
    const matched = matchRoutes(this.props.routes, this.props.location.pathname)[0]

    if (matched && matched.route.settings) {
      const routeSettings = _.merge({}, this.props.defaultSettings, matched.route.settings)
      if (!_.isEqual(this.props.settings, routeSettings)) {
        this.props.setSettings(_.merge({}, routeSettings))
      }
    } else {
      if (!_.isEqual(this.props.settings, this.props.defaultSettings)) {
        this.props.resetSettings()
      }
    }
  }

  render() {
    const { settings, classes } = this.props
    // console.warn('FuseLayout:: rendered');
    const Layout = FuseLayouts[settings.layout.style].component
    return <Layout className={classes.root} {...this.props} />
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSettings: Actions.setSettings,
      setDefaultSettings: Actions.setDefaultSettings,
      resetSettings: Actions.resetSettings,
      navbarOpenFolded: Actions.navbarOpenFolded,
      navbarCloseFolded: Actions.navbarCloseFolded,
      navbarOpenMobile: Actions.navbarOpenMobile,
      navbarCloseMobile: Actions.navbarCloseMobile,
    },
    dispatch
  )
}

function mapStateToProps({ fuse }) {
  return {
    defaultSettings: fuse.settings.defaults,
    settings: fuse.settings.current,
    navbar: fuse.navbar,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
