import { Notification } from 'src/gql/queries/getNotifications'

export const ADD_NOTIFICATIONS_DATA = 'ADD_NOTIFICATIONS_DATA'

export type NotificationActions = {
  type: string
  payload: {
    notifications: Notification[]
  }
}

type Dispatch = (action: NotificationActions) => void

export const addNotificationsData = (notifications: Notification[]) => (dispatch: Dispatch) =>
  dispatch({
    type: ADD_NOTIFICATIONS_DATA,
    payload: { notifications },
  })
