import * as React from 'react'

import store from 'src/store'

const langs = [
  'en-us',
  'da',
  'hr',
  'sv',
  'el',
  'sq',
  'ar',
  'bg',
  'zh',
  'nl',
  'en-gb',
  'et',
  'en-au',
  'en-in',
  'en-gb-wls',
  'cy',
  'cs',
  'is',
  'fr-ca',
  'fi',
  'fr-fr',
  'ka',
  'de',
  'it',
  'he',
  'hi',
  'hu',
  'id',
  'ja',
  'ko',
  'lv',
  'no',
  'pl',
  'pt-pt',
  'ro',
  'ru',
  'sr',
  'sk',
  'sl',
  'es-es',
  'es-mx',
  'ta',
  'th',
  'tr',
  'uk',
  'vi',
]

export const ALL_LANG_NAMES = {
  da: {
    'en-us': 'Danish',

    da: 'Dansk',

    hr: 'Danski',

    sv: 'Danska',
  },
  'en-us': 'English (United States)',
  dk: {
    'en-us': 'Danish',
    da: 'Dansk',
    hr: 'Danski',
    sv: 'Danska',
  },
  hr: {
    'en-us': 'Croatian',
    da: 'Kroatisk',
    hr: 'Hrvatski',
    sv: 'Kroatisk',
  },
  sv: {
    'en-us': 'Swedish',
    da: 'Svensk',
    hr: 'švedski',
    sv: 'Svenska',
  },
  sq: {
    'en-us': 'Albanian',
  },
  ar: {
    'en-us': 'Arabic',
  },
  bg: {
    'en-us': 'Bulgarian',
  },
  zh: {
    'en-us': 'Chinese',
  },
  nl: {
    'en-us': 'Dutch',
  },
  'en-gb': 'English (United Kingdom)',
  'en-au': 'English (Australian)',
  'en-in': {
    'en-us': 'English (Indian)',
  },
  'en-gb-wls': {
    'en-us': 'English (Welsh)',
  },
  cy: {
    'en-us': 'Welsh',
  },
  el: {
    'en-us': 'Greek',
  },
  cs: {
    'en-us': 'Czech',
  },
  'fr-ca': {
    'en-us': 'French (Canadian)',
  },
  is: {
    'en-us': 'Icelandic',
  },
  et: {
    'en-us': 'Estonian',
  },
  fi: {
    'en-us': 'Finnish',
  },
  'fr-fr': {
    'en-us': 'French',
  },
  ka: {
    'en-us': 'Georgian',
  },
  de: {
    'en-us': 'German',
  },
  he: {
    'en-us': 'Hebrew',
  },
  hi: {
    'en-us': 'Hindi',
  },
  hu: {
    'en-us': 'Hungarian',
  },
  id: {
    'en-us': 'Indonesian',
  },
  it: {
    'en-us': 'Italy',
  },
  ja: {
    'en-us': 'Japanese',
  },
  ko: {
    'en-us': 'Korean',
  },
  lv: {
    'en-us': 'Latvian',
  },
  no: {
    'en-us': 'Norwegian',
  },
  pl: {
    'en-us': 'Polish',
  },
  'pt-pt': {
    'en-us': 'Portuguese',
  },
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  sk: 'Slovak',
  sl: 'Slovenian',
  'es-es': 'Spanish (European)',
  'es-mx': 'Spanish (Mexican)',
  ta: 'Tamil',

  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  vi: 'Vietnamese',
}

export type Translate = (key: string) => React.Component | string

export const getTranslation = (lang: string) => (key: string) => {
  const data = store.getState().auth.language[lang] || store.getState().auth.language['en-us']

  if (!data[key]) {
    return key
  }

  const translation = data[key] && data[key][lang] ? data[key][lang] : data[key]['en-us']

  return translation ? translation : data[key]
}

export const getLanguages = () => langs
