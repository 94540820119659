import { setUserData } from './user.actions'
import { signIn } from '../../cognito/organizationApi'

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export function submitLogin({ username, password }, canClearViewOnlyMode = true) {
  return (dispatch, getState) => {
    signIn(username.toLocaleLowerCase(), password)
      .then(res => {
        if (canClearViewOnlyMode) {
          localStorage.removeItem('isViewOnlyMode')
        }

        dispatch(
          setUserData({
            role: 'member',
            data: {
              token: res.token,
              displayName: username,
              photoUrl: 'assets/images/avatars/profile.jpg',
              email: username.toLocaleLowerCase(),
            },
          })
        )
        dispatch({
          type: LOGIN_SUCCESS,
        })
      })
      .catch(err => {
        const payload =
          err.message === 'User does not exist.'
            ? {
                username: err.message,
                password: null,
              }
            : {
                username: null,
                password: err.message,
              }

        return dispatch({
          type: LOGIN_ERROR,
          payload,
        })
      })
  }
}
