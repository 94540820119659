import { includes, pickBy, keys } from 'lodash'

import { TOGGLE_FILTER } from '../actions/favoritesFilter'
import { FilterState, Filters } from '../types/filterVideos'

const initState: FilterState = {
  searchValue: '',
  isFiltered: false,
  filters: {
    authorEmail: [],
    status: [],
    type: [],
    channel: [],
    subChannel: [],
  },
}

const checkedIsFiltered = (filters: Filters) => {
  let flag = false
  Object.keys(filters).forEach(key => {
    if (filters[key].length) flag = true
  })
  return flag
}

export default (state: FilterState = initState, { type, payload }: any): FilterState => {
  switch (type) {
    case TOGGLE_FILTER:
      const filters = pickBy(
        {
          ...state.filters,
          subChannel: payload.key === 'channel' ? [] : state.filters.subChannel,
          [payload.key]: !state.filters[payload.key]
            ? [payload.filter]
            : !includes(state.filters[payload.key], payload.filter)
            ? [...state.filters[payload.key], payload.filter]
            : state.filters[payload.key].filter(f => f !== payload.filter),
        },
        (value, key) => includes(keys(initState.filters), key) || value.length
      )
      return {
        ...state,
        isFiltered: checkedIsFiltered(filters),
        filters,
      }

    default:
      return state
  }
}
