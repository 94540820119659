export default (theme: any) => ({
  notificationContainer: {
    outline: 'none',
    boxSizing: 'border-box',
    height: 642,
    overflow: 'hidden',
    width: 480,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderTop: 'none',
    flexDirection: 'column',
  },
  channelImg: {
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    width: 42,
    height: 42,
    maxWidth: '100%',
    color: '#000',
    marginRight: 16,
  },
  headerContainer: {
    padding: 0,
    border: 0,
    background: 'transparent',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    minHeight: 48,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
  },
  notificationText: {
    marginLeft: 16,
    fontSize: 16,
    color: '#030303',
    fontWeight: 400,
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 414,
  },
  settingsIcon: {
    marginRight: 8,
    fontSize: 24,
    marginTop: 'auto',
    marginBottom: 'auto',
    color: 'rgb(144, 144, 144);',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notificationAbstract: {
    marginBottom: 0,
    maxHeight: '12.6rem',
    overflow: 'hidden',
    fontSize: 13,
  },
  notification: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    marginRight: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f1f1f1',
    },
  },
  notificationTimer: {
    color: 'rgb(96, 96, 96);',
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '18px',
  },
  notificationBodyText: {
    color: 'rgb(3,3,3)',
    width: 'w-100',
  },
  bodyContainer: {
    height: 608,
    overflowY: 'overlay',
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 8,
        position: 'fixed',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 0,
    },
  },
  videoThumbnail: {
    margin: '0 16px',
    width: 86,
    height: 48,
    overflow: 'hidden',
  },
  unseenNotification: {
    backgroundColor: '#f0fdff',
  },
  notOpenedAlert: {
    borderRadius: '50%',
    background: 'blue',
    width: 12,
    height: 12,
    display: 'flex',
  },
  nonotificationContainer: {
    height: '50vh',
    marginTop: '30%',
  },
  seeAllText: {
    marginLeft: 0,
    fontSize: 16,
    color: '#030303',
    fontWeight: 400,
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '95%',
    textAlign: 'right',
  },
  footerContainer: {
    padding: 0,
    paddingBottom: 10,
    border: 0,
    background: 'transparent',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    minHeight: 48,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
  },
  notificationBadgeWrapper: {
    position: 'relative',
    marginLeft: 25,
    marginTop: -14,
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    width: 20,
    height: 20,
    alignItems: 'center',
  },
})
