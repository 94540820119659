import * as React from 'react'
import { Query } from 'react-apollo'
import { localDataQuery, LocalData } from '../gql/localData'
import { ErrorDialog } from './reusable/ErrorDialog'

export const GlobalDialogs = props => (
  <Query {...localDataQuery()}>
    {({ data, client }: LocalData) => (
      <ErrorDialog
        open={data.errorDialog}
        message={data.errorMessage}
        onClose={() =>
          client.writeData({
            data: {
              errorDialog: false,
              errorMessage: '',
              errorCode: '',
            },
          })
        }
      />
    )}
  </Query>
)
