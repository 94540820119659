import gql from 'graphql-tag'
import { QueryResult } from 'react-apollo'

import VideoLink from 'src/domains/videoLinks'

export type GetVideoEmbed = QueryResult<{
  getFromEmbedRes: {
    url: string
    thumbnail: string
    lastVersion: number
    videoId: string
    language: string
    publicEmbed: boolean
    masterLanguage: string
    renderingData: {
      [keyLang: string]: { videoLinks: { start: number; link: VideoLink }[] }
    }
  }
}>

export const getVideoEmbed = (key: string, orgId: string) => ({
  query: gql`
    query($key: String!, $orgId: String!) {
      getFromEmbedRes(key: $key, orgId: $orgId)
        @rest(type: "VideoEmbed", path: "/getVideoEmbed?key=:key&orgId=:orgId", endpoint: "query") {
        url
        thumbnail
        lastVersion
        videoId
        language
        publicEmbed
        channelId
        createdAt
        duration
        name
        description
        abstract
        type
        masterLanguage
        channelName
        authorEmail
        renderingData
      }
    }
  `,
  variables: { key, orgId },
})
