import gql from 'graphql-tag'
import { QueryResult } from 'react-apollo'

export type Channel = {
  id: string
  parent: string
  name: string
  abstract: string
  description: string
  owner: string
  icon: string
  createdAt: string
  shared: boolean
  canUpdate: boolean
  backgroundImage: string
  backgroundColor: string
  videosCount: number
  tracksCount: number
  sharedWith: { shareId: string; destOrgId: string }[]
  owners: string[]
  channelIcon?: string
  status: string
  isPersonalChannel?: boolean
  tags?: {
    [key: string]: string[]
  }
}

export type ListChannels = QueryResult<{
  channels: Channel[]
}>

export const listChannelsQuery = (
  action: 'readChannel' | 'updateChannel' | 'createVideo' | 'createTrack',
  fetchAdditionalInfo = false
) => ({
  query: gql`
    query listChannels($tubeId: String!, $action: String!, $fetchAdditionalInfo: String!) {
      channels(id: $tubeId, action: $action, fetchAdditionalInfo: $fetchAdditionalInfo)
        @rest(
          type: "[ChannelList]"
          path: "/listchannels?tubeId=:id&action=:action&fetchAdditionalInfo=:fetchAdditionalInfo"
          endpoint: "query"
        ) {
        parent
        id
        name
        abstract
        description
        icon
        canUpdate
        videosCount
        tracksCount
        createdAt
        shared
        sharedWith
        channelIcon
        backgroundImage
        backgroundColor
        owners
        owner
        status
        isPersonalChannel
        tags
      }
    }
  `,
  variables: {
    action,
    fetchAdditionalInfo,
    tubeId: localStorage.getItem('tubeId'),
  },
})

export const LIST_CHANNELS_QUERY = gql`
  query listChannels($tubeId: String!, $action: String!, $fetchAdditionalInfo: String!) {
    channels(id: $tubeId, action: $action, fetchAdditionalInfo: $fetchAdditionalInfo)
      @rest(
        type: "[ChannelList]"
        path: "/listchannels?tubeId=:id&action=:action&fetchAdditionalInfo=:fetchAdditionalInfo"
        endpoint: "query"
      ) {
      parent
      id
      name
      abstract
      description
      icon
      canUpdate
      videosCount
      tracksCount
      createdAt
      shared
      sharedWith
      channelIcon
      backgroundImage
      backgroundColor
      owners
      status
      tags
    }
  }
`
