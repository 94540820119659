export default (theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '@media(max-width: 1024px)': {
      display: 'none !important',
    },
    backgroundColor: 'white',
  },
  seperator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
  centerContainer: {
    minWidth: '500px !important',
  },
  logo: {
    paddingLeft: 12,
    paddingTop: 5,
    maxHeight: 55,
    '@media(max-width: 1200px)': {
      maxHeight: 48,
      paddingLeft: 12,
    },
  },
  userMenu: {
    '@media(max-width: 1200px)': {
      padding: '3px',
      marginLeft: -10,
    },
  },
  channelImg: {
    width: 30,
    height: 30,
    maxWidth: '100%',
  },
  hamburgerWrapper: {
    paddingLeft: 20,
    paddingRight: 5,
  },
  hamburger: {
    cursor: 'pointer',
  },
  search: {
    border: '1px solid #c4c4c4',
    borderRadius: 100,
    margin: 10,
    padding: 0,
    width: '85%',
  },
  chatIcon: {
    padding: 0,
    minWidth: 30,
    marginLeft: -10,
  },
  demoButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '1.4rem !important',
    textTransform: 'none',
    padding: '5px 10px',
    minHeight: 'unset',
    marginLeft: '20px !important',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}`,
      color: `#fff !important`,
    },
  },
  loginButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.main}`,
    fontSize: '1.4rem !important',
    textTransform: 'none',
    padding: '5px 10px',
    minHeight: 'unset',
    marginLeft: '20px !important',
    color: `#fff !important`,

    '&:hover': {
      backgroundColor: `transparent`,
      color: `#000 !important`,
    },
  },

  centerContainter: {
    width: '35%',
  },
  notificationIcon: {
    minWidth: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    '@media(max-width: 1200px)': {
      marginLeft: -10,
    },
  },
  notificationCountBadge: {
    backgroundColor: 'transparent',
    top: 9,
    right: -10,
    width: 24,
    height: 24,
    display: 'flex',
    fontWeight: 500,
    zIndex: 1,
    position: 'absolute',
  },
  langSelect: {
    overflowY: 'auto',
    position: 'absolute',
    maxHeight: 490,
    '&::-webkit-scrollbar': {
      width: 8,
      position: 'fixed',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
  langItem: {
    borderBottom: '1px solid #e5e1e1',
  },

  success: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  listItem: {
    color: 'inherit',
    paddingTop: '8px',
    paddingBottom: '8px',
    ' & .material-icons ': {
      color: '#000',
    },
  },
  profilePictureDefault: {
    width: 40,
    height: 40,
    margin: '20px auto',
    borderRadius: '50%',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    backgroundSize: '100%',
    textAlign: 'center' as 'center',
  },
  profilePicText: {
    color: theme.palette.primary.main,
    fontSize: 20,
    left: '50%',
    top: '50%',
    display: 'block',
    transform: 'translate(0%,50%)',
  },
  toolbarText: {
    '& span': {
      fontSize: '1.4rem',
      color: '#000',
    },
  },
  toolbarLink: {
    fontSize: '1.4rem',
    color: '#000',
    textDecoration: 'none',
  },
  toolbarTextActive: {
    '& span': {
      fontSize: '1.4rem',
      color: '#fff',
    },
  },
})
