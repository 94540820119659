import gql from 'graphql-tag'

export const ADD_COMMENT = gql`
  mutation addCommentCommand($input: REST!) {
    res(input: $input) @rest(type: "AddTaskMessage", path: "/taskCommand", method: "POST", endpoint: "command") {
      id
    }
  }
`

export const EMAIL_CHAT_ABOUT_COMMENT = gql`
  mutation notifyComment($input: REST!) {
    res(input: $input) @rest(type: "NotifyComment", path: "/taskCommand", method: "POST", endpoint: "command") {
      id
    }
  }
`

export const COMPLETE_TASK = gql`
  mutation completeTask($input: REST!) {
    res(input: $input) @rest(type: org, path: "/organizationCommand", method: "POST", endpoint: "command") {
      id
    }
  }
`

export const CREATE_SUPPORT_TASK = gql`
  mutation createSupportTask($input: REST!) {
    res(input: $input) @rest(type: "createSupportTask", path: "/userCommand", method: "POST", endpoint: "command") {
      taskId
    }
  }
`

export const CHANGE_TASK_CHANNEL = gql`
  mutation changeTaskChannel($input: REST!) {
    res(input: $input) @rest(type: "ChangeTaskChannel", path: "/taskCommand", method: "POST", endpoint: "command") {
      taskId
    }
  }
`

export type AddCommentCommandVariables = {
  type: 'add' | 'update' | 'archive'
  payload: {
    videoId: string
    content?: string
    commentId?: string
    parentCommentId?: string
  }
}

export const addCommentCommandMutation = () => ({
  mutation: gql`
    mutation addCommentCommand($input: REST!) {
      res(input: $input) @rest(type: "AddComment", path: "/commentCommand", method: "POST", endpoint: "command") {
        id
      }
    }
  `,
})

export const addCommentCommandVariables = (data: AddCommentCommandVariables) => ({
  variables: {
    input: data,
  },
  refetchQueries: ['listComments'],
})
