import * as Actions from '../actions/lang.actions'

import locals from 'src/translation/locals/lang.json'
import { ALL_LANG_NAMES } from 'src/translation/index'

const initialState = {
  'en-us': {
    ...locals,
    ...ALL_LANG_NAMES,
  },
}

const lang = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_LANG: {
      return {
        ...state,
        [action.payload.lang]: {
          ...locals,
          ...ALL_LANG_NAMES,
          ...action.payload.data,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default lang
