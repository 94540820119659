import { Avatar, Icon, withStyles } from '@material-ui/core'
import * as React from 'react'
import { ReduxState } from 'src/store/reducers'
import { getTranslation } from 'src/translation'
import { getInitials } from 'src/utils/common'
import { connect } from 'react-redux'
import { compose } from 'underscore'
import { Channel } from 'src/gql/getChannel'

type Props = {
  tubeColor: string
  tubeIcon: string
  classes: {
    channelImg: string
    avatar: string
  }
  channel: Channel
  size: 'small' | 'medium' | 'large'
}

const sizeStyles = {
  small: {
    width: '23px',
    height: '23px',
    fontSize: '10px',
  },
  medium: {
    width: '30px',
    height: '30px',
    fontSize: '18px',
  },
  large: {
    width: '36px',
    height: '36px',
    fontSize: '24px',
  },
}

const ChannelAvatar = (props: Props) => {
  const { channel, classes, tubeColor, tubeIcon, size } = props
  const channelIcon = channel.channelIcon || channel.icon || channel.logo
  const sizeStyle = sizeStyles[size]

  const defaultAvatarStyles = {
    backgroundColor: channelIcon ? '#fff' : tubeColor,
    ...sizeStyle,
  }

  if (channelIcon) {
    return <Avatar style={defaultAvatarStyles} src={channelIcon} className={classes.avatar} />
  }

  if (channel.isPersonalChannel) {
    return (
      <Icon
        style={{
          color: tubeColor,
        }}
      >
        account_circle
      </Icon>
    )
  }

  if (tubeIcon) {
    return <img src={tubeIcon} className={classes.channelImg} style={sizeStyle} />
  }

  return (
    <Avatar src={channelIcon} style={defaultAvatarStyles} className={classes.avatar}>
      {getInitials(channel.name)}
    </Avatar>
  )
}

const styles = () => ({
  channelImg: {
    width: '100%',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative' as 'relative',
    borderRadius: '100%',
    color: 'white',
    justifyContent: 'center' as 'center',
    background: '#fffffff5',
    border: '1px solid #e0e0e0',
  },
})

function mapStateToProps(state: ReduxState, ownProps) {
  return {
    ...ownProps,
    translate: getTranslation(state.translation.lang),
    tubeColor: state.auth.tube.color,
    tubeIcon: state.auth.tube.icon,
  }
}
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(ChannelAvatar)
