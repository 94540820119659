import * as React from 'react'
import { useMemo } from 'react'
import { Icon, ListSubheader, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { ReduxState } from 'src/store/reducers'
import { getTranslation, Translate } from 'src/translation'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import { styles } from './styles'
import Item from './Item'
import { Channel } from 'src/gql/listChannels'

const DEFAULT_CHANNEL_COUNT_TO_SHOW = 5

type MappedProps = {
  classes?: any
  translate: Translate
  tubeColor: string
  channels: Channel[]
  tubeIcon?: string
}
type ChannelProps = {
  listTitle?: string
  filterFunction?: any
}
type Props = MappedProps & ChannelProps

const ChannelList = (props: Props) => {
  const { listTitle, filterFunction, classes, translate, channels, tubeColor, tubeIcon } = props

  const [showMoreChannels, setShowMoreChannels] = React.useState(false)
  const [selectedChannelsIds, setSelectedChannelsIds] = React.useState([])
  const getChannels = (parentId: string) =>
    sortBy((channels || []).filter(channel => channel.parent === parentId).filter(filterFunction), 'name')

  const channelsToShow = useMemo(() => {
    // const rootChannelsToShow = getChannels(localStorage.getItem('tubeId'))
    return (channels || []).filter(filterFunction)
    // .filter(item => item.parent !== localStorage.getItem('tubeId'))
    // .filter(item => {
    //   const hasParentInList = channels.find(channel => channel.id === item.parent)
    //   return !hasParentInList
    // })

    // return [...rootChannelsToShow, ...channelWithNoParent]
  }, [channels])

  if (channelsToShow.length === 0) return null

  return (
    <div className={classNames(classes.mobileHidden, classes.groupSeparator)}>
      <ListSubheader
        disableSticky={true}
        className={classNames(classes.heading, classes.headingPadding)}
        component="div"
      >
        {listTitle}
      </ListSubheader>
      {channelsToShow
        .slice(0, showMoreChannels ? channelsToShow.length : DEFAULT_CHANNEL_COUNT_TO_SHOW)
        .map(channel => (
          <Item
            tubeIcon={tubeIcon}
            key={channel.id}
            classes={classes}
            channel={channel}
            translate={translate}
            selectedChannelsIds={selectedChannelsIds}
            setSelectedChannelsIds={setSelectedChannelsIds}
            tubeColor={tubeColor}
            getChannels={getChannels}
          />
        ))}
      {channelsToShow.length >= DEFAULT_CHANNEL_COUNT_TO_SHOW && (
        <div
          className={classes.showMoreButtonWrapper}
          onClick={() => {
            setShowMoreChannels(!showMoreChannels)
          }}
        >
          {showMoreChannels ? (
            <>
              <Icon className={classes.menuClass}>keyboard_arrow_up</Icon>
              <span className={classes.menuText}>{translate('showLess')}</span>
            </>
          ) : (
            <>
              <Icon className={classes.menuClass}>keyboard_arrow_down</Icon>
              <span className={classes.menuText}>
                {translate('show')} {channelsToShow.length - DEFAULT_CHANNEL_COUNT_TO_SHOW} {translate('more')}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  translate: getTranslation(state.translation.lang),
  tubeColor: state.auth.tube.color,
  channels: state.channel.channelList,
  tubeIcon: state.auth.tube.icon,
})

// @ts-ignore
export default withStyles(styles)<ChannelProps>(connect(mapStateToProps)(ChannelList))
