export const CHANGE_LANG = '[TRANSLATION] CHANGE LANGUAGE'

export type TranslationAction = {
  type: string
  payload: {
    lang?: string
    masterLanguage?: string
    settings?: any
  }
}

export const changeLang = (lang: string): TranslationAction => ({
  type: CHANGE_LANG,
  payload: { lang },
})
