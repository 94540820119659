import { ReduxState } from 'src/store/reducers'
import { includes } from 'lodash'

/**
 * Check if a tasks feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isExternalShareFeatureEnabled = (state: ReduxState, channelId: string) => {
  const externalShareFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'externalShare')

  return (
    externalShareFeature &&
    externalShareFeature.configuration !== 'deactivated' &&
    (externalShareFeature.configuration === 'tube' ||
      includes(externalShareFeature.activatedIn, state.auth.tube.id) ||
      includes(externalShareFeature.activatedIn, channelId))
  )
}

/**
 * Check if a tasks feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isTasksFeatureEnabled = (state: ReduxState, channelId: string) => {
  const tasksFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'tasks')

  return (
    tasksFeature &&
    tasksFeature.configuration !== 'deactivated' &&
    (tasksFeature.configuration === 'tube' ||
      includes(tasksFeature.activatedIn, state.auth.tube.id) ||
      includes(tasksFeature.activatedIn, channelId))
  )
}

/**
 * Check if a tasks chat feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isChatFeatureEnabled = (state: ReduxState, channelId: string) => {
  const chatFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'tasks-chat')

  return (
    chatFeature &&
    chatFeature.configuration !== 'deactivated' &&
    (chatFeature.configuration === 'tube' ||
      includes(chatFeature.activatedIn, state.auth.tube.id) ||
      includes(chatFeature.activatedIn, channelId))
  )
}

/**
 * Note: Notifications feature is only checked to be enabbled on a tube. Not in a chanel
 *
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isNotificationsFeatureEnabled = (state: ReduxState) => {
  const notificationsFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'notifications')

  return notificationsFeature && notificationsFeature.configuration !== 'deactivated'
}

/**
 * Note: User profile feature is only checked to be enabbled on a tube. Not in a chanel
 *
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isUserProfileFeatureEnabled = (state: ReduxState) => {
  const userProfileFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'userProfile')

  return userProfileFeature && userProfileFeature.configuration !== 'deactivated'
}

/**
 * Check if a new channel layout feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isNewChannelLayoutFeatureEnabled = (state: ReduxState) => {
  const newChannelLayoutFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'newChannelLayout')

  return (
    newChannelLayoutFeature &&
    newChannelLayoutFeature.configuration !== 'deactivated' &&
    (newChannelLayoutFeature.configuration === 'tube' ||
      includes(newChannelLayoutFeature.activatedIn, state.auth.tube.id))
  )
}

/**
 * Check if a new channel home page  layout feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isNewChannelHomePageLayoutFeatureEnabled = (state: ReduxState, channelId: string) => {
  const newChannelHomePageLayoutFeature = state.auth.organization.featureFlags.find(
    f => f.featureId === 'newChannelHomePageLayout'
  )

  return (
    newChannelHomePageLayoutFeature &&
    newChannelHomePageLayoutFeature.configuration !== 'deactivated' &&
    (newChannelHomePageLayoutFeature.configuration === 'tube' ||
      includes(newChannelHomePageLayoutFeature.activatedIn, state.auth.tube.id) ||
      includes(newChannelHomePageLayoutFeature.activatedIn, channelId))
  )
}

/**
 * Check if a new web recorder feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isNewWebRecorderFeatureEnabled = (state: ReduxState, channelId?: string) => {
  const newWebRecorderFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'webRecorder')

  return (
    newWebRecorderFeature &&
    newWebRecorderFeature.configuration !== 'deactivated' &&
    (newWebRecorderFeature.configuration === 'tube' ||
      includes(newWebRecorderFeature.activatedIn, state.auth.tube.id) ||
      includes(newWebRecorderFeature.activatedIn, channelId))
  )
}

/**
 * Check if a copy external video feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isCopyInternalPlaylistFeatureEnabled = (state: ReduxState, channelId: string) => {
  const copyInternalPlaylistFeature = state.auth.organization.featureFlags.find(
    f => f.featureId === 'copyInternalPlaylist'
  )

  return (
    copyInternalPlaylistFeature &&
    copyInternalPlaylistFeature.configuration !== 'deactivated' &&
    (copyInternalPlaylistFeature.configuration === 'tube' ||
      includes(copyInternalPlaylistFeature.activatedIn, state.auth.tube.id) ||
      includes(copyInternalPlaylistFeature.activatedIn, channelId))
  )
}

/**
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isCopyExternalPlaylistFeatureEnabled = (state: ReduxState, channelId: string) => {
  const copyExternalPlaylistFeature = state.auth.organization.featureFlags.find(
    f => f.featureId === 'copyExternalPlaylist'
  )

  return (
    copyExternalPlaylistFeature &&
    copyExternalPlaylistFeature.configuration !== 'deactivated' &&
    (copyExternalPlaylistFeature.configuration === 'tube' ||
      includes(copyExternalPlaylistFeature.activatedIn, state.auth.tube.id) ||
      includes(copyExternalPlaylistFeature.activatedIn, channelId))
  )
}

/**
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isCopyInternalVideoFeatureEnabled = (state: ReduxState, channelId?: string) => {
  const copyInternalVideoFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'copyInternalVideo')

  return (
    copyInternalVideoFeature &&
    copyInternalVideoFeature.configuration !== 'deactivated' &&
    (copyInternalVideoFeature.configuration === 'tube' ||
      includes(copyInternalVideoFeature.activatedIn, state.auth.tube.id) ||
      includes(copyInternalVideoFeature.activatedIn, channelId))
  )
}

/**
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isCopyExternalVideoFeatureEnabled = (state: ReduxState, channelId?: string) => {
  const copyExternalVideoFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'copyExternalVideo')

  return (
    copyExternalVideoFeature &&
    copyExternalVideoFeature.configuration !== 'deactivated' &&
    (copyExternalVideoFeature.configuration === 'tube' ||
      includes(copyExternalVideoFeature.activatedIn, state.auth.tube.id) ||
      includes(copyExternalVideoFeature.activatedIn, channelId))
  )
}

/**
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isEnableRequestDemoFeatureEnabled = (state: ReduxState, channelId?: string) => {
  const copyExternalVideoFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'enableRequestDemo')

  return (
    copyExternalVideoFeature &&
    copyExternalVideoFeature.configuration !== 'deactivated' &&
    (copyExternalVideoFeature.configuration === 'tube' ||
      includes(copyExternalVideoFeature.activatedIn, state.auth.tube.id) ||
      includes(copyExternalVideoFeature.activatedIn, channelId))
  )
}

/**
 * Check if channel tag feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isChannelTagsFeatureEnabled = (state: ReduxState, channelId?: string) => {
  const channelTagsFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'channelTags')

  return (
    channelTagsFeature &&
    channelTagsFeature.configuration !== 'deactivated' &&
    (channelTagsFeature.configuration === 'tube' ||
      includes(channelTagsFeature.activatedIn, state.auth.tube.id) ||
      includes(channelTagsFeature.activatedIn, channelId))
  )
}

/**
 * Check if a assisted search feature is enabled or not
 *
 * @param {ReduxState} state
 * @param {string} channelId
 * @returns {boolean}
 */
export const isAssistedSearchFeatureEnabled = (state: ReduxState) => {
  const assistedSearchFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'assistedSearch')

  return (
    assistedSearchFeature &&
    assistedSearchFeature.configuration !== 'deactivated' &&
    (assistedSearchFeature.configuration === 'tube' || includes(assistedSearchFeature.activatedIn, state.auth.tube.id))
  )
}
