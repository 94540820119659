export const mainApi = (isDevStage: boolean) => ({
  maintenance: false,
  query: isDevStage ? 'https://api.launchpeople.com/dev-query' : 'https://api.launchpeople.com/v1-query',
  command: isDevStage ? 'https://api.launchpeople.com/dev-command' : 'https://api.launchpeople.com/v1-command',
  user: isDevStage ? 'https://api.launchpeople.com/dev-users' : 'https://api.launchpeople.com/v1-users',
  webSocket: isDevStage
    ? 'wss://p35aqosf13.execute-api.eu-west-1.amazonaws.com/dev/'
    : 'wss://n8sh5mokcj.execute-api.eu-west-1.amazonaws.com/prod/',
})

export const sentryDsn = 'https://d6942465b88a41aab5725142ba7881fa@o493520.ingest.sentry.io/5596531'
