import React from 'react'
import { Tooltip, withStyles } from '@material-ui/core'
import { ReduxState } from 'src/store/reducers'
import { Translate, getTranslation } from 'src/translation'
import { compose } from 'underscore'
import { connect } from 'react-redux'

type Props = {
  classes: any
  translate: Translate
  title: string
}

const FormElementHelperTooltip = (props: Props) => {
  const { title, classes, translate } = props

  return (
    <Tooltip
      placement="right"
      classes={{
        tooltip: classes.tooltip,
      }}
      title={translate(title)}
    >
      <span className={classes.infoIcon}>i</span>
    </Tooltip>
  )
}

const styles = theme => ({
  tooltip: {
    backgroundColor: '#e5e5e5',
    color: '#000',
    fontSize: '12px',
  },
  infoIcon: {
    backgroundColor: '#e5e5e5',
    padding: '1px 6px',
    marginLeft: '5px',
    borderRadius: '100%',
    fontSize: '1.3rem',
    color: '#000',
    border: '1px solid #eeee',
  },
})
function mapStateToProps(state: ReduxState, ownProps) {
  return {
    ...ownProps,
    translate: getTranslation(state.translation.lang),
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(FormElementHelperTooltip)
