// actions.ts
import { Action } from 'redux'

export interface UploadModalAction extends Action {
  type: 'SET_UPLOAD_MODAL'
  payload: {
    videoId?: string
    isUploading?: boolean
    file?: any
    isModalOpen?: boolean
    version?: number
    refetch?: any
    uploadType?: string
    lang?: string
    accessTaskId?: string
  }
}

export type UploadModalActionTypes = UploadModalAction

export const setUploadModal = ({
  isModalOpen,
  file,
  videoId,
  isUploading,
  version,
  refetch,
  uploadType,
  lang,
  accessTaskId,
}): UploadModalAction => {
  return {
    type: 'SET_UPLOAD_MODAL',
    payload: {
      isModalOpen,
      file,
      videoId,
      isUploading,
      version,
      refetch,
      uploadType,
      lang,
      accessTaskId,
    },
  }
}
