import gql from 'graphql-tag'
import { omit } from 'underscore'
import { listChannelsQuery } from './listChannels'
import { getChannelQuery } from './getChannel'

export type ChannelCommand =
  | {
      type: 'create'
      payload: {
        tubeId: string
        parentChannelId?: string
        name: string
        abstract?: string
        description?: string
        logo?: string
        icon?: string
        backgroundImage?: string
        backgroundColor?: string
      }
    }
  | {
      type: 'defineTracksOrder'
      payload: {
        channelId: string
        trackIds: string[]
      }
    }
  | {
      type: 'archive'
      payload: {
        channelId: string
      }
    }
  | {
      type: 'removeUserShareAccess'
      payload: {
        channelId: string
        shareId: string
        email?: string
        domain?: string
      }
    }
  | {
      type: 'updatePromotionVideo'
      payload: {
        channelId: string
        videoId: string
      }
    }
  | {
      type: 'onUpdateMandatoryTrainingSetting'
      payload: {
        channelId: string
        channelMandatoryTrainingSetting: boolean
      }
    }
  | {
      type: 'onUpdateExternalShareShareSetting'
      payload: {
        channelId: string
        isChannelExternalShareEnabled: boolean
      }
    }
  | {
      type: 'onUpdateAllowExternalTaskToBeReassignedSetting'
      payload: {
        channelId: string
        canExternalTasksBeAssignedToChannel: boolean
      }
    }
  | {
      type: 'onUpdateExternalShareDomains'
      payload: {
        channelId: string
        domains: string[]
      }
    }
  | {
      type: 'copyExternalPlaylist'
      payload: {
        playlistId: string
        channelId: string
        channelIdToCopyTo: string
        playlistIdToCopyTo: string
      }
    }
  | {
      type: 'copyInternalPlaylist'
      payload: {
        playlistId: string
        channelId: string
        channelIdToCopyTo: string
        playlistIdToCopyTo: string
      }
    }
  | {
      type: 'removeExternalPlaylist'
      payload: {
        playlistId: string
        channelId: string
        channelIdCopiedTo: string
        playlistIdCopiedTo: string
      }
    }
  | {
      type: 'removeInternalPlaylist'
      payload: {
        playlistId: string
        channelId: string
        channelIdCopiedTo: string
        playlistIdCopiedTo: string
      }
    }
  | {
      type: 'onUpdateFeaturedVideosForRecommendation'
      payload: {
        channelId: string
        videoIds: string[]
        featuredVideoRecommendationGenerationOn: string
      }
    }
  | {
      type: 'notifyChannelUsers'
      payload: {
        channelId: string
        userIds: string[]
        messageTitle: string
        messageBody: string
      }
    }
  | {
      type: 'copyExternalVideo'
      payload: {
        playlistId: string
        channelId: string
        videoId: string
        channelIdToCopyTo: string
        playlistIdToCopyTo: string
      }
    }
  | {
      type: 'copyInternalVideo'
      payload: {
        playlistId: string
        channelId: string
        videoId: string
        channelIdToCopyTo: string
        playlistIdToCopyTo: string
      }
    }
  | {
      type: 'removeInternalVideo'
      payload: {
        channelId: string
        playlistId: string
        videoId: string
        channelIdCopiedTo: string
        playlistIdCopiedTo: string
      }
    }
  | {
      type: 'removeExternalVideo'
      payload: {
        channelId: string
        playlistId: string
        videoId: string
        channelIdCopiedTo: string
        playlistIdCopiedTo: string
      }
    }
  | {
      type: 'addChannelTag' | 'removeChannelTag'
      payload: {
        channelId: string
        tagGroup: string
        tagValue: string
      }
    }

export const ChannelCommandMutation = () => ({
  mutation: gql`
    mutation ChannelCommand($input: REST!) {
      res(input: $input) @rest(type: "Channel", path: "/channelCommand", method: "POST", endpoint: "command") {
        id
      }
    }
  `,
})

export const ChannelCommandVariables = (command: ChannelCommand) => ({
  variables: {
    input: {
      type: command.type,
      payload: omit(command.payload, val => val === null || val === undefined),
    },
  },
  refetchQueries:
    command.type === 'onUpdateExternalShareShareSetting' ||
    command.type === 'onUpdateMandatoryTrainingSetting' ||
    command.type === 'onUpdateFeaturedVideosForRecommendation' ||
    command.type === 'onUpdateExternalShareDomains'
      ? [getChannelQuery(command.payload.channelId, false)]
      : [listChannelsQuery('readChannel')],
})

export const CHANNEL_COMMAND_MUTATION = gql`
  mutation ChannelCommand($input: REST!) {
    res(input: $input) @rest(type: "Channel", path: "/channelCommand", method: "POST", endpoint: "command") {
      id
    }
  }
`
