import { includes } from 'lodash'
import { setUserData } from '../actions/user.actions'
import { LOGIN_ERROR } from '../actions/login.actions'
import { signUp, confirmRegistration } from '../../cognito/organizationApi'

export const REGISTER_ERROR = 'REGISTER_ERROR'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_CONFIRM = 'REGISTER_CONFIRM'

export function submitRegister({ email, orgId, password }) {
  return dispatch => {
    signUp(email.toLocaleLowerCase(), password, orgId)
      .then(res => {
        if (!res.userConfirmed) {
          dispatch({
            type: REGISTER_CONFIRM,
          })
          return
        }
        dispatch(
          setUserData({
            role: 'member',
            data: {
              token: res.token,
              displayName: email,
              photoUrl: 'assets/images/avatars/profile.jpg',
              email: email.toLocaleLowerCase(),
            },
          })
        )
        dispatch({
          type: REGISTER_SUCCESS,
        })
      })
      .catch(err =>
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            email: err.message,
            password: includes(err.message, 'password') ? err.message : '',
          },
        })
      )
  }
}

export function submitRegisterConfirmation({ email, password, code, redirectChannelId, redirectVideoId, redirectUrl }) {
  return dispatch => {
    confirmRegistration(email, password, code)
      .then(async res => {
        dispatch(
          setUserData({
            role: 'member',
            data: {
              token: res.token,
              displayName: email,
              photoUrl: 'assets/images/avatars/profile.jpg',
              email: email,
            },
          })
        )

        await dispatch({
          type: REGISTER_SUCCESS,
        })

        if (!!redirectUrl) {
          window.location.href = redirectUrl
        }

        if (!!redirectChannelId) {
          window.location.href = `/channel/${redirectChannelId}/home`
        }

        if (!!redirectVideoId) {
          window.location.href = `/video/${redirectVideoId}`
        }
      })
      .catch(err =>
        dispatch({
          type: LOGIN_ERROR,
          payload: { code: err.message },
        })
      )
  }
}
