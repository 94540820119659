import gql from 'graphql-tag'
import { QueryResult } from 'react-apollo'

export const initialState = {
  errorDialog: false,
  errorCode: '',
  errorMessage: '',
}

export const localResolvers = {}

export type LocalData = QueryResult<typeof initialState>

export const localDataQuery = () => ({
  query: gql`
    query {
      errorDialog @client
      errorCode @client
      errorMessage @client
    }
  `,
})
