import { ADD_NOTIFICATIONS_DATA } from 'src/store/actions/notifications.actions'

const initialState = {
  data: null,
}

const notificationsReducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_NOTIFICATIONS_DATA:
      return {
        ...state,
        data: payload.notifications.data,
      }

    default:
      return state
  }
}

export default notificationsReducer
