import 'videojs-contrib-dash'
import * as React from 'react'
import { chunk, includes, uniq } from 'lodash'
import videojs from 'video.js'

import 'video.js/dist/video-js.css'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Mutation, Query } from 'react-apollo'
import purple from '@material-ui/core/colors/purple'
import { withStyles } from '@material-ui/core/styles'
import { Card, CircularProgress, Icon, IconButton } from '@material-ui/core'

import styles from './VideoPlayer.styles'
import { QueryS3Url } from './QueryS3Url'
import { Subtitle } from '../../gql/getVideo'
import { getTranslation } from '../../translation'
import VideoLink from 'src/domains/videoLinks'
import { langToVideoLinkPauseSoundMap, s3URLPrefix } from 'src/constants/common'
import { replaceTranslationSkipCharacters } from 'src/utils/common'
import { addUserAnalyticsVariables, addUserAnalyticsMutation } from '../../gql/addUserAnalytics'
import { getTranscodingStatusQuery, GetTranscodingStatus } from '../../gql/getVideoTranscodingStatus'
import VideoPlayerSwitchContainer from './video/VideoPlayerSwitchContainer'

videojs.registerComponent('autoPlaySwitch', VideoPlayerSwitchContainer)

type Subtitles = { language: string; chapters: Subtitle[] }[]

type Props = {
  source: string
  voiceOver?: Subtitles
  thumbnail?: string
  ribbonType?: string
  onPlaybackEvent?: (type: string, time?: number) => void
  classes?: any
  videoDuration?: string
  tubeColor?: string
  translate?: any
  linkedUrl?: string
  trackItems?: any
  subtitlesLang?: string
  links?: { start: number; link: VideoLink }[]
  isVideoLinksFeatureEnabled?: boolean
  mainLanguage?: string
  showTTLogo?: boolean
  videoId?: string
  shouldAutoPlay?: boolean
  disableAutoPlay?: boolean
  history?: {
    replace: (path: string) => void
    push: (path: string) => void
  }
  language?: string
  masterLanguage?: string
  autoPlay?: boolean
  isEmbedVideo?: boolean
  onLoaded?: () => void
  onVideoPlaybackEnded?: () => void
}

type State = {
  showRibbon: boolean
  videoErr: boolean
  videoPlaying: boolean
  fontSize: number
  currentBitrate: number
  subTitleLang?: string
  isVideoPlayEnded: boolean
  count: number
  subtitles: {
    enabled: boolean
    language: string
    current: number
    points: number[]
  }

  linkConfirmationToShow: { start: number; link: VideoLink } | null
  shownChapterLinkIds: number[]
}

type Bitrate = {
  bitrate: number
  height: number
  mediaType: string
  qualityIndex: number
  width: number
}

// @ts-ignore
@withStyles(styles)
@connect((state, ownProps) => ({
  ...ownProps,
  translate: getTranslation(state.translation.lang),
  autoPlay: state.auth.user.settings.autoPlay,
  language: ownProps.mainLanguage || state.translation.lang,
}))
export class VideoPlayer extends React.PureComponent<Props, State> {
  videoNode: HTMLVideoElement
  player: any
  playerInterval: any
  prevTime = 0
  interval = null
  resizeDebounce: any
  soundAudio = null

  state = {
    showRibbon: true,
    videoErr: false,
    videoPlaying: false,
    isVideoPlayEnded: false,
    count: 0,
    fontSize: 14,
    currentBitrate: -1,
    subTitleLang: this.props.subtitlesLang,
    subtitles: {
      enabled: true,
      language:
        this.props.voiceOver &&
        this.props.voiceOver.length &&
        this.props.voiceOver.find(v => v.language === this.props.language)
          ? this.props.language
          : this.props.masterLanguage,
      current: 0,
      points: [],
    },
    // These events are used for showing video url link
    linkConfirmationToShow: null,
    shownChapterLinkIds: [],
  }

  public componentDidMount() {
    const lang = this.props.language
    this.soundAudio = new Audio(
      s3URLPrefix + `${langToVideoLinkPauseSoundMap[lang] || langToVideoLinkPauseSoundMap['en-us']}`
    )

    // instantiate Video.js
    this.player = videojs(this.videoNode, {
      autoplay: this.props.disableAutoPlay ? false : this.props.autoPlay,
      controls: true,
      fluid: true,
      aspectRatio: '16:9',
      poster: this.props.thumbnail,
      sources: [
        {
          src: this.props.source,
          type:
            this.props.source &&
            this.props.source
              .split('&')[0]
              .split('.')
              .pop() === 'mpd'
              ? 'application/dash+xml'
              : 'video/mp4',
        },
      ],
    })

    this.player.on('loadeddata', this.onLoadedData)
    this.player.on('play', this.onEditorPlay)
    this.player.on('ended', this.onEditorEnd)
    this.player.on('error', this.onEditorError)
    this.player.on('timeupdate', this.onPlayerTimeUpdate)

    this.onResize(this.player.el_.clientHeight, this.player.aspectRatio_)
    window.addEventListener('resize', this.onResizeDebounce)

    if (this.props.voiceOver && this.props.voiceOver.length) {
      const subtitles_element_instance = videojs.getComponent('Component')
      const subtitles_element = new subtitles_element_instance(this.player, {})

      subtitles_element.el().innerHTML = `
        <div class="${this.props.classes.subtitles}">
          <span>CC</span>
          </div>
          <div>
            ${chunk(this.getSubtitlesLanguages(this.props.voiceOver), 10)
              .map(
                currentChunk =>
                  `<div>
                    ${currentChunk
                      .map(
                        item =>
                          `<span class="${
                            item === this.state.subtitles.language ? 'active' : ''
                          }" data-language="${item}">${item}</span>`
                      )
                      .join(' ')}
                  </div>`
              )
              .join(' ')}
          </div>
        </div>
      `

      this.player.getChild('controlBar').addChild(subtitles_element)

      document.querySelector(`.${this.props.classes.subtitles} > span`).addEventListener('click', this.enableSubtitles)

      Array.from(document.querySelectorAll(`.${this.props.classes.subtitles} > div > div > span`)).forEach(element => {
        element.addEventListener('click', this.setSubtitlesLanguage)
      })
    }

    if (this.props.showTTLogo) {
      const ttLogoInstance = videojs.getComponent('Component')
      const ttLogo = new ttLogoInstance(this.player, {})

      ttLogo.el().innerHTML = `
      <div>
      <img class="${this.props.classes.ttLogo} "src="./assets/images/tt-logo-white.png"/>
      </div>
      `

      this.player
        .getChild('controlBar')
        .addChild(ttLogo)
        .addClass('vjs-order-2')
    }

    if (!this.props.isEmbedVideo) {
      this.player.getChild('controlBar').addChild('autoPlaySwitch', {})
    }
  }

  // destroy player on unmount
  public componentWillUnmount() {
    clearInterval(this.playerInterval)
    if (this.player) {
      this.player.off('loadeddata', this.onLoadedData)
      this.player.off('start', this.onEditorPlay)
      this.player.off('ended', this.onEditorEnd)
      this.player.off('error', this.onEditorError)
      this.player.on('timeupdate', this.onPlayerTimeUpdate)
      this.soundAudio.pause()

      const enableSubtitlesDOM = document.querySelector(`.${this.props.classes.subtitles} > span`)
      const setSubtitlesLanguageDOM = document.querySelectorAll(`.${this.props.classes.subtitles} > div > span`)
      if (enableSubtitlesDOM) {
        enableSubtitlesDOM.removeEventListener('click', this.enableSubtitles)
      }
      if (setSubtitlesLanguageDOM) {
        Array.from(document.querySelectorAll(`.${this.props.classes.subtitles} > div > span`)).forEach(element => {
          element.removeEventListener('click', this.setSubtitlesLanguage)
        })
      }
      window.removeEventListener('resize', this.onResizeDebounce)
      this.player.dispose()
    }
  }

  private onLoadedData = () => {
    if (this.props.onLoaded) this.props.onLoaded()
    if (!this.player.dash) return

    this.player.dash.mediaPlayer.seek(this.player.duration() * 0.6)
    setTimeout(() => this.player.dash.mediaPlayer.seek(0), 200)

    const bitrates: Bitrate[] = this.player.dash.mediaPlayer.getBitrateInfoListFor('video')
    const bitrates_element_instance = videojs.getComponent('Component')
    const bitrates_element = new bitrates_element_instance(this.player, {})

    bitrates_element.el().innerHTML = `
      <div class="${this.props.classes.bitrates}">
        <span>AUTO</span>
        <div>
          ${chunk(
            [{ bitrate: -1, height: -1, mediaType: '', qualityIndex: -1, scanType: null, width: 0 }, ...bitrates],
            10
          )
            .map(
              currentChunk =>
                `<div>
                  ${currentChunk
                    .map(
                      item =>
                        `<span class="${item.bitrate === this.state.currentBitrate ? 'active' : ''}" data-language="${
                          item.qualityIndex
                        }">${item.height === -1 ? 'AUTO' : `${item.height}P`}</span>`
                    )
                    .join(' ')}
                </div>`
            )
            .join(' ')}
        </div>
      </div>
    `

    this.player.getChild('controlBar').addChild(bitrates_element)
    Array.from(document.querySelectorAll(`.${this.props.classes.bitrates} > div > div > span`)).forEach(element => {
      element.addEventListener('click', this.setBitrate)
    })
  }

  private onResizeDebounce = () => {
    clearTimeout(this.resizeDebounce)
    if (!this.player.el_) return

    this.resizeDebounce = setTimeout(() => this.onResize(this.player.el_.clientHeight, this.player.aspectRatio_), 500)
  }

  private onResize(height: number, ratio: string) {
    const [x, y] = ratio.split(':')
    this.setState({
      fontSize: ((Number(y) / Number(x)) * height) / 12,
    })
  }

  private onEditorPlay = () => {
    if (this.props.voiceOver && this.props.voiceOver.length && !this.state.subtitles.points.length) {
      this.getSubtitlesPoints(this.state.subtitles.language, this.props.voiceOver)
    }

    if (!!this.state.subTitleLang) {
      this.setState({
        subTitleLang: undefined,

        subtitles: {
          ...this.state.subtitles,
          enabled: true,
          language: this.state.subTitleLang,
        },
      })
    }

    this.setState({
      videoPlaying: true,
      isVideoPlayEnded: false,
      count: 0,
      showRibbon: false,
      videoErr: false,
    })

    if (this.player.currentTime() * 1000 !== 0 && this.playerInterval) return

    this.props.onPlaybackEvent('videoPlaybackStarted')

    const interval = 10 * 1000
    this.playerInterval = setInterval(() => {
      if (!this.player || (this.player && this.prevTime === this.player.currentTime() * 1000)) return

      const time = this.player.paused() ? this.player.currentTime() * 1000 - this.prevTime : interval
      this.props.onPlaybackEvent('videoTimeWatched', time * this.player.playbackRate())
      this.prevTime = this.player.currentTime() * 1000
    }, interval)
  }

  private onEditorEnd = () => {
    this.setState({
      videoPlaying: false,
      isVideoPlayEnded: true,
      count: 0,
    })
    clearInterval(this.playerInterval)
    this.props.onPlaybackEvent('videoTimeWatched', this.player.currentTime() * 1000 - this.prevTime)
    this.props.onPlaybackEvent('videoPlaybackEnded')
    this.prevTime = 0
    this.props.onVideoPlaybackEnded()
  }

  private onEditorError = () => {
    if (this.props.onLoaded) this.props.onLoaded()
    this.setState({ videoErr: true })
  }

  private onContextMenu = e => {
    e.preventDefault()
  }

  private setBitrate = ({ target }) => {
    const bitrates: Bitrate[] = this.player.dash.mediaPlayer.getBitrateInfoListFor('video')
    const currentBitrate = Number(target.getAttribute('data-language'))
    const active = document.querySelector(`.${this.props.classes.bitrates} .active`)

    document.querySelector(`.${this.props.classes.bitrates} > span`).textContent =
      currentBitrate === -1 ? 'AUTO' : `${bitrates.find(b => b.qualityIndex === currentBitrate).height}P`

    if (Boolean(active)) active.classList.remove('active')
    target.classList.add('active')

    if (currentBitrate === -1) {
      this.player.dash.mediaPlayer.setAutoSwitchQualityFor('video', true)
    } else {
      this.player.dash.mediaPlayer.setAutoSwitchQualityFor('video', false)
      this.player.dash.mediaPlayer.setQualityFor('video', currentBitrate)
    }

    this.setState({ currentBitrate })
  }

  private setSubtitlesLanguage = ({ target }) => {
    const active = document.querySelector(`.${this.props.classes.subtitles} .active`)

    if (Boolean(active)) active.classList.remove('active')
    target.classList.add('active')

    this.setState({
      subtitles: {
        ...this.state.subtitles,
        enabled: true,
        language: target.getAttribute('data-language'),
      },
    })
  }

  private getChaptersFromLanguage = (language: string, object: Subtitles) =>
    object.length
      ? (
          object.find(transcript => transcript.language === language && Boolean(transcript.chapters.length)) || {
            chapters: [],
          }
        ).chapters
      : []

  private getSubtitlesLanguages = (object: Subtitles = []) => object.map(object => object.language)

  private getSubtitlesPoints = (language: string, object: Subtitles) => {
    if (language && object) {
      this.setState({
        subtitles: {
          ...this.state.subtitles,
          points: this.getChaptersFromLanguage(language, object).map(item => item.start),
        },
      })
    }
  }

  private enableSubtitles = ({ target }) => {
    if (this.state.subtitles && document.querySelector(`.${this.props.classes.subtitles} .active`)) {
      document.querySelector(`.${this.props.classes.subtitles} .active`).classList.remove('active')
    }

    this.setState(({ subtitles }) => ({
      subtitles: {
        ...subtitles,
        enabled: !subtitles.enabled,
      },
    }))
  }

  private onPlayerTimeUpdate = () => {
    this.setState(({ subtitles }) => {
      const currentTime = this.player.currentTime() * 1000
      const currentIndex = subtitles.points.findIndex(point => point > currentTime)
      const lastChapterTime = subtitles.points[subtitles.points.length - 1]

      if (!this.props.isVideoLinksFeatureEnabled) {
        return {
          subtitles: {
            ...subtitles,
            current:
              currentIndex !== -1
                ? currentIndex - 1
                : lastChapterTime < this.player.currentTime() * 1000
                ? subtitles.points.length - 1
                : -1,
          },
          linkConfirmationToShow: null,
          shownChapterLinkIds: [],
        }
      }

      const { prevChapterStart, start } = (this.props.links || []).reduce(
        (acc, item, index) => {
          const nextItem = this.props.links[index + 1]

          const nextStart = nextItem ? nextItem.start : this.player.duration() * 1000

          // 200 is added as tolerance so that, it doesnot exceed move to the new chapter before showing the link
          if (currentTime >= nextStart - 200) {
            return { ...acc, start: nextStart, prevChapterStart: this.props.links[index].start }
          }

          return acc
        },
        { start: 0, prevChapterStart: 0 }
      )

      const currentLinkToShow = this.props.links
        ? [...this.props.links].find(item => item.start === prevChapterStart)
        : { start: 0, link: { url: '' } }

      const nextLink = this.props.links
        ? [...this.props.links].find(item => item.start === start) || this.props.links[this.props.links.length - 1]
        : { start: 0, link: { url: '' } }

      const doesCurrentChapterContainLink = includes(
        this.state.shownChapterLinkIds,
        currentLinkToShow ? currentLinkToShow.start : 0
      )

      const canShowLink =
        !doesCurrentChapterContainLink &&
        currentLinkToShow &&
        nextLink.start !== 0 &&
        !!currentLinkToShow.link &&
        !!currentLinkToShow.link.url

      if (canShowLink) {
        this.soundAudio.play()

        this.player.pause()
      }

      return {
        subtitles: {
          ...subtitles,
          current:
            currentIndex !== -1
              ? currentIndex - 1
              : lastChapterTime < this.player.currentTime() * 1000
              ? subtitles.points.length - 1
              : -1,
        },
        linkConfirmationToShow: canShowLink ? currentLinkToShow : this.state.linkConfirmationToShow,
        shownChapterLinkIds: uniq([...this.state.shownChapterLinkIds, currentLinkToShow ? currentLinkToShow.start : 0]),
      }
    })
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  public render() {
    const { classes, translate, voiceOver, videoDuration, history, trackItems, videoId, autoPlay } = this.props
    const { videoErr, subtitles, linkConfirmationToShow, videoPlaying, isVideoPlayEnded } = this.state

    const chapters = voiceOver ? this.getChaptersFromLanguage(subtitles.language, voiceOver) : []

    const currentVideoIndex =
      !!trackItems && !!trackItems.length && videoId ? trackItems.findIndex(video => video.videoId === videoId) : null
    const nextVideo = currentVideoIndex === 0 || !!currentVideoIndex ? trackItems[currentVideoIndex + 1] : null
    const showNextThumbnail = isVideoPlayEnded && !!nextVideo && !!nextVideo.videoId

    const elemId = document.getElementById('video-container')
    const clientWidth = elemId ? elemId.clientWidth : null

    // Font size is calculated based on the size of the video container
    const fontSize = clientWidth ? (clientWidth > 1000 ? clientWidth / 55 : clientWidth / 48) : 16

    if (showNextThumbnail && !this.interval && autoPlay) {
      this.interval = setInterval(() => {
        // Autoplay after 8 seconds
        if (this.state.count > 80) {
          clearInterval(this.interval)
          this.interval = null
          history && history.push(`/video/${nextVideo.humanId}?autoPlay=true`)
        } else {
          this.setState({ count: this.state.count + 1 })
        }
      }, 100)
    }

    const onCompleteLinkPage = () => {
      clearInterval(this.interval)
      this.interval = null

      this.setState({ linkConfirmationToShow: null, count: 0 })
      const currentTime = this.player.currentTime() * 1000
      if (currentTime !== this.player.duration() * 1000) {
        this.soundAudio.pause()
        this.player.play()
      }
    }

    if (linkConfirmationToShow && !this.interval) {
      this.interval = setInterval(() => {
        // Autoplay after 8 seconds
        if (this.state.count > 80) {
          onCompleteLinkPage()
        } else {
          this.setState({ count: this.state.count + 1 })
        }
      }, 100)
    }

    return (
      <div className={classes.videoWrapper}>
        <div data-vjs-player={true} onContextMenu={this.onContextMenu}>
          {!linkConfirmationToShow && showNextThumbnail && autoPlay && (
            <div
              id="video-container"
              style={{ fontSize: fontSize, background: 'rgba(0, 0, 0, 0.54)' }}
              className={classes.videoEndWrapper}
            >
              <div className={classes.titleText}>{translate('up-next')}</div>
              <div className={classes.nextVideoName}>{replaceTranslationSkipCharacters(nextVideo.name)}</div>
              <div className={classes.nextChannelName}>{nextVideo.channelName}</div>

              <Link className={classes.nextVideoLink} to={`/video/${nextVideo.humanId}?autoPlay=true`}>
                <CircularProgress
                  variant="static"
                  className={classes.loadingIcon}
                  // Since the interval of auto play is 8 secs. And the progress bar takes values from (0-100) scale (0-80) to (0-100). Additional 0.05 is added so that the progress bar is stuck at 100 for a short time
                  value={this.state.count + 0.25 * this.state.count}
                  style={{ color: 'white' }}
                  thickness={3}
                />
                <IconButton id="play-video" className={classes.nextVideoPlayBtn}>
                  <Icon className={classes.nextPlayIcon}>skip_next</Icon>
                </IconButton>
              </Link>
              <div
                onClick={() => {
                  this.setState({ isVideoPlayEnded: false, count: 0 })
                  clearInterval(this.interval)
                  this.interval = null
                }}
                className={classes.cancelBtn}
              >
                <span className={classes.cancelText}> {translate('playback-cancel')}</span>
              </div>
            </div>
          )}
          {linkConfirmationToShow && (
            <div style={{ fontSize: fontSize, background: 'rgba(0, 0, 0, 0.54)' }} className={classes.videoEndWrapper}>
              <div className={classes.titleText} style={{ textTransform: 'uppercase', textAlign: 'center' }}>
                {translate('related-link')}
              </div>
              <div className={classNames(classes.linkTitle)} style={{ margin: '20px auto', textAlign: 'center' }}>
                {linkConfirmationToShow.link.title}
              </div>
              <div onClick={() => onCompleteLinkPage()} style={{ textAlign: 'center' }}>
                <CircularProgress
                  variant="static"
                  className={classes.loadingIcon}
                  // Since the interval of auto play is 8 secs. And the progress bar takes values from (0-100) scale (0-80) to (0-100). Additional 0.05 is added so that the progress bar is stuck at 100 for a short time
                  value={this.state.count + 0.25 * this.state.count}
                  style={{ color: 'white' }}
                  thickness={3}
                />
                <IconButton id="play-video" className={classes.nextVideoPlayBtn}>
                  <Icon className={classes.nextPlayIcon}>skip_next</Icon>
                </IconButton>
              </div>

              <div className={''} style={{ marginBottom: 15, textAlign: 'center', marginTop: 30 }}>
                <a
                  href={linkConfirmationToShow.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames(classes.link, classes.cancelText)}
                  style={{ cursor: 'pointer' }}
                >
                  {translate('open-link')}
                </a>
              </div>
            </div>
          )}

          {this.state.showRibbon && videoDuration && (
            <div className={classes.videoDuration} style={{ backgroundColor: 'black', opacity: 0.8 }}>
              {videoDuration}
            </div>
          )}
          <video
            data-setup='{ "playbackRates": [0.7, 1, 1.25, 1.5, 2, 3] }'
            ref={node => (this.videoNode = node)}
            style={{ opacity: showNextThumbnail ? 0.3 : 1 }}
            className="video-js vjs-big-play-centered h-full w-full"
          />
          {chapters.length && videoPlaying && subtitles.enabled && chapters[subtitles.current] && (
            <div className={classes.transcript} style={{ fontSize: this.state.fontSize }}>
              {chapters[subtitles.current].manuscript}
            </div>
          )}
        </div>
        {videoErr && (
          <Card className={classes.errorCard}>
            {navigator.platform === 'iPhone' ? translate('ios-video-error') : translate('video-player-error')}
          </Card>
        )}
      </div>
    )
  }
}

// @ts-ignore
@connect((state, ownProps) => ({
  ...ownProps,
  choosenLang: state.translation.lang,
  autoPlay: state.auth.user.settings.autoPlay,
  translate: getTranslation(state.translation.lang),
}))
export class VideoPlayerWithLoader extends React.PureComponent<any, any> {
  stopPolling: any
  lastStatus: string

  props: {
    key?: string
    history?: {
      replace: (path: string) => void
      push: (path: string) => void
    }
    disableAutoPlay?: boolean
    source: string
    lastVersion: number
    choosenLang?: string
    thumbnail?: string
    ribbonType?: string
    subtitlesLang?: string
    mainLanguage?: string
    videoId: string
    showTTLogo?: boolean
    videoDuration?: string
    links?: { start: number; link: VideoLink }[]
    isVideoLinksFeatureEnabled?: boolean
    tubeColor?: string
    // backendId?: string
    translate?: any
    trackItems?: any
    shouldAutoPlay?: boolean
    linkedUrl?: string
    skipRefetch?: boolean
    refetchVideo?: any
    fetchTranscodingStatus?: boolean
    showLoadingStatus?: boolean
    lang: string
    masterLanguage: string
    subtitles: Subtitles
    onLoaded?: () => void
    onVideoPlaybackEnded?: () => void
    autoPlay?: boolean
    isEmbedVideo?: boolean
  }

  componentWillUnmount() {
    if (this.stopPolling) {
      this.stopPolling()
    }
  }

  renderVideo(thumbnail?: string) {
    return (
      <Mutation {...addUserAnalyticsMutation()}>
        {addUserAnalyticsReq => (
          <QueryS3Url source={this.props.source}>
            {({ loading, error, url }) => {
              if (loading) {
                return <p>{this.props.translate('loading')}</p>
              }
              if (error) {
                return <p>{error.message}</p>
              }

              if (!this.props.source && this.props.fetchTranscodingStatus) {
                return (
                  <Query {...getTranscodingStatusQuery(this.props.videoId)}>
                    {({ error, data, stopPolling }: GetTranscodingStatus) => {
                      this.stopPolling = stopPolling
                      if (error) {
                        return <p>{error.message}</p>
                      }

                      const transcodingStatus =
                        data && data.transcodingStatus && data.transcodingStatus.length
                          ? data.transcodingStatus
                              .filter(t => t.language === this.props.lang && t.version === this.props.lastVersion)
                              .slice(-1)[0]
                          : null
                      const status = transcodingStatus ? transcodingStatus.status : ''
                      if (this.lastStatus !== 'completed' && status === 'completed' && this.props.refetchVideo) {
                        this.props.refetchVideo(status)
                      }

                      this.lastStatus = status

                      if (this.props.showLoadingStatus) {
                        return (
                          <div
                            style={{
                              paddingTop: '22%',
                              paddingBottom: '22%',
                              fontSize: 20,
                            }}
                          >
                            <p style={{ textAlign: 'center' }}>{this.props.translate('video-player-error')}</p>
                          </div>
                        )
                      }

                      return (
                        <div
                          style={{
                            paddingTop: '22%',
                            paddingBottom: '22%',
                            fontSize: 20,
                          }}
                        >
                          {!data || !data.transcodingStatus || !data.transcodingStatus.length || !status ? (
                            <p style={{ textAlign: 'center' }}>{this.props.translate('video-player-error')}</p>
                          ) : (
                            <React.Fragment>
                              <CircularProgress style={{ color: this.props.tubeColor || purple[500] }} thickness={7} />
                              <p>
                                {this.props.translate('transcoding')}: {this.props.translate(status)}
                              </p>
                            </React.Fragment>
                          )}
                        </div>
                      )
                    }}
                  </Query>
                )
              }
              return (
                <VideoPlayer
                  source={url}
                  voiceOver={this.props.subtitles}
                  ribbonType={this.props.ribbonType}
                  thumbnail={thumbnail}
                  subtitlesLang={this.props.subtitlesLang}
                  showTTLogo={this.props.showTTLogo}
                  isEmbedVideo={this.props.isEmbedVideo}
                  onLoaded={this.props.onLoaded}
                  shouldAutoPlay={this.props.autoPlay}
                  disableAutoPlay={this.props.disableAutoPlay}
                  linkedUrl={this.props.linkedUrl}
                  videoId={this.props.videoId}
                  mainLanguage={this.props.mainLanguage}
                  history={this.props.history}
                  links={this.props.links}
                  isVideoLinksFeatureEnabled={this.props.isVideoLinksFeatureEnabled}
                  videoDuration={this.props.videoDuration}
                  tubeColor={this.props.tubeColor}
                  trackItems={this.props.trackItems}
                  masterLanguage={this.props.masterLanguage}
                  onPlaybackEvent={(event, time) => {
                    if (this.props.videoId) {
                      addUserAnalyticsReq(
                        addUserAnalyticsVariables(
                          {
                            type: event,
                            payload:
                              event === 'videoTimeWatched'
                                ? {
                                    videoId: this.props.videoId,
                                    time,
                                    version: this.props.lastVersion,
                                    lang: this.props.lang,
                                  }
                                : {
                                    videoId: this.props.videoId,
                                    version: this.props.lastVersion,
                                    lang: this.props.lang,
                                  },
                          },
                          this.props.skipRefetch
                        )
                      )
                    }
                  }}
                  onVideoPlaybackEnded={this.props.onVideoPlaybackEnded}
                />
              )
            }}
          </QueryS3Url>
        )}
      </Mutation>
    )
  }

  getThumbnailAndRenderVideo() {
    return (
      <QueryS3Url source={this.props.thumbnail}>
        {({ loading, error, url }) => {
          if (loading) {
            return <p>Loading video...</p>
          }
          if (error) {
            return <p>{error.message}</p>
          }
          return this.renderVideo(url)
        }}
      </QueryS3Url>
    )
  }
  render() {
    return this.props.thumbnail ? this.getThumbnailAndRenderVideo() : this.renderVideo()
  }
}
