import * as React from 'react'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MobileDetect from 'mobile-detect/mobile-detect'
import { includes } from 'lodash'
import { matchPath } from 'react-router-dom'

const md = new MobileDetect(window.navigator.userAgent)
const isMobile = md.mobile()

const defaultProps = {
  className: '',
  enable: true,
  option: {
    wheelPropagation: true,
  },
  containerRef: () => {},
  onScrollY: undefined,
  onScrollX: undefined,
  onScrollUp: undefined,
  onScrollDown: undefined,
  onScrollLeft: undefined,
  onScrollRight: undefined,
  onYReachStart: undefined,
  onYReachEnd: undefined,
  onXReachStart: undefined,
  onXReachEnd: undefined,
}

const handlerNameByEvent = {
  'ps-scroll-y': 'onScrollY',
  'ps-scroll-x': 'onScrollX',
  'ps-scroll-up': 'onScrollUp',
  'ps-scroll-down': 'onScrollDown',
  'ps-scroll-left': 'onScrollLeft',
  'ps-scroll-right': 'onScrollRight',
  'ps-y-reach-start': 'onYReachStart',
  'ps-y-reach-end': 'onYReachEnd',
  'ps-x-reach-start': 'onXReachStart',
  'ps-x-reach-end': 'onXReachEnd',
}

Object.freeze(handlerNameByEvent)

const styles = theme => ({
  root: {},
})

type Props = {
  customScrollbars: boolean
  option: {
    wheelPropagation: true
  }
  updatePage: any
  updateScroll: any
  currentPage: number
  isPaginating: boolean
  isPaginationComplete: boolean
  containerRef: (ref: HTMLDivElement) => void
  className: string
  enable: boolean
  location: { pathname: string; search: string }
}

// @ts-ignore
@withStyles(styles, { withTheme: true })
class Scrollbars extends React.PureComponent<Props, {}> {
  _handlerByEvent: Map<any, any>
  _ps: PerfectScrollbar
  _container: HTMLDivElement

  static defaultProps = defaultProps

  constructor(props: Props) {
    super(props)
    this._handlerByEvent = new Map()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.customScrollbars) {
      setTimeout(() => {
        this.createPs()
      })
    } else {
      setTimeout(() => {
        this.destroyPs()
      })
    }

    this.updatePs()
  }

  componentDidMount() {
    this.createPs()
  }

  componentWillUnmount() {
    this.destroyPs()
  }

  updatePs = () => {
    if (!this._ps) {
      return
    }
    this._ps.update()
  }

  destroyPs = () => {
    if (!this._ps) {
      return
    }
    // unhook up evens
    Object.keys(this._handlerByEvent).forEach((value, key) => {
      // @ts-ignore
      this._container.removeEventListener(key, value, false)
    })
    this._handlerByEvent.clear()
    this._ps.destroy()
    this._ps = null
  }

  createPs = () => {
    if (isMobile || !this._container || this._ps) {
      return
    }

    this._ps = new PerfectScrollbar(this._container, this.props.option)

    // hook up events
    Object.keys(handlerNameByEvent).forEach(key => {
      const callback = this.props[handlerNameByEvent[key]]
      if (callback) {
        const handler = () => callback(this._container)
        this._handlerByEvent.set(key, handler)
        this._container.addEventListener(key, handler, false)
      }
    })
  }

  handleRef = (ref: HTMLDivElement) => {
    this._container = ref
    this.props.containerRef(ref)
  }

  render() {
    const { children, className, customScrollbars, enable } = this.props

    const isChannelHome = !!matchPath(this.props.location.pathname, {
      path: ['/channel/:id/home'],
      exact: true,
      strict: false,
    })

    const isScrollDisabled = includes(['/home', '/subscriptions'], this.props.location.pathname) || isChannelHome

    return customScrollbars && enable && !isMobile ? (
      <div
        id="scrollbar-wrapper"
        className={className}
        style={{
          position: 'relative',
          height: '100%',
          overflow: isScrollDisabled ? 'none !important' : 'overlay',
        }}
        ref={this.handleRef}
      >
        {children}
      </div>
    ) : (
      <div
        id="track-list-container"
        className={this.props.className}
        style={{
          overflow: isScrollDisabled ? 'none !important' : 'overlay !important',
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

function mapStateToProps({ fuse }) {
  return {
    customScrollbars: fuse.settings.current.customScrollbars,
  }
}

export default withRouter(connect(mapStateToProps)(Scrollbars))
