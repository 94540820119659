import * as React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Manager, Target, Popper } from 'react-popper'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import { MenuItem, Icon, MenuList } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { sortBy } from 'lodash'
import Apps from '@material-ui/icons/Apps'
import { getTranslation, Translate } from '../../../translation'
import { Channel } from 'src/gql/listChannels'
import { ReduxState } from 'src/store/reducers'
import RouterLink from 'src/components/reusable/RouterLink'

const menuItemHeight = 48
const menuItemCount = 8

const styles = theme => ({
  root: {
    display: 'flex',
    width: 60,
    justifyContent: 'center',
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  popperClose: {
    pointerEvents: 'none',
  },
  dropdown: {
    display: 'flex',
    backgroundColor: 'white',
    boxShadow: 'none',
    '-ms-overflow-style': 'none',
  },
  iconSmall: {
    fontSize: 40,
    paddingRight: 5,
  },
  channelsMenu: {
    width: '100%',
    '& a + div': {
      opacity: 0,
      pointerEvents: 'none',
    },
    '& a:hover + div': {
      pointerEvents: 'unset',
      opacity: 1,
    },
    '& a:hover': {
      pointerEvents: 'unset',
      opacity: 1,
    },
    '& div:hover': {
      pointerEvents: 'unset',
      opacity: 1,
    },
  },
  channelWrapper: {
    opacity: '1 !important',
    position: 'relative',
    backgroundColor: 'white',
  },
  nestedPopper: {
    transform: 'none !important',
    left: '100% !important',
  },
  menuWrapper: {
    border: '1px #e0e0e0 solid',
    width: '100%',
  },
  menu: {
    height: menuItemCount * menuItemHeight,
    maxHeight: menuItemCount * menuItemHeight,
    'overflow-x': 'hidden',
    paddingTop: 0,
  },
  menuLink: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      textDecoration: 'none !important',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 15,
  },
  scrollIcon: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  menuItemLink: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

type Props = {
  classes?: any
  channels: Channel[]
  loading: boolean
  translate: Translate
  tubeColor: string
}

type State = {
  open: boolean
  selectedChannels: string[]
  scrolledMenus: {
    [key: string]: number
  }
}

class MenuListComposition extends React.PureComponent<Props, State> {
  state = {
    open: false,
    selectedChannels: [],
    scrolledMenus: {},
  }

  private getChannels = (parentId: string) =>
    sortBy(
      this.props.channels.filter(channel => channel.parent === parentId),
      'name'
    )

  private handleOpen = () => {
    this.setState({ open: true, selectedChannels: [] })
  }

  private handleClose = () => {
    this.setState({ open: false, selectedChannels: [] })
  }

  private onSelectChannel = (channelId: string, depth: number) => () => {
    this.setState({
      selectedChannels: [...this.state.selectedChannels.slice(0, depth), channelId],
    })
  }

  private onUnselectChannel = (depth: number) => () => {
    this.setState({
      selectedChannels: this.state.selectedChannels.slice(0, depth),
    })
  }

  private onScrollDown = (depth: number) => () => {
    const element = document.getElementById(`scroll-menu-${depth}`)
    const scrollTo = menuItemHeight + (this.state.scrolledMenus[`depth-${depth}`] || 0)
    if (scrollTo > element.scrollHeight - menuItemHeight * (menuItemCount - 1)) {
      return
    }

    element.scrollTop = scrollTo

    this.setState({
      scrolledMenus: {
        ...this.state.scrolledMenus,
        [`depth-${depth}`]: scrollTo,
      },
    })
  }

  private onScrollUp = (depth: number) => () => {
    const element = document.getElementById(`scroll-menu-${depth}`)
    const scrollTo = this.state.scrolledMenus[`depth-${depth}`] - menuItemHeight
    element.scrollTop = scrollTo

    this.setState({
      scrolledMenus: {
        ...this.state.scrolledMenus,
        [`depth-${depth}`]: scrollTo,
      },
    })
  }

  public render() {
    const { classes, loading, translate, tubeColor } = this.props
    const { open, selectedChannels, scrolledMenus } = this.state

    return (
      <Manager className={classes.root}>
        <Target>
          <Button
            id="select-channel-btn"
            className={classes.selectButton}
            aria-owns={open ? 'menu-list-grow' : null}
            aria-haspopup="true"
            onClick={this.handleOpen}
          >
            <Apps className={classNames(classes.leftIcon, classes.iconSmall)} />
          </Button>
        </Target>
        <Popper
          placement="bottom-start"
          eventsEnabled={open}
          className={classNames({ [classes.popperClose]: !open }, classes.channelsMenu)}
        >
          <ClickAwayListener onClickAway={this.handleClose}>
            <Grow in={open} style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classNames(classes.dropdown)}>
                {loading ? (
                  translate('loading')
                ) : (
                  <div className={classes.menuWrapper}>
                    {scrolledMenus['depth-0'] ? (
                      <div className={classes.scrollIcon} onClick={this.onScrollUp(0)}>
                        <Icon>expand_less</Icon>
                      </div>
                    ) : null}
                    <MenuList id="scroll-menu-0" role="menu" className={classNames('channels-menu', classes.menu)}>
                      {this.getChannels(localStorage.getItem('tubeId')).map(channel => (
                        <React.Fragment key={channel.id}>
                          <MenuItem
                            className={classes.menuItemLink}
                            style={
                              Boolean(selectedChannels.length) && channel.id === selectedChannels[0]
                                ? { backgroundColor: tubeColor }
                                : {}
                            }
                          >
                            <RouterLink
                              id={`channel-${(channel.name || '').replace(/\s{1,}/g, '-')}-link`}
                              to={`/channel/${channel.id}/home`}
                              className={classes.menuLink}
                              onClick={this.handleClose}
                              style={
                                Boolean(selectedChannels.length) && channel.id === selectedChannels[0]
                                  ? { color: 'white' }
                                  : {}
                              }
                            >
                              {channel.name}
                            </RouterLink>
                            {this.getChannels(channel.id).length && selectedChannels[0] === channel.id ? (
                              <Icon onClick={this.onUnselectChannel(0)}>expand_less</Icon>
                            ) : this.getChannels(channel.id).length ? (
                              <Icon onClick={this.onSelectChannel(channel.id, 0)}>expand_more</Icon>
                            ) : null}
                          </MenuItem>
                          {selectedChannels[0] === channel.id
                            ? selectedChannels.map((selectedChannel, index) => (
                                <MenuList
                                  key={`${selectedChannel.id}-${index}`}
                                  id={`scroll-menu-${index + 1}`}
                                  role="menu"
                                  style={{
                                    backgroundColor: '#fafafa',
                                    paddingTop: 0,
                                    paddingLeft: (index + 1) * 20,
                                  }}
                                >
                                  {this.getChannels(selectedChannel).map(channel => (
                                    <MenuItem
                                      key={channel.id}
                                      className={classes.menuItemLink}
                                      style={
                                        channel.id === selectedChannels[index + 1] ? { backgroundColor: tubeColor } : {}
                                      }
                                    >
                                      <RouterLink
                                        id={`selected-channel-${(channel.name || '').replace(/\s{1,}/g, '-')}-link`}
                                        name={channel.name}
                                        to={`/channel/${channel.id}/home`}
                                        className={classes.menuLink}
                                        onClick={this.handleClose}
                                        style={channel.id === selectedChannels[index + 1] ? { color: 'white' } : {}}
                                      >
                                        {channel.name}
                                      </RouterLink>
                                      {this.getChannels(channel.id).length &&
                                      selectedChannels[index + 1] === channel.id ? (
                                        <Icon onClick={this.onUnselectChannel(index + 1)}>expand_less</Icon>
                                      ) : this.getChannels(channel.id).length ? (
                                        <Icon onClick={this.onSelectChannel(channel.id, index + 1)}>expand_more</Icon>
                                      ) : null}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              ))
                            : null}
                        </React.Fragment>
                      ))}
                    </MenuList>
                    {this.getChannels(localStorage.getItem('tubeId')).length > 10 ? (
                      <div id="expand-more-icon" className={classes.scrollIcon} onClick={this.onScrollDown(0)}>
                        <Icon>expand_more</Icon>
                      </div>
                    ) : null}
                  </div>
                )}
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </Manager>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  translate: getTranslation(state.translation.lang),
  tubeColor: state.auth.tube.color,
})

// @ts-ignore
export default withStyles(styles)(connect(mapStateToProps)(MenuListComposition))
