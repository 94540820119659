import * as React from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Dropzone from 'react-dropzone'
import { FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core'
import Autocomplete from './Autocomplete'
import { convertFromHTML, EditorState, ContentState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
  <TextField helperText={touched && error} label={label} error={Boolean(touched && error)} {...input} {...custom} />
)

export class renderRichTextEditor extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)

    const blocksFromHTML = props.input.value ? convertFromHTML(props.input.value) : null
    const editorState =
      blocksFromHTML && blocksFromHTML.contentBlocks
        ? ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        : null

    this.state = {
      editorState: editorState ? EditorState.createWithContent(editorState) : EditorState.createEmpty(),
    }
  }

  onEditorChange = e => this.setState({ editorState: e })

  onEditorBlur = () => {
    const content = this.state.editorState.getCurrentContent()
    const html = stateToHTML(content)

    this.props.input.onChange(html)
  }

  render() {
    return (
      <Editor
        placeholder="Description"
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorChange}
        onBlur={this.onEditorBlur}
      />
    )
  }
}

export const renderAutocomplete = ({ input, label, suggestions, meta: { touched, error }, ...custom }) => (
  <div>
    <Autocomplete input={input} placeholder={label} error={Boolean(error && touched)} suggestions={suggestions} />
    {Boolean(touched && error) && <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>}
  </div>
)

export const renderAutocompleteWithLabel = ({
  input,
  label,
  disabled,
  suggestions,
  meta: { touched, error },
  ...custom
}) => (
  <div>
    {label && (
      <InputLabel disabled={disabled} style={{ fontSize: '13px' }} htmlFor={input.name}>
        {label}
      </InputLabel>
    )}
    <Autocomplete
      placeholder={label}
      input={input}
      disabled={disabled}
      error={Boolean(error && touched)}
      suggestions={suggestions}
    />
    {Boolean(touched && error) && <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>}
  </div>
)

export const renderSelectWithLabel = ({ input, label, meta: { touched, error }, ...custom }) => (
  <FormControl style={{ fontSize: '13px' }} error={Boolean(touched && error)} className={'w-full'}>
    {label && <InputLabel htmlFor={input.name}>{label}</InputLabel>}
    <Select
      {...input}
      input={<Input id={(input.name || input.id || '').replace(/\s{1,}/g, '-')} name={input.name} />}
      label={label}
      {...custom}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
    />
    {Boolean(touched && error) && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)
export const renderSelect = ({ input, label, meta: { touched, error }, ...custom }) => {
  return (
    <FormControl error={Boolean(touched && error)} className={input.className}>
      {label && <InputLabel htmlFor={input.name}>{label}</InputLabel>}
      <Select
        {...input}
        input={
          <Input
            id={(input.name || input.id || '').replace(/\s{1,}/g, '-')}
            name={input.name}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          />
        }
        label={label}
        {...custom}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          height: '50vh',
          style: {
            maxHeight: '50vh',
            display: 'flex',
            justifyContent: 'space-between',
          },
        }}
      />
      {Boolean(touched && error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export const renderDropdownList = ({ input, meta: { touched, error }, ...rest }) => (
  <div className="input-wrapper">
    <Select {...input} {...rest} />
    {touched && error && <span className="input-error">{error}</span>}
  </div>
)

export const renderDropzoneInput = ({ id, input, meta, className, dropzone, label }) => {
  return (
    <div>
      <Dropzone
        id={id}
        // name={name}
        className={className}
        {...dropzone}
        onDrop={(filesToUpload, e) => {
          input.onChange(filesToUpload)
          if (dropzone && dropzone.onDrop) {
            dropzone.onDrop(filesToUpload)
          }
        }}
      >
        <div>{label}</div>
      </Dropzone>
      {meta.touched && meta.error && <span className="error">{meta.error}</span>}
    </div>
  )
}
