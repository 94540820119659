import { CognitoUserPool, CognitoUserAttribute, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import Cookies from 'js-cookie'

type Params = {
  orgId: string
  userPoolId: string
  clientId: string
}

type UserData = {
  email: string
  token: string
}

export class CognitoAPI {
  private userPool: CognitoUserPool

  constructor({ userPoolId, clientId, orgId }: Params) {
    this.userPool = new CognitoUserPool({
      ClientId: clientId,
      UserPoolId: userPoolId,
    })
  }

  getUserData(session: any): UserData {
    let email = ''

    try {
      email = session.idToken.payload.email
    } catch (err) {
      console.error(err)
    }

    return {
      email,
      token: session.getIdToken().getJwtToken(),
    }
  }

  sessionLogin(ssoUrl: string): Promise<UserData> {
    return new Promise((resolve, reject) => {
      const hash = window.location.hash.substr(1)

      const result = hash.split('&').reduce(function(result, item) {
        const parts = item.split('=')
        result[parts[0]] = parts[1]
        return result
      }, {})

      // window.location.hash is used on SSO
      if (result['access_token']) {
        const timestampExpire = Date.now() + parseInt(result['expires_in']) * 1000

        localStorage.setItem('sso_token_expire', timestampExpire.toString())
        return resolve({
          email: '',
          token: result['access_token'],
        })
      }

      // login using SSO token, if stored
      const ssoTokenExpire = localStorage.getItem('sso_token_expire')
      const token = localStorage.getItem('token')
      if (token) {
        if (ssoTokenExpire) {
          if (parseInt(ssoTokenExpire) > Date.now()) {
            return resolve({
              email: '',
              token,
            })
          }

          localStorage.removeItem('token')
          localStorage.removeItem('sso_token_expire')
          Cookies.set('prevLocation', window.location.pathname, { expires: 0.1 })
          return (window.location.href = ssoUrl)
        } else {
          localStorage.removeItem('token')
          localStorage.removeItem('sso_token_expire')
        }
      }

      // otherwise, use cognito sdk
      const cognitoUser = this.userPool.getCurrentUser()
      if (!cognitoUser) {
        return reject({ message: 'no session' })
      }

      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          return reject(err)
        }
        if (!session.isValid()) {
          return reject({ message: 'invalid session' })
        }

        cognitoUser.getUserAttributes((errAttr, attributes) => {
          if (errAttr) {
            return reject(errAttr)
          }
          if (!attributes) {
            return reject({ message: 'missing user data' })
          }

          return resolve(this.getUserData(session))
        })
      })
    }).then((res: any) => {
      localStorage.setItem('token', res.token)
      return res
    })
  }

  signIn(email: string, password: string, newPassword?: string): Promise<UserData> {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      })

      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: this.userPool,
      })

      const authCallbacks = {
        onSuccess: result => resolve(this.getUserData(result)),
        newPasswordRequired: userAttributes => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified

          if (!newPassword) {
            return reject({
              newPassword: true,
              message: 'must create new password',
            })
          }

          cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, authCallbacks)
        },
        onFailure: err => reject(err),
      }
      cognitoUser.authenticateUser(authenticationDetails, authCallbacks)
    })
  }

  signUp(email: string, password: string, orgId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const dataEmail = {
        Name: 'email',
        Value: email,
      }

      const dataWebsite = {
        Name: 'website',
        Value: orgId,
      }

      const attributeEmail = new CognitoUserAttribute(dataEmail)
      const attributeWebsite = new CognitoUserAttribute(dataWebsite)
      const attributeList = [attributeEmail, attributeWebsite]
      const validationData = []

      this.userPool.signUp(email, password, attributeList, validationData, (err, result) => {
        if (err || !result) {
          return reject(err)
        }
        if (result.userConfirmed === false) {
          return resolve({
            userConfirmed: result.userConfirmed,
          })
        }
        return resolve(this.getUserData(result))
      })
    })
  }

  confirmRegistration(email: string, password: string, code: string): Promise<UserData> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: this.userPool,
      }

      const cognitoUser = new CognitoUser(userData)
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err)
          return
        }
        this.signIn(email, password)
          .then(resolve)
          .catch(reject)
      })
    })
  }

  resendConfirmationCode(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: this.userPool,
      }

      const cognitoUser = new CognitoUser(userData)
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err)
          return
        }

        resolve({
          userConfirmed: false,
        })
      })
    })
  }

  signOut() {
    const cognitoUser = this.userPool.getCurrentUser()
    if (!cognitoUser) {
      localStorage.removeItem('token')
      localStorage.removeItem('sso_token_expire')
      return null
    }
    return cognitoUser.signOut()
  }

  forgotPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: this.userPool,
      }

      const cognitoUser = new CognitoUser(userData)
      cognitoUser.forgotPassword({
        onSuccess: resolve,
        onFailure: reject,
      })
    })
  }

  confirmPassword(email: string, password: string, code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: this.userPool,
      }
      const cognitoUser = new CognitoUser(userData)
      cognitoUser.confirmPassword(code, password, {
        onSuccess: () =>
          this.signIn(email, password)
            .then(resolve)
            .catch(reject),
        onFailure: reject,
      })
    })
  }
}
