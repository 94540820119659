export default (theme: any) => ({
  wrapper: {
    padding: 15,
  },
  innerWrapper: {
    borderBottom: '1px solid #d8dbe0',
  },
  crossButton: {
    textAlign: 'right' as 'right',
    cursor: 'pointer',
  },
  welcomeMessageTitle: {
    color: '#94969a',
    fontSize: '18px !important',
    textAlign: 'center' as 'center',
  },
  userName: {
    color: '#323335',
    textAlign: 'center' as 'center',
    fontSize: '18px !important',
    marginBottom: 10,
  },

  userDetailsWrapper: {
    marginTop: 10,
  },

  nonEditableText: {
    color: '#323335',
    textAlign: 'center' as 'center',
    fontSize: 14,
  },
  uploadPictureButton: {
    textAlign: 'center' as 'center',
    width: 130,
    padding: 8,
    fontSize: 14,
    color: theme.palette.primary.main,
    borderRadius: 40,
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    margin: '25px auto 0 auto',
    display: 'block',
  },

  saveBtn: {
    background: theme.palette.primary.main,
    color: '#fff',
    borderRadius: 40,
    display: 'block',
    fontSize: 14,
    padding: '10px 30px',
    margin: '30px 0 0 auto',
    marginTop: 20,
  },
  error: {
    background: '#ef5350',
    color: '#fff',
  },

  inputRow: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    columnGap: '5px',
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'auto',
    },
  },
  userDetailTitle: {
    color: '#94969a',
    textAlign: 'center' as 'center',
    fontSize: 14,
    marginTop: 15,
  },
  inputField: {
    border: '1px solid #d8dbe0',
    color: '#323335',
    borderRadius: 40,
    padding: '7px 15px',
    display: 'block',
    width: 250,
    background: 'white',
    '@media (max-width: 768px)': {
      width: 180,
    },
    '@media (max-width: 600px)': {
      width: 250,
    },
  },

  profilePictureDefault: {
    width: 130,
    height: 130,
    margin: '10px auto',
    borderRadius: '50%',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    backgroundSize: '100%',
    textAlign: 'center' as 'center',
    '&:hover $clearImg': {
      display: 'flex',
    },
  },
  clearImg: {
    marginTop: -44,
    marginLeft: 22,
    position: 'absolute' as 'absolute',
    color: 'white',
    background: 'rgba(0,0,0,0.8)',
    // display:'flex',
    padding: '0px 4px',
    cursor: 'pointer',
    display: 'none',
  },
  profilePicText: {
    color: theme.palette.primary.main,
    fontSize: 48,
    left: '50%',
    top: '50%',
    display: 'block',
    transform: 'translate(0%,50%)',
  },
})
