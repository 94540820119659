const styles = {
  videoWrapper: {
    border: '1.5px solid lightgray',
  },
  headerWrapper: {
    height: '6%',
    display: 'flex',
    // width: '100%',
  },
  logoImg: {
    height: '42px',
    marginLeft: '2%',
    marginTop: '0.25%',
    '@media(max-width: 768px)': {
      marginTop: '2%',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  trainingTubeRedirect: {
    height: 42,
    marginLeft: 'auto',
    marginTop: '0.25%',
    fontSize: '16px',
    color: 'white',
    marginRight: '2%',
    textTransform: 'unset !important',

    '@media(max-width: 768px)': {
      marginTop: '2%',
      fontSize: 10,
    },
  },
  bodyWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0) !important',
    height: '100%',
  },
  cardWrapper: {
    margin: '15px auto',
    width: '50%',
    boxShadow: '-1px 1px 5px 4px #ccc',
    '@media(max-width: 768px)': {
      width: '100%',
    },
  },
  innerCard: {
    padding: '15px 10px',
  },
  video: {
    border: '1.5px solid lightgray',
  },
  videoName: {
    fontSize: 18,
    marginTop: 13,
    fontWeight: 500,
  },
  videoAbstract: {
    fontSize: 16,
  },
  descriptionTitle: {
    fontSize: 18,
    marginTop: 18,
    fontWeight: 500,
    marginBottom: 12,
  },
  descriptionBody: {
    fontSize: 16,
  },
  channelPreloader: {
    width: 40,
    height: 40,
    maxWidth: '100%',
    backgroundColor: '#cccccc',
  },
  channelImg: {
    width: 40,
    height: 40,
    maxWidth: '100%',
  },

  tooltip: {
    fontSize: 14,
  },
  channelImgLink: {
    cursor: 'pointer',
    height: 40,
    marginTop: 12,
    marginRight: 12,
    textDecoration: 'none !important',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  imageContainer: {
    display: 'flex',
    marginTop: 20,
  },
  videoCreatedDate: {
    marginTop: 10,
  },

  linkParentWrapper: {
    '&:hover $expandViewHover': {
      visibility: 'inherit !important' as 'inherit',
    },
  },
  linkInner: {
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d' as 'preserve-3d',
  },

  expandedLink: {
    position: 'absolute' as 'absolute',
    top: 0,
    visibility: 'inherit' as 'inherit',
    right: 10,
    zIndex: 999,
    overflowY: 'scroll',
    flexDirection: 'column',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '30%',
    height: '100%',
    display: 'flex',
    '&::-webkit-scrollbar': {
      width: 8,
      position: 'fixed',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
  linkWrapper: {
    margin: '0 auto',
    background: 'white',
    padding: 10,
    cursor: 'pointer',
  },
  linkTitleWrapper: {
    display: '-webkit-box',
    overflow: 'hidden',
    fontSize: '1.6rem',
    maxHeight: '7.4rem',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    marginBottom: 6,
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  link: {
    fontSize: 18,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#333',
  },
  expandView: {
    position: 'absolute' as 'absolute',
    top: 10,
    visibility: 'inherit' as 'inherit',
    right: 10,
    zIndex: 999,
    color: 'white',
    backgroundColor: 'black',
    width: 30,
    height: 30,
    display: 'flex',
    '&:hover $viewProgress': {
      display: 'block',
      marginTop: 5,
      marginLeft: 15,
      cursor: 'pointer',
    },
    transition: 'width 0.3s',
    '-webkit-transition': 'width 0.3s',
    textAlign: 'center' as 'center',
    overflow: 'hidden',
    '&:hover': {
      width: 130,
    },
  },
  viewProgress: {
    display: 'none',
  },
  infoBtn: {
    // backgroundColor:'transparent',
    // top:90,
    cursor: 'pointer',
    '& > span': {
      margin: 'auto',
    },
    '&:hover': {
      width: '130px !important',
    },
  },
  expandViewHover: {
    visibility: 'hidden !important' as 'inherit',
  },
  chartButton: {
    marginLeft: 'auto',
    zIndex: 999,
    color: 'white',
    backgroundColor: 'black',
    width: 30,
    height: 30,
  },
}

export default styles
