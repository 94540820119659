import gql from 'graphql-tag'

type CreateLeadParams = {
  type: string
  payload:
    | {
        channel?: string
        orgId?: string
        email?: string
        firstName?: string
        lastName?: number
        phone?: string
        description?: number
      }
    | { subject: string; requesterName: string; requesterEmail: string; body: string }
}

export const addCreateLeadMutation = () => ({
  mutation: gql`
    mutation addUserAnalytics($input: REST!) {
      addedUserAnalytics(input: $input)
        @rest(type: "createLead", path: "/zendesk", method: "POST", endpoint: "command") {
        id
      }
    }
  `,
})

export const addCreateLeadVariables = (input: CreateLeadParams) => ({
  variables: {
    input,
  },
})
