import * as React from 'react'
import FullToolbar from './FullToolbar'
import MobileToolbar from './MobileToolbar'

type Props = {
  isDevStage: boolean
  history: {
    push: (route: string) => void
  }
  routes: {
    path: string
    component: React.Component
  }[]
}

export default class MainToolbar extends React.PureComponent<Props, {}> {
  public render() {
    return (
      <React.Fragment>
        <FullToolbar history={this.props.history} routes={this.props.routes} isDevStage={this.props.isDevStage} />
        <MobileToolbar routes={this.props.routes} />
      </React.Fragment>
    )
  }
}
