const trackListReducer = function(state = { channelId: '', ids: [] }, action) {
  switch (action.type) {
    case 'CHANGE_EXPANDED_TRACK_LIST': {
      return action.payload
    }

    default: {
      return state
    }
  }
}

export default trackListReducer
