import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'

// @ts-ignore
const BetaBadge = withStyles(theme => ({
  badge: {
    backgroundColor: 'transparent',
    color: 'red',
    width: 40,
    height: 20,
    right: -35,
    top: -15,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  // @ts-ignore
}))(Badge)

export default BetaBadge
