import * as React from 'react'
import { Translate } from 'src/translation'
import { Link } from 'react-router-dom'
import { Button, Icon } from '@material-ui/core'
import { Channel } from 'src/gql/listChannels'
import ChannelAvatar from '../Channel/ChannelAvatar'

type ItemProps = {
  classes?: any
  channel: Channel
  translate: Translate
  selectedChannelsIds: string[]
  setSelectedChannelsIds: any
  tubeColor: string
  getChannels: any
  userProfileImage?: string
  tubeIcon: string
}

const Item = (props: ItemProps) => {
  const {
    classes,
    channel,
    translate,
    selectedChannelsIds,
    setSelectedChannelsIds,
    tubeColor,
    getChannels,
    userProfileImage,
    tubeIcon,
  } = props
  const handleOpenItem = id => {
    setSelectedChannelsIds([...selectedChannelsIds, id])
  }
  const handleCloseItem = id => {
    setSelectedChannelsIds(selectedChannelsIds.filter(item => item !== id))
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link id={channel.id} name={channel.name} to={`/channel/${channel.id}/home`} className={classes.topNavItem}>
          <ChannelAvatar size="small" channel={channel} />
          <span style={{ marginLeft: '10px' }} className={classes.topNavText}>
            {channel.name}
          </span>
        </Link>
        {getChannels(channel.id).length ? (
          selectedChannelsIds.includes(channel.id) ? (
            <Button type="button" className={classes.collapseButton} onClick={() => handleCloseItem(channel.id)}>
              <Icon style={{ marginLeft: 'auto' }}>keyboard_arrow_up</Icon>
            </Button>
          ) : (
            <Button type="button" className={classes.collapseButton} onClick={() => handleOpenItem(channel.id)}>
              <Icon style={{ marginLeft: 'auto' }}>keyboard_arrow_down</Icon>
            </Button>
          )
        ) : null}
      </div>

      {selectedChannelsIds.includes(channel.id) && getChannels(channel.id).length
        ? getChannels(channel.id).map(channel => (
            <div style={{ paddingLeft: '10px' }}>
              <Item
                tubeIcon={tubeIcon}
                key={channel.id}
                classes={classes}
                channel={channel}
                translate={translate}
                selectedChannelsIds={selectedChannelsIds}
                setSelectedChannelsIds={setSelectedChannelsIds}
                tubeColor={tubeColor}
                getChannels={getChannels}
                userProfileImage={userProfileImage}
              />
            </div>
          ))
        : null}
    </>
  )
}

export default Item
