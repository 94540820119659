export default () => ({
  layoutRoot: {},
  layoutToolbar: {
    padding: 0,
  },
  tabRoot: {
    height: 64,
    minWidth: 80,
    width: '10%',
    maxWidth: 120,
  },
  selectedTab: {
    borderBottom: '2px solid black',
  },
  tabWrap: {
    width: '100%',
  },
  rightFloat: {
    float: 'right' as 'right',
  },
  red: {
    color: 'red',
  },
  noResultHeader: {
    marginTop: 40,
    color: 'rgb(3,3,3)',
    fontSize: 30,
    fontWeight: 400 as 400,
    lineHeight: '30px',
    marginBottom: 16,
  },
  noResultContainer: {
    width: '100%',
    height: '100%',
    padding: 200,
    background: 'white',
    textAlign: 'center' as 'center',
  },
  noResultFooter: {
    color: 'rgb(148, 150, 154)',
    fontSize: 18,
    cursor: 'pointer',

    textDecoration: 'none',
    '&:hover': {
      opacity: 0.6,
    },
  },
})
